import WithdrawBalancePanel from "./WithdrawBalancePanel";
import WithdrawBalanceBottom from "./WithdrawBalanceBottom";
import { useContext } from "react";
import { ThemeContext } from "ThemeContext";

const WithdrawBalance = () => {
  const { theme } = useContext(ThemeContext);
  return (
    <div
      className={`w-full inline-block dark font-bold rounded-2xl text-xs leading-normal transition duration-150 ease-in-out ${
        theme === "dark" ? "bg-[#263238]" : "bg-[#EBF5FB]"
      } dark:text-gray p-8 text-[#128FC8] transition-all overflow-auto`}
    >
      <div className="flex flex-col justify-between w-full">
        <WithdrawBalancePanel />
      </div>
      <div className="flex justify-between w-full">
        <WithdrawBalanceBottom />
      </div>
    </div>
  );
};

export default WithdrawBalance;
