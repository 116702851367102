import { fixDigitFilter } from "utils/fixDigitFilter";

const TransactionInfo = ({
  selectedCoinBalance = 0,
  selectedCoinSymbol = "",
  feePercent = 0,
  feeValue = 0,
  ...props
}) => {
  return (
    <div
      {...props}
      className={`${props.className} border border-[#6e7a8a] rounded p-2`}
    >
      <div className="flex justify-between">
        <span>Your Balance:</span>
        <span>
          {fixDigitFilter(selectedCoinBalance)} {selectedCoinSymbol}
        </span>
      </div>
      <div className="flex justify-between">
        <span>Fee ({feePercent}%):</span>
        <span>
          {feeValue} {selectedCoinSymbol}
        </span>
      </div>
      {/* <div className="flex justify-between">
        <span>Network Cost:</span>
        <span>
          {fixDigitFilter(networkCost)} ETH (${fixDigitFilter(costAmount)})
        </span>
      </div> */}
    </div>
  );
};

export default TransactionInfo;
