const SwapIcon = ({ isDarkMode }) => {
  return (
    <>
      {isDarkMode ? (
        <svg
          width="34"
          height="34"
          viewBox="0 0 34 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.7 23.7996C18.7 25.6031 17.9836 27.3327 16.7083 28.6079C15.4331 29.8832 13.7035 30.5996 11.9 30.5996C10.0965 30.5996 8.36692 29.8832 7.09167 28.6079C5.81643 27.3327 5.1 25.6031 5.1 23.7996V10.1996L0 10.1996L6.8 3.39961L13.6 10.1996H8.5V23.7996C8.5 24.7013 8.85821 25.5661 9.49584 26.2038C10.1335 26.8414 10.9983 27.1996 11.9 27.1996C12.8017 27.1996 13.6665 26.8414 14.3042 26.2038C14.9418 25.5661 15.3 24.7013 15.3 23.7996V10.1996C15.3 8.39614 16.0164 6.66653 17.2917 5.39128C18.5669 4.11604 20.2965 3.39961 22.1 3.39961C23.9035 3.39961 25.6331 4.11604 26.9083 5.39128C28.1836 6.66653 28.9 8.39614 28.9 10.1996V23.7996H34L27.2 30.5996L20.4 23.7996H25.5V10.1996C25.5 9.29787 25.1418 8.43307 24.5042 7.79545C23.8665 7.15782 23.0017 6.79961 22.1 6.79961C21.1983 6.79961 20.3335 7.15782 19.6958 7.79545C19.0582 8.43307 18.7 9.29787 18.7 10.1996V23.7996Z"
            fill="#6E7A8A"
          />
        </svg>
      ) : (
        <svg
          width="34"
          height="34"
          viewBox="0 0 34 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.7 23.7996C18.7 25.6031 17.9836 27.3327 16.7083 28.6079C15.4331 29.8832 13.7035 30.5996 11.9 30.5996C10.0965 30.5996 8.36692 29.8832 7.09167 28.6079C5.81643 27.3327 5.1 25.6031 5.1 23.7996V10.1996L0 10.1996L6.8 3.39961L13.6 10.1996H8.5V23.7996C8.5 24.7013 8.85821 25.5661 9.49584 26.2038C10.1335 26.8414 10.9983 27.1996 11.9 27.1996C12.8017 27.1996 13.6665 26.8414 14.3042 26.2038C14.9418 25.5661 15.3 24.7013 15.3 23.7996V10.1996C15.3 8.39614 16.0164 6.66653 17.2917 5.39128C18.5669 4.11604 20.2965 3.39961 22.1 3.39961C23.9035 3.39961 25.6331 4.11604 26.9083 5.39128C28.1836 6.66653 28.9 8.39614 28.9 10.1996V23.7996H34L27.2 30.5996L20.4 23.7996H25.5V10.1996C25.5 9.29787 25.1418 8.43307 24.5042 7.79545C23.8665 7.15782 23.0017 6.79961 22.1 6.79961C21.1983 6.79961 20.3335 7.15782 19.6958 7.79545C19.0582 8.43307 18.7 9.29787 18.7 10.1996V23.7996Z"
            fill="#6E7A8A"
          />
        </svg>
      )}
    </>
  );
};

export default SwapIcon;
