import React, { useContext } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import { SortIcon, SortUpIcon, SortDownIcon } from "./shared/Icons";
import { ThemeContext } from "ThemeContext";

function SimpleTable({ columns, data, title }) {
  const { theme } = useContext(ThemeContext);
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    useTable(
      {
        columns,
        data,
      },
      useFilters, // useFilters!
      useGlobalFilter,
      useSortBy,
      usePagination // new
    );

  return (
    <div
      className={`flex flex-col ${
        theme === "dark" ? "bg-black" : "dark:bg-white text-black"
      }  rounded-2xl`}
    >
      <div className="flex sm:gap-x-2 justify-between">
        <div className="md:block hidden text-[20px]">{title && title}</div>
        {headerGroups.map((headerGroup) =>
          headerGroup.headers.map((column) =>
            column.Filter ? (
              <div className="mt-2 sm:mt-0" key={column.id}>
                {column.render("Filter")}
              </div>
            ) : null
          )
        )}
      </div>
      {/* table */}
      <div className="flex flex-col mt-4">
        <div className=" overflow-x-visible">
          <div className="inline-block min-w-full py-2 align-middle">
            <div
              className={`overflow-hidden 
              rounded-2xl`}
            >
              <table {...getTableProps()} className="min-w-full">
                <thead
                  className={`text-center ${
                    theme === "dark"
                      ? "text-white bg-[#181F23]"
                      : "text-black bg-[#99CFEB]"
                  }`}
                >
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props
                        <th
                          scope="col"
                          className="p-4 text-xs font-medium tracking-wider text-left uppercase group"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          <div className="flex items-center">
                            {column.render("Header")}
                            {/* Add a sort direction indicator */}
                            <span>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <SortDownIcon className="w-4 h-4 text-white" />
                                ) : (
                                  <SortUpIcon className="w-4 h-4 text-white" />
                                )
                              ) : (
                                <SortIcon className="w-4 h-4 text-white opacity-0 group-hover:opacity-100" />
                              )}
                            </span>
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody
                  {...getTableBodyProps()}
                  className={`${
                    theme === "dark"
                      ? "bg-[#000000] text-[#B0B5BC] divide-[#263238]"
                      : "bg-white text-gray-700 divide-gray-200"
                  } divide-y`}
                >
                  {page.map((row, i) => {
                    // new
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              className="px-2 py-2 whitespace-nowrap"
                              role="cell"
                            >
                              {cell.column.Cell.name === "defaultRenderer" ? (
                                <div
                                  className={`text-sm ${
                                    theme === "dark"
                                      ? "text-white"
                                      : "text-gray-700"
                                  }`}
                                >
                                  {cell.render("Cell")}
                                </div>
                              ) : (
                                cell.render("Cell")
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SimpleTable;
