import { createContext, useState, useContext, useEffect } from "react";
import { useUserData } from "UserDataContext";
import { constants } from "../constants";
import * as AppStatesApi from "api/app";
import * as BtcStatesApi from "api/btc";
import { useAccount } from "wagmi";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const { userData } = useUserData();
  const [balances, setBalances] = useState([]);
  const [balancesWallet, setBalancesWallet] = useState([]);
  const [balanceLoaded, setBalanceLoaded] = useState(false);
  const [balanceWalletLoaded, setBalanceWalletLoaded] = useState(false);
  const [constantsAll, setConstantsAll] = useState({});
  const [btcMinDepositAmount, setBtcMinDepositAmount] = useState(0);
  const balanceOfETH =
    balances.filter((balance) => balance.symbol === "ETH")[0]?.balance || 0;
  const { address } = useAccount();

  useEffect(() => {
    if (userData) {
      AppStatesApi.getBalances(userData).then((res) => {
        const balances = res?.data?.data.sort((a, b) => {
          if (a.viewOrder < b.viewOrder) {
            return -1;
          } else if (a.viewOrder > b.viewOrder) {
            return 1;
          } else {
            return 0;
          }
        });
        setBalances(balances);
        setBalanceLoaded(true);
      });
    }
  }, [userData]);
  useEffect(() => {
    if (address) {
      AppStatesApi.getBalancesWallet({ address }).then((res) => {
        const balances = res?.data?.data.sort((a, b) => {
          if (a.viewOrder < b.viewOrder) {
            return -1;
          } else if (a.viewOrder > b.viewOrder) {
            return 1;
          } else {
            return 0;
          }
        });
        console.log(balances);
        setBalancesWallet(balances);
        setBalanceWalletLoaded(true);
      });
    } else {
      setBalancesWallet([]);
      setBalanceWalletLoaded(true);
    }
  }, [address]);

  useEffect(() => {
    AppStatesApi.getConstantsAll().then((res) => {
      setConstantsAll(res.data.data);
    });
    BtcStatesApi.getBtcMinDepositAmount().then((res) => {
      setBtcMinDepositAmount(res.data.data.btc_minimum_deposit_amount);
    });
  }, []);

  const updateBalancesWithLatestPrices = (prices) => {
    const latestBalances = balances.map((coinItem) => {
      const newPrice = prices[coinItem.symbol];
      const newBalanceInUsd = newPrice * coinItem.balance;
      return { ...coinItem, price: newPrice, balanceInUsd: newBalanceInUsd };
    });
    setBalances(latestBalances);
  };

  useEffect(() => {
    if (balanceLoaded) {
      const timer = setInterval(() => {
        AppStatesApi.getPrices().then((res) => {
          const prices = res.data.data;
          updateBalancesWithLatestPrices(prices);
        });
      }, constants.intervalRequestTokenPrices);
      return () => {
        clearInterval(timer);
      };
    }
  }, [balanceLoaded]);

  const infoEUUS = {
    price: balances.filter((b) => b.symbol === "EUUS")[0]?.price || 0,
    icon: balances.filter((b) => b.symbol === "EUUS")[0]?.icon,
  };
  const infoUSEU = {
    price: balances.filter((b) => b.symbol === "USEU")[0]?.price || 0,
    icon: balances.filter((b) => b.symbol === "USEU")[0]?.icon,
  };
  const coins = balances.filter((balance) => balance.showOnBuy === true);
  const totalBalanceInUsd = balances.reduce((sum, coinItem) => {
    const balanceInUSD = parseFloat(coinItem?.balanceInUsd) || 0;
    return sum + balanceInUSD;
  }, 0);
  const totalBalanceWalletInUsd = balancesWallet.reduce((sum, coinItem) => {
    const balanceInUSD = parseFloat(coinItem?.balanceInUsd) || 0;
    return sum + balanceInUSD;
  }, 0);

  const defaultSelectedCoin =
    coins.filter((coin) => coin.symbol === "ETH")[0] || {};

  return (
    <AppContext.Provider
      value={{
        balances,
        balancesWallet,
        totalBalanceInUsd,
        totalBalanceWalletInUsd,
        coins,
        btcMinDepositAmount,
        constantsAll,
        infoEUUS,
        infoUSEU,
        balanceOfETH,
        defaultSelectedCoin,
        balanceLoading: !balanceLoaded,
        balanceWalletLoaded: !balanceWalletLoaded,
        setBalanceLoaded,
        setBalanceWalletLoaded,
        setBalances,
        setBalancesWallet,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppData = () => {
  return useContext(AppContext);
};
