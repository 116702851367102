import { useContext } from "react";
import Button from "components/Input/Button_dark";
import ProfileButton from "./ProfileButton";
import { useUserData } from "UserDataContext";
import { useLogout } from "hooks/useLogout";
import LogoIcon from "components/Svg/Icons/LogoIcon";
import { ThemeContext } from "ThemeContext";

export const navLinks = [
  { label: "Dashboard", link: "dashboard", hidden: true },
];

const Header = ({
  isMobileMenuOpen,
  setIsMobileMenuOpen,
  setSelectedPage,
  selectedPage,
}) => {
  const { userData } = useUserData();
  const { logout } = useLogout();

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const { theme } = useContext(ThemeContext);

  return (
    <div
      className={`${
        theme === "dark" ? "bg-[#202630] text-[#ffffff]" : "bg-[#ffffff] text-[#010813]"
      } flex flex-row flex-wrap justify-between items-center sm:py-[18px] py-[20px] font-inter absolute top-0 w-full`}
    >
      {/* Mobile Breadcrumb */}

      <div
        className={`absolute left-0 z-10 flex flex-row justify-center w-full h-screen p-12 overflow-hidden transition-all ${
          theme === "dark" ? "bg-[#000000] text-[#ffffff]" : "bg-[#ffffff] text-[#010813]"
        } lg:hidden ${
          isMobileMenuOpen
            ? " max-h-screen sm:top-32 top-20"
            : "max-h-0 -top-44"
        }`}
      >
        <div className="flex flex-row space-x-8">
          {navLinks.map((nav, index) => {
            return nav?.hidden === true ? null : (
              <div
                className={`flex flex-row space-y-8 text-[16px] cursor-pointer`}
                key={index}
              >
                {
                  <div
                    onClick={() => {
                      scrollToTop();
                      setIsMobileMenuOpen(false);
                      setSelectedPage(nav?.link);
                    }}
                    key={index}
                  >
                    <span>{nav?.label}</span>
                  </div>
                }
              </div>
            );
          })}

          <div className="flex flex-row items-center gap-5 px-4 ">
            {userData ? (
              <ProfileButton
                userData={userData}
                logout={() => {
                  logout();
                }}
                setSelectedPage={setSelectedPage}
              />
            ) : (
              <>
                <div
                  onClick={() => {
                    scrollToTop();
                    setSelectedPage("login");
                  }}
                  className={
                    selectedPage === "login"
                      ? `flex transition-all text-[#128FC8] cursor-pointer`
                      : `flex transition-all text-[#5F5F5F] cursor-pointer`
                  }
                >
                  <div className="flex items-center text-base font-semibold uppercase transition-all gap-x-2 hover:text-gray-400">
                    Login
                  </div>
                </div>
                <Button
                  label="SignUp"
                  dark
                  onClickHandle={() => {
                    scrollToTop();
                    setSelectedPage("signup");
                  }}
                />
              </>
            )}

            {/* <ThemeSwitcher /> */}
          </div>
        </div>
      </div>

      <nav className="z-30 flex flex-row items-center justify-between w-full px-3">
        <div
          onClick={() => {
            scrollToTop();
            setSelectedPage("dashboard");
          }}
          // className={({ isActive }) =>
          //   isActive ? ` text-gray-800` : ` text-[#1E2761]`
          // }
        >
          <span className="flex items-center h-20 mt-0 transition-all cursor-pointer hover:opacity-80">
            <img
              src={
                theme === "dark"
                  ? "/assets/images/Nexus Pro_Logo 1.png"
                  : "/assets/images/Nexus Pro_Logo 2.png"
              }
              alt="wing"
              className="h-3/4 md:block hidden"
            />
            <span className="md:hidden block">
              <LogoIcon></LogoIcon>
            </span>
          </span>
        </div>
        <div className="flex flex-row justify-end w-full">
          {navLinks.map((link, index) => {
            return link?.hidden ? null : (
              <div
                onClick={() => {
                  scrollToTop();
                  setSelectedPage(link?.link);
                }}
                key={index}
                className={
                  link?.link === selectedPage
                    ? `flex transition-all text-[#128FC8]  cursor-pointer`
                    : `flex transition-all  cursor-pointer ${
                        theme === "dark" ? "text-[#ffffff]" : "text-[#5F5F5F]"
                      }`
                }
              >
                <div className="flex text-sm font-semibold transition-all xl:text-base gap-x-2 hover:text-gray-400">
                  {link.label}
                </div>
              </div>
            );
          })}
          {/* userprofile */}
          <div className="flex flex-row justify-between gap-5 ">
            {userData ? (
              <ProfileButton
                userData={userData}
                logout={() => {
                  logout();
                }}
                setSelectedPage={setSelectedPage}
              />
            ) : (
              <>
                <div
                  onClick={() => {
                    scrollToTop();
                    setSelectedPage("login");
                  }}
                  className={
                    selectedPage === "login"
                      ? `flex transition-all text-[#128FC8] cursor-pointer`
                      : `flex transition-all text-[#5F5F5F] cursor-pointer`
                  }
                >
                  <div className="flex items-center text-base font-semibold uppercase transition-all gap-x-2 hover:text-gray-400">
                    Login
                  </div>
                </div>
                <Button
                  label="SignUp"
                  dark
                  onClickHandle={() => {
                    scrollToTop();
                    setSelectedPage("signup");
                  }}
                />
              </>
            )}

            {/* <ThemeSwitcher /> */}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
