import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import PrimarySelectBox from "components/common/PrimarySelectBox";
import { Button } from "@mui/material";

const DepositWithdrawFilterPanel = ({
  coins = [],
  fromDate = {},
  toDate = {},
  selectedCoin = {},
  onChangeFromDate = () => {},
  onChangeToDate = () => {},
  onCoinSelectionChange = () => {},
}) => {
  const optionRenderFn = (option) => {
    return (
      <div className="flex items-center">
        {option.name !== "All" && (
          <img src={option.icon} alt="coin_icon" className="w-5 h-5 mr-2" />
        )}
        <span>{option.name}</span>
      </div>
    );
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="flex w-full">
        <div className="flex flex-1 lg:flex-row flex-col">
          <div className="flex flex-row space-x-4 ">
            <DatePicker
              slotProps={{ textField: { size: "small" } }}
              value={fromDate}
              onChange={onChangeFromDate}
            />
            <DatePicker
              slotProps={{ textField: { size: "small" } }}
              value={toDate}
              onChange={onChangeToDate}
            />
          </div>
          <PrimarySelectBox
            options={coins}
            render={optionRenderFn}
            onChange={onCoinSelectionChange}
            className="lg:px-4 py-2 lg:py-0 min-w-[150px]"
            defaultSelect={selectedCoin}
          />
          
            <Button variant="outlined" sx={{ height: "40px" }}>
              Refresh
            </Button>
          
        </div>
      </div>
    </LocalizationProvider>
  );
};

export default DepositWithdrawFilterPanel;
