import BoardDashboard from "components/Board/Board_dashboard";
import ButtonDark from "components/Input/Button_dark";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import InputNormal from "components/Input/InputNormal";
import { useAppData } from "providers/app";

const BTCPayment = ({ onClick = (f) => f, isLoading = false }) => {
  const { t } = useTranslation();
  const [inputValue, setinputValue] = useState("");
  const { btcMinDepositAmount } = useAppData();
  const handleClickOption = (inputValue) => {
    onClick(inputValue);
  };

  return (
    <BoardDashboard>
      <div className="relative flex flex-col flex-1 w-full gap-5">
        <div className="flex flex-col items-center justify-between w-full">
          <div className="flex w-full">
            <div className="flex items-center w-full gap-3">
              <InputNormal
                value={inputValue}
                setValue={setinputValue}
                label={t("")}
                btcDepositAmount={btcMinDepositAmount}
              />
            </div>
          </div>
          <div className="flex justify-start w-full lg:justify-end self-endmt-3 pt-2">
            <ButtonDark
              label={t("Send Transaction ID")}
              onClickHandle={() => handleClickOption(inputValue)}
              className="w-1/2 bg-info"
              loading={isLoading}
            />
          </div>
        </div>
      </div>
    </BoardDashboard>
  );
};

export default BTCPayment;
