import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "ThemeContext";
import { usePageNum } from "PageNumContext";
import { useUserData } from "UserDataContext";
import PrimarySelectBox from "components/common/PrimarySelectBox";
import { useWithdrawConstraint } from "hooks/useWithdrawConstraint";
import { useAppData } from "providers/app";
import { useTranslation } from "react-i18next";
import { fixFloorFilter } from "utils/fixDigitFilter";
import * as AppStatesApi from "api/app";
import WalletIcon from "components/Svg/Icons/WalletIcon";
import WithdrawDialog from "./WithdrawDialog";
import ButtonGreen from "components/Input/Button_green";
import useToast from "hooks/useToast";

const optionRenderFn = (option) => {
  return (
    <div className="flex items-center">
      <img src={option.icon} alt="coin_icon" className="w-5 h-5 mr-2" />
      <span>{option.name}</span>
    </div>
  );
};

const Alert = (type, inputAddressValue, minimumAmount, symbol) => {
  if (type === 2 && inputAddressValue) {
    return (
      <p className="text-center text-red-400">
        Entered amount should be more than {minimumAmount} {symbol}
      </p>
    );
  } else if (type === 1 && inputAddressValue) {
    return (
      <p className="text-center text-red-400">
        Entered amount is exceed to your balance
      </p>
    );
  }
};

const canWithdrawAlert = (value) => {
  if (value) {
    return (
      <p className="text-center text-red-400">
        This is not available to withdrawal for a while.
      </p>
    );
  }
};

const WithdrawBalancePanel = () => {
  const { balances, defaultSelectedCoin, setBalances, setBalanceLoaded } =
  useAppData();
  const { theme } = useContext(ThemeContext);
  const { userData } = useUserData();
  const { showToast } = useToast();
  const { t } = useTranslation();
  const { setPageNum } = usePageNum();
  
  const [selectedCoin, setSelectedCoin] = useState(defaultSelectedCoin);
  const [possibleToBuyWithToken, setPossibleToBuyToken] = useState(0);
  const [inputAmount, setInputAmount] = useState("");
  const [inputAddressValue, setInputAddressValue] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [minimumAmount, setMinimumAmount] = useState(0);
  const [gasFee, setGasFee] = useState(0);
  const [openDialog, setopenDialog] = useState(false);
  const withdrawConstraintData = useWithdrawConstraint();

  const withdrawConstraint = (value, symbol) => {
    if (value?.isFetched) {
      const canWithdarw =
        value?.data?.data?.data?.minimum_withdraw_amount.filter(
          (state) => state.symbol === symbol
        )[0];

      setisLoading(value?.isFetched !== true);
      setIsDisabled(!canWithdarw.can_withdraw);
      setMinimumAmount(canWithdarw.minimum_withdraw_amount);
      setGasFee(canWithdarw.gas_fee);
    }
  };

  const onCoinSelectionChange = (coin) => {
    setSelectedCoin(balances.filter((c) => c.symbol === coin.symbol)[0]);
  };

  const receiveAmount =
    selectedCoin.balance === 0
      ? 0
      : inputAmount - gasFee >= 0
      ? fixFloorFilter(inputAmount - gasFee)
      : 0;
  const isDisabledWithdraw = possibleToBuyWithToken !== 0 || receiveAmount <= 0;
  
  const onClickHandle = () => {
    setInputAmount(fixFloorFilter(selectedCoin.balance));
  };

  const handleInputChange = (e) => {
    const input = e.target.value;

    // Use a regular expression to check if the input is a valid float
    const isValidFloat = /^-?\d*(\.\d*)?$/.test(input);

    if (isValidFloat || input === "") {
      setInputAmount(input);
    }
  };

  const handleInputAdressChange = (e) => {
    setInputAddressValue(e.target.value);
  };

  useEffect(() => {
    withdrawConstraint(withdrawConstraintData, selectedCoin.symbol);
  }, [withdrawConstraintData, selectedCoin.symbol]);

  useEffect(() => {
    const i = Number(inputAmount);
    const b = selectedCoin.balance;
    const min = minimumAmount;
    const conditionMinus = i - b <= 0 && i - min < 0;
    const conditionPlus = i - b >= 0 && i - min > 0;

    setPossibleToBuyToken(
      conditionMinus ? 2 : conditionPlus ? 1 : i > b ? 1 : 0
    );
  }, [inputAmount, minimumAmount, selectedCoin.balance]);

  useEffect(() => {
    setInputAmount("");
    setPossibleToBuyToken(true);
  }, [inputAddressValue]);

  useEffect(() => {
    setSelectedCoin(
      balances.filter((c) => c.symbol === selectedCoin.symbol)[0]
    );
  }, [balances, selectedCoin.symbol]);

  const reloadBalance = () => {
    setBalanceLoaded(false);
    AppStatesApi.getBalances(userData).then((res) => {
      const balances = res?.data?.data.sort((a, b) => {
        if (a.viewOrder < b.viewOrder) {
          return -1;
        } else if (a.viewOrder > b.viewOrder) {
          return 1;
        } else {
          return 0;
        }
      });
      setBalances(balances);
      setBalanceLoaded(true);
    });
  };

  return (
    <div
      className={`flex p-[10px] ${
        theme === "dark" ? "bg-[#181F23]" : "bg-[#D6ECF7]"
      } w-full rounded-[20px] mb-4`}
    >
      <div
        className={`flex flex-col ${
          theme === "dark"
            ? "bg-black text-white"
            : "bg-[#FFFFFF] text-[#6E7A8B]"
        } w-full rounded-[20px] p-6 gap-y-4`}
      >
        <div className="flex mt-4 w-full justify-between items-center">
          <div className="flex flex-row gap-x-2 items-center">
            <WalletIcon isDarkMode={theme === "dark"} />
            <h2 className="text-[14px] font-semibold">
              My Balance / Add $ / Withdraw Balance
            </h2>
          </div>
          <div
            className="text-sm font-normal text-[#B0B5BC] cursor-pointer hover:opacity-70 items-center"
            onClick={() => {
              setPageNum(1);
            }}
          >
            ← Back
          </div>
        </div>

        <div className="flex flex-col lg:flex-row gap-8">
          <div className="flex flex-col lg:w-1/2 w-full py-3 lg:pr-16 gap-2">
            <PrimarySelectBox
              label={t("Select Coin")}
              options={balances}
              render={optionRenderFn}
              onChange={onCoinSelectionChange}
              className="mb-4"
              defaultSelect={selectedCoin}
              isLoading={isLoading}
            />

            {canWithdrawAlert(isDisabled)}

            <div className="flex flex-col mt-1">
              <span className="text-sm text-[#D9D9D9] ">Send To</span>
              <div className="flex border-[1px] border-[#6E7A8A] rounded-md font-normal w-[90%] sm:w-full items-center mt-2 p-2">
                <input
                  type="text"
                  name="price"
                  id="price"
                  className={`grow rounded-md border pl-2 pr-2 border-none lg:w-[100px] text-gray-300 outline-none placeholder:text-[#6E7A8A] ${
                    theme === "dark"
                      ? "bg-[#000000] text-[#6E7A8A]"
                      : "bg-white text-gray-800"
                  } text-sm xl:text-[14px] lg:text-[14px] lg:leading-6`}
                  value={inputAddressValue}
                  disabled={isDisabled}
                  onChange={handleInputAdressChange}
                />
              </div>
            </div>

            {inputAddressValue ? (
              <div className="flex flex-col mt-5">
                <div className="flex flex-col gap-2">
                  <div className="flex flex-col sm:flex-row justify-between">
                    <span className="text-sm text-[#D9D9D9] ">Amount</span>
                    <div className="flex flex-col ">
                      <div className="flex justify-end">
                        <span className="text-[#6E7A8A]">
                          {t("Available Balance")}{" "}
                          <span className="text-[#D9D9D9]">
                            {fixFloorFilter(selectedCoin.balance)}{" "}
                            {selectedCoin.symbol}{" "}
                          </span>
                        </span>
                        <span
                          className="text-[#00C880] pl-2 font-normal cursor-pointer"
                          onClick={onClickHandle}
                        >
                          SELECT ALL
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex border-[1px] border-[#6E7A8A] rounded-md font-normal w-[90%] sm:w-full items-center p-2">
                    <input
                      type="text"
                      name="price"
                      id="price"
                      className={`grow rounded-md border pl-2 pr-2 border-none lg:w-[100px] text-gray-300 outline-none placeholder:text-[#6E7A8A] ${
                        theme === "dark"
                          ? "bg-[#000000] text-[#6E7A8A]"
                          : "bg-white text-gray-800"
                      } text-sm xl:text-[14px] lg:text-[14px] lg:leading-6`}
                      value={inputAmount}
                      onChange={handleInputChange}
                    />
                  </div>

                  {Alert(
                    possibleToBuyWithToken,
                    inputAddressValue,
                    minimumAmount,
                    selectedCoin.symbol
                  )}

                  <div className="flex justify-between mt-1">
                    <div className="flex flex-col mt-1 text-[#6E7A8A]">
                      <span>{t("Received")}</span>
                      <span>{t("Minimum Withdrawal Amount")}</span>
                      <span>{t("Fee")}</span>
                    </div>
                    <div className="flex flex-col mt-1 text-[#6E7A8A]">
                      <span className="text-[#D9D9D9] text-md">
                        {receiveAmount}
                        {selectedCoin.symbol}
                      </span>
                      <span>
                        {minimumAmount} {selectedCoin.symbol}
                      </span>
                      <span>
                        {gasFee} {selectedCoin.symbol}
                      </span>
                    </div>
                  </div>
                  <div className="flex w-full justify-center">
                    <WithdrawDialog
                      onClose={() => {
                        setopenDialog(false);
                      }}
                      isOpen={openDialog}
                      symbol={selectedCoin.symbol}
                      feeValue={gasFee}
                      amount={inputAmount}
                      address={inputAddressValue}
                      reloadBalance={reloadBalance}
                    />
                    <ButtonGreen
                      label={t("Submit")}
                      disabled={isDisabledWithdraw}
                      onClickHandle={() => {
                        if (inputAmount !== null && Number(inputAmount) > 0.0) {
                          setopenDialog(true);
                        } else
                          showToast(
                            `Money should be bigger than zero!`,
                            "error"
                          );
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div
            className={`flex flex-col lg:w-1/2 w-full py-8 lg:px-8 gap-10 ${
              theme === "dark" ? "text-[#D9D9D9]" : "text-[#5F5F5F]"
            } text-sm font-normal`}
          >
            <div>
              Please do not withdraw funds to an ICO address or for
              crowdfunding. Nexus Pro will not be responsible for distributing
              any future tokens you may receive.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WithdrawBalancePanel;
