import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import useToast from "./useToast";
import { useUserData } from "UserDataContext";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL + "/api",
});

// Set the Authorization header with the token
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const useDepositAll = () => {
  const { userData } = useUserData();
  const { showToast } = useToast();
  return {
    ...useQuery({
      queryKey: ["Deposit-history-all", "post"],
      queryFn: async () => {
        const result = await instance
          .post("/deposit/history", { userId: userData.id })
          .catch((err) => {
            console.log(err, "ERROR");
            if (
              err?.response?.status === 403 ||
              err?.response?.status === 401
            ) {
              showToast("Something is wrong!", "error");
            }
          });
        return result;
      },
    }),
  };
};

export const useWithdrawAll = () => {
  const { userData } = useUserData();
  const { showToast } = useToast();
  return {
    ...useQuery({
      queryKey: ["Withdraw-history-all", "post"],
      queryFn: async () => {
        const result = await instance
          .post("/withdraw/history", { userId: userData.id })
          .catch((err) => {
            console.log(err, "ERROR");
            if (
              err?.response?.status === 403 ||
              err?.response?.status === 401
            ) {
              showToast("Something is wrong!", "error");
            }
          });
        return result;
      },
    }),
  };
};
