import { backendAPIAxios as axios } from "utils/initAxios";

export const getBalances = (userData) =>
  axios.post("/spot/balance", { userId: userData.id });

export const getBalancesWallet = (payload) =>
  axios.post("/spot/balance_wallet", payload);

export const getPrices = () => axios.get("/rates/token_prices");

export const getConstantsAll = () => axios.get("/constants/all");

export const getDepositBTC = (payload) =>
  axios.post("/deposit/fetch_btc", payload);

export const withdrawCreate = (payload) =>
  axios.post("/withdraw/create", payload);
