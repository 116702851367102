import SideBar from "pages/Dashboard/Sidebar";
import DashboardContent from "./Content";
import { useContext, useState } from "react";
import MenuBarMobile from "./SidebarMobile";
import MyBalance from "./MyBalance";
import AddBalance from "./MyBalance/AddBalance";
import { usePageNum } from "PageNumContext";
import { useUserData } from "UserDataContext";
import WithdrawBalance from "./MyBalance/WithdrawBalance";
import MyPosition from "./MyPosition";
import TradingHistory from "./TradingHistory";
import MyAccount from "./MyAccount";
import Setting from "./Setting";
import Support from "./Support";
import OTCCalculator from "./OTCCalculator";
import OTCStatus from "./OTCStatus";
import { ThemeContext } from "ThemeContext";

export const dashboard_contents = ({ changeLanguage, membership }) => {
  return [
    {
      icon: (
        <span>
          <img src={"/assets/svg/dashboard.svg"} alt="wing" />
        </span>
      ),
      label: "dashboard",
      component: <DashboardContent />,
      hasSeperatorLine: true,
    },
    {
      icon: (
        <span>
          <img src={"/assets/svg/wallet.svg"} alt="wing" />
        </span>
      ),
      label: "myBalance/add",
      component: <MyBalance />,
    },
    {
      show: false,
      icon: (
        <span>
          <img src={"/assets/svg/wallet.svg"} alt="wing" />
        </span>
      ),
      component: <AddBalance />,
    },
    {
      show: false,
      icon: (
        <span>
          <img src={"/assets/svg/wallet.svg"} alt="wing" />
        </span>
      ),
      component: <WithdrawBalance />,
    },
    {
      icon: (
        <span>
          <img src={"/assets/svg/position.svg"} alt="wing" />
        </span>
      ),
      label: "myPositions",
      component: <MyPosition />,
    },
    {
      icon: (
        <span>
          <img src={"/assets/svg/Activity History.svg"} alt="wing" />
        </span>
      ),
      label: "tradingHistory",
      component: <TradingHistory />,
    },
    {
      icon: (
        <span>
          <img src={"/assets/svg/Account.svg"} alt="wing" />
        </span>
      ),
      label: "myAccount",
      component: <MyAccount />,
      hasSeperatorLine: true,
    },
    {
      show: membership === "Business" || membership === "Admin",
      icon: (
        <span>
          <img
            src={
              "https://staticdash.dev.nexuspro.io/assets/svg/Account.svg"
            }
            alt="wing"
          />
        </span>
      ),
      label: "OTC Calculator",
      component: <OTCCalculator />,
    },
    {
      show: membership === "Business" || membership === "Admin",
      icon: (
        <span>
          <img
            src={
              "https://staticdash.dev.nexuspro.io/assets/svg/Account.svg"
            }
            alt="wing"
          />
        </span>
      ),
      label: "OTC Status",
      component: <OTCStatus />,
      hasSeperatorLine: true,
    },
    {
      icon: (
        <span>
          <img src={"/assets/svg/Settings.svg"} alt="wing" />
        </span>
      ),
      label: "settings",
      component: <Setting changeLanguage={changeLanguage} />,
    },
    {
      icon: (
        <span>
          <img src={"/assets/svg/Technical Support.svg"} alt="wing" />
        </span>
      ),
      label: "support",
      component: <Support />,
    },
  ];
};

const Dashboard = ({ changeLanguage }) => {
  const { theme } = useContext(ThemeContext);
  const { pageNum } = usePageNum();
  const { userData } = useUserData();
  const [showSidebar, setShowSidebar] = useState(false);
  return (
    <div
      className={`${
        theme === "dark" ? "bg-[#202630]" : "bg-[#ffffff]"
      } transition-all h-screen flex w-full pt-32 px-2 `}
    >
      <div className="relative flex w-full h-[99%] gap-4">
        {!dashboard_contents({
          changeLanguage: changeLanguage,
          membership: userData?.membership,
        })[pageNum]?.noSidebar ? (
          <>
            {" "}
            <MenuBarMobile theme={theme} setter={setShowSidebar} />
            <SideBar
              show={showSidebar}
              setter={setShowSidebar}
              selectedSidebarPage={pageNum}
              changeLanguage={changeLanguage}
            />
            {showSidebar ? (
              <div
                className="fixed top-0 bottom-0 right-0 z-50 w-full h-full left-1/4"
                onClick={() => {
                  setShowSidebar(false);
                }}
              ></div>
            ) : null}
          </>
        ) : null}
        {
          dashboard_contents({
            changeLanguage: changeLanguage,
            membership: userData?.membership,
          })[pageNum].component
        }
      </div>
    </div>
  );
};

export default Dashboard;
