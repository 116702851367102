import classNames from "classnames";
const ButtonDepositMenu = ({ dark, size, label, color, onClickHandle }) => {
  return (
    <button
      type="button"
      onClick={onClickHandle}
      className={classNames(
        `block dark font-bold rounded-xl px-8 py-3 
        text-sm leading-normal transition duration-150 ease-in-out bg-[#0084C9] dark:text-gray text-[#EBF5FB] text-center`
      )}
    >
      {label}
    </button>
  );
};

export default ButtonDepositMenu;
