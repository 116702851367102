import { useContext } from "react";
import { ThemeContext } from "ThemeContext";
import BoardDashboard from "components/Board/Board_dashboard";
import HashCodeCopy from "components/HashCodeCopy";

const ShowHistoryPanel = ({ historyData }) => {
  const { theme } = useContext(ThemeContext);

  if (historyData.length === 0) {
    return <h1>There is no history.</h1>;
  }
  return historyData.map((history, idx) => {
    return (
      <div
        className={`2xl:w-[32%] xl:w-[31.7%] lg:w-[30%] md:w-[47%] w-full ${
          theme === "dark" ? "bg-black text-white" : "bg-white text-black"
        } rounded-[20px] p-5`}
        key={idx}
      >
        <BoardDashboard>
          <div className="flex flex-col gap-5 font-normal">
            <div className="flex flex-col items-start justify-between gap-2">
              <div className="text-base text-black">Txn Id</div>
              <div
                className={`text-base border border-[#939699] p-2 rounded w-full ${
                  theme === "dark" ? "text-[#6E7A8A]" : "text-[#000000]"
                }`}
              >
                <HashCodeCopy hashCode={history?.transaction_hash} />
              </div>
            </div>
            <div className="flex flex-col justify-between gap-2">
              <div className="text-base text-black">Time</div>
              <div
                className={`text-base border border-[#939699] p-2 rounded w-full ${
                  theme === "dark" ? "text-[#6E7A8A]" : "text-[#000000]"
                }`}
              >
                {history.process_date}
              </div>
            </div>
            <div className="flex flex-col justify-between gap-2">
              <div className="text-base text-black">Network</div>
              <div
                className={`text-base border border-[#939699] p-2 rounded w-full ${
                  theme === "dark" ? "text-[#6E7A8A]" : "text-[#000000]"
                }`}
              >
                {history.network}
              </div>
            </div>
            <div className="flex flex-col justify-between gap-2">
              <div className="text-base text-black">Coin</div>
              <div
                className={`text-base border border-[#939699] p-2 rounded w-full ${
                  theme === "dark" ? "text-[#6E7A8A]" : "text-[#000000]"
                }`}
              >
                <div className="w-6 h-6 left-[352px] items-center gap-1.5 inline-flex justify-between">
                  <img
                    className="w-6 h-6"
                    src={history.token.icon_url}
                    alt={"token"}
                  />
                  <span>{history.token.symbol}</span>
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-between gap-2">
              <div className="text-base text-black">Amount</div>
              <div
                className={`text-base border border-[#939699] p-2 rounded w-full ${
                  theme === "dark" ? "text-[#6E7A8A]" : "text-[#000000]"
                }`}
              >
                {history.amount}
              </div>
            </div>
            <div className="flex flex-col justify-between gap-2">
              <div className="text-base text-black">Status</div>
              <div
                className={`text-base border border-[#939699] p-2 rounded w-full ${
                  theme === "dark" ? "text-[#00C880]" : "text-[#000000]"
                }`}
              >
                {history.status}
              </div>
            </div>
          </div>
        </BoardDashboard>
      </div>
    );
  });
};

export default ShowHistoryPanel;
