import { useContext, useEffect, useState } from "react";
import { useUserData } from "UserDataContext";
import { useAppData } from "providers/app";
import PrimarySpinner from "components/common/PrimarySpinner";
import ButtonWhite from "components/Input/Button_white";
import * as RequestMembership from "api/requestMembership";
import useToast from "hooks/useToast";
import { ThemeContext } from "ThemeContext";
import { fixDigitFilter } from "utils/fixDigitFilter";

const ShowStatus = ({ statusData, refreshCallback }) => {
  const { userData } = useUserData();
  const [postingFlag, setPostingFlag] = useState(false);
  const { showToast } = useToast();

  if (statusData.status === "None")
    return (
      <ButtonWhite
        label={"Get Started"}
        postingFlag={postingFlag}
        onClickHandle={() => {
          const payload = {
            userId: userData.id,
          };
          setPostingFlag(true);
          RequestMembership.requestMembership(payload)
            .then((res) => {
              showToast("Success Request Memebership", "success");
              refreshCallback();
            })
            .catch((error) => {
              showToast(error.response.data.message, "error");
            })
            .finally(() => {
              setPostingFlag(false);
            });
        }}
      />
    );
  const dateData =
    statusData.status === "Pending"
      ? statusData?.request_date
      : statusData?.close_date;
  const dateOnly = dateData && dateData !== "" ? dateData.split("T")[0] : "";
  return (
    <span className="text-[16px]">
      {statusData.status}
      <span className="text-xs text-[#B0B5BC] ml-4"> {dateOnly}</span>
    </span>
  );
};

const MyAccount = () => {
  const { theme } = useContext(ThemeContext);
  const { userData } = useUserData();
  const {
    balanceLoading,
    totalBalanceInUsd,
    balanceWalletLoaded,
    totalBalanceWalletInUsd,
  } = useAppData();
  const { showToast } = useToast();

  const [membershipStatus, setMembershipStatus] = useState({});
  const [isFetchedMembershipStatus, setIsFetchedMembershipStatus] =
    useState(false);

  useEffect(() => {
    refreshStatus();
  }, []);

  const refreshStatus = () => {
    const payload = {
      userId: userData.id,
    };
    setIsFetchedMembershipStatus(false);
    RequestMembership.getRequestMembershipStatus(payload)
      .then((res) => {
        setMembershipStatus(res.data.data);
      })
      .catch((error) => {
        showToast(error.response.data.message, "error");
      })
      .finally(() => {
        setIsFetchedMembershipStatus(true);
      });
  };
  const getRandomColor = (a) => {
    let hash = 0;
    a.split("").forEach((char) => {
      hash = char.charCodeAt(0) + ((hash << 5) - hash);
    });
    let colour = "#";
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      colour += value.toString(16).padStart(2, "0");
    }
    return colour;
  };
  return (
    <div
      className={`w-full h-[85%] inline-block dark font-bold rounded-2xl text-xs leading-normal transition duration-150 ease-in-out ${
        theme === "dark" ? "bg-[#263238]" : "bg-[#EBF5FB]"
      } dark:text-[#128FC8] text-[#128FC8] transition-all md:p-8 p-2 overflow-y-auto`}
    >
      <div className="w-full flex flex-col lg:flex-row gap-6">
        <div
          className={`flex flex-col w-full h-fit lg:w-1/3 p-2.5 ${
            theme === "dark" ? "bg-[#181F23]" : "bg-[#D6ECF7]"
          } rounded-2xl`}
        >
          <div
            className={`flex flex-col ${
              theme === "dark"
                ? "bg-black text-white"
                : "bg-[#FFFFFF] text-[#2f3133]"
            } w-full rounded-2xl p-8 gap-y-4 divide-y divide-[#263238]`}
          >
            <div className="flex flex-col gap-y-4">
              <div className="flex flex-row items-center gap-x-2">
                <svg
                  width="40"
                  height="28"
                  viewBox="0 0 40 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_532_7380)">
                    <path
                      d="M36 0H4C1.79086 0 0 1.79086 0 4V24C0 26.2091 1.79086 28 4 28H36C38.2091 28 40 26.2091 40 24V4C40 1.79086 38.2091 0 36 0Z"
                      fill={`${theme === "dark" ? "#0084C9" : "#D6ECF7"}`}
                    />
                    <g clipPath="url(#clip1_532_7380)">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19.5 5C14.2555 5 10 9.25548 10 14.5C10 19.7445 14.2555 24 19.5 24C24.7445 24 29 19.7445 29 14.5C29 9.25548 24.7445 5 19.5 5ZM19.5 6.35342C23.9897 6.35342 27.6466 9.99726 27.6466 14.5C27.6466 19.0027 24.0027 22.6466 19.5 22.6466C14.9973 22.6466 11.3534 18.9897 11.3534 14.5C11.3534 10.0103 15.0103 6.35342 19.5 6.35342ZM25.5514 18.4171C25.5904 18.339 25.6034 18.2479 25.6034 18.1438C25.6034 17.2459 25.1089 16.3219 24.25 15.5541C23.1048 14.539 21.2959 13.8103 19.5 13.8103C17.7041 13.8103 15.9082 14.539 14.75 15.5541C13.8911 16.3089 13.3966 17.2329 13.3966 18.1438L13.4486 18.4041L13.6178 18.8205C13.8911 19.4842 14.5288 19.9137 15.2445 19.9137H23.7555C24.4712 19.9137 25.1089 19.4842 25.3822 18.8205L25.5514 18.4041V18.4171ZM24.25 18.0267L24.1329 18.313C24.0678 18.4692 23.9247 18.5603 23.7555 18.5603H15.2445C15.0753 18.5603 14.9322 18.4562 14.8671 18.313L14.75 18.0267C14.8021 17.5062 15.1534 17.0116 15.6479 16.5822C16.5849 15.7623 18.0425 15.1767 19.5 15.1767C20.9575 15.1767 22.4151 15.7623 23.3521 16.5822C23.8466 17.0116 24.1979 17.5192 24.25 18.0267ZM19.5 7.71986C18.0034 7.71986 16.7801 8.93014 16.7801 10.4397C16.7801 11.9493 17.9904 13.1596 19.5 13.1596C21.0096 13.1596 22.2199 11.9493 22.2199 10.4397C22.2199 8.93014 21.0096 7.71986 19.5 7.71986ZM19.5 9.07329C20.2548 9.07329 20.8534 9.68493 20.8534 10.4267C20.8534 11.1685 20.2418 11.7801 19.5 11.7801C18.7582 11.7801 18.1466 11.1685 18.1466 10.4267C18.1466 9.68493 18.7582 9.07329 19.5 9.07329Z"
                        fill="black"
                      />
                    </g>
                  </g>
                  <defs>
                    <clipPath id="clip0_532_7380">
                      <rect width="40" height="28" fill="white" />
                    </clipPath>
                    <clipPath id="clip1_532_7380">
                      <rect
                        width="19"
                        height="19"
                        fill="white"
                        transform="translate(10 5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <p className="text-[14px] font-semibold">My Account</p>
              </div>

              <div className="flex flex-col gap-y-2">
                <label className="text-[12px] font-normal">
                  Profile Picture:
                </label>
                {userData?.photos.length > 0 ? (
                  <img
                    className="border-2 border-white rounded-full shadow w-16 h-16"
                    src={userData?.photos[0]?.value}
                    alt="avatar"
                  />
                ) : (
                  <span
                    className={`w-16 h-16 rounded-full flex items-center justify-center `}
                    style={{
                      backgroundColor: getRandomColor(userData?.displayName),
                    }}
                  >
                    {userData?.displayName.trim().slice(0, 2).toUpperCase()}
                  </span>
                )}
              </div>

              <div className="flex flex-col gap-y-1">
                <label className="text-[12x] font-normal">Username:</label>
                <span className="text-[18px] font-normal">
                  {userData?.displayName}
                </span>
              </div>
            </div>

            <div className="flex flex-col pt-4 gap-y-4">
              <div className="flex flex-col gap-y-0">
                <label className="text-[12x] font-normal">Email:</label>
                <span className="text-[14px] font-normal">
                  {userData?.emails[0]?.value}
                </span>
              </div>

              <div className="flex flex-col gap-y-0">
                <label className="text-[12x] font-normal">ID:</label>
                <span className="text-[14px] font-normal">{userData?._id}</span>
              </div>
            </div>

            <div className="flex flex-col pt-4 gap-y-4">
              <div className="flex flex-col gap-y-1">
                <label className="text-[14px] font-normal">
                  Total Balance:
                </label>
                {balanceLoading || balanceWalletLoaded ? (
                  <PrimarySpinner />
                ) : (
                  <span className="text-[32px] font-normal">
                    ${" "}
                    {fixDigitFilter(
                      totalBalanceInUsd + totalBalanceWalletInUsd
                    )}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`flex flex-col w-full h-fit lg:w-1/3 p-2.5 ${
            theme === "dark" ? "bg-[#181F23]" : "bg-[#D6ECF7]"
          } rounded-2xl`}
        >
          <div
            className={`flex flex-col ${
              theme === "dark"
                ? "bg-black text-white"
                : "bg-[#FFFFFF] text-[#2f3133]"
            } w-full rounded-2xl p-8 gap-y-4 divide-y divide-[#263238]`}
          >
            <div className="flex flex-col gap-y-4">
              <label className="text-[18px] font-normal">Level 1</label>
              <div className="flex flex-col">
                <div className="flex flex-row items-stretch justify-between">
                  <label className="text-nowrap text-[14px] font-normal">
                    Deposit Assets
                  </label>
                  <label className="border border-t-0 border-x-0 border-b-[#898b8b] w-full ml-1 mr-1 mb-1.5"></label>
                  <label className="text-[14px] font-normal">Enabled</label>
                </div>

                <div className="flex flex-row justify-between">
                  <label className="text-nowrap text-[14px] font-normal">
                    Withdraw assets
                  </label>
                  <label className="border border-t-0 border-x-0 border-b-[#898b8b] w-full ml-1 mr-1 mb-1.5"></label>
                  <label className="text-[14px] font-normal">
                    Enabled&nbsp;$10000
                  </label>
                </div>

                <div className="flex flex-row justify-between">
                  <label className="text-nowrap text-[14px] font-normal">
                    Card purchases
                  </label>
                  <label className="border border-t-0 border-x-0 border-b-[#898b8b] w-full ml-1 mr-1 mb-1.5"></label>
                  <label className="text-[14px] font-normal">Enabled</label>
                </div>

                <div className="flex flex-row justify-between">
                  <label className="text-nowrap text-[14px] font-normal">
                    Futures
                  </label>
                  <label className="border border-t-0 border-x-0 border-b-[#898b8b] w-full ml-1 mr-1 mb-1.5"></label>
                  <label className="text-[14px] font-normal">Enabled</label>
                </div>
              </div>
            </div>

            <div className="flex flex-col pt-4 gap-y-4">
              <label className="text-[18px] font-normal">Level 2</label>
              <div className="flex flex-col">
                <div className="flex flex-row justify-between">
                  <label className="text-nowrap text-[14px] font-normal">
                    Increase Withdrawal
                  </label>
                  <label className="text-[14px] text-[#00B880] font-normal">
                    Enable
                  </label>
                </div>
                <div className="flex flex-row justify-between">
                  <label className="text-nowrap text-[14px] font-normal">
                    Limit
                  </label>
                  <label className="border border-t-0 border-x-0 border-b-[#898b8b] w-full ml-1 mr-1 mb-1.5"></label>
                  <label className="text-[14px] text-[#00B880] font-normal">
                    50000/day
                  </label>
                </div>
                <div className="flex flex-row justify-between">
                  <label className="text-nowrap text-[14px] font-normal">
                    Enable Account
                  </label>
                  <label className="text-[14px] text-[#00B880] font-normal"></label>
                </div>
                <div className="flex flex-row justify-between">
                  <label className="text-nowrap text-[14px] font-normal">
                    Security
                  </label>
                  <label className="border border-t-0 border-x-0 border-b-[#898b8b] w-full ml-1 mr-1 mb-1.5"></label>
                  <label className="text-[14px] text-[#00B880] font-normal">
                    Enable
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`flex flex-col w-full h-fit lg:w-1/3 p-[10px] ${
            theme === "dark" ? "bg-[#181F23]" : "bg-[#D6ECF7]"
          } rounded-2xl`}
        >
          <div
            className={`flex flex-col ${
              theme === "dark"
                ? "bg-black text-white"
                : "bg-[#FFFFFF] text-[#2f3133]"
            } w-full rounded-2xl p-8 gap-y-6`}
          >
            <span className="text-lg">Want Higher Limits?</span>
            <span className="font-normal text-[14px]">
              Get increased limits and advanced features by providing a bit more
              profile information.
            </span>
            <div className="flex w-fit">
              {isFetchedMembershipStatus ? (
                <ShowStatus
                  statusData={membershipStatus}
                  refreshCallback={refreshStatus}
                />
              ) : (
                <PrimarySpinner />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyAccount;
