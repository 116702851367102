import { useContext, useState } from "react";
import * as AppStatesApi from "api/app";
import { useUserData } from "UserDataContext";
import useToast from "hooks/useToast";
import QRcode from "react-qr-code";
import AddressCopy from "components/AddressCopy";
import { useTranslation } from "react-i18next";
import { useAppData } from "providers/app";
import PrimarySelectBox from "components/common/PrimarySelectBox";
import BTCPayment from "./BTCPayment";
import { constants } from "constants";
import { ThemeContext } from "ThemeContext";

const optionRenderFn = (option) => {
  return (
    <div className="flex items-center">
      <img src={option.icon} alt="coin_icon" className="w-5 h-5 mr-2" />
      <span>{option.name}</span>
    </div>
  );
};

const CryptoPayment = () => {
  const { theme } = useContext(ThemeContext);
  const { userData } = useUserData();
  const { t } = useTranslation();
  const [isBTC, setIsBTC] = useState(false);
  const { showToast } = useToast();

  const { coins, defaultSelectedCoin } = useAppData();
  const [selectedCoin, setSelectedCoin] = useState(defaultSelectedCoin);
  const [isLoading, setIsLoading] = useState(false);

  const onCoinSelectionChange = (coin) => {
    coin.symbol === constants.BTC_SYMBOL ? setIsBTC(true) : setIsBTC(false);
    setSelectedCoin(coins.filter((c) => c.symbol === coin.symbol)[0]);
  };

  const onClickHandle = async (value) => {
    const payload = { userId: userData.id, txId: value };
    setIsLoading(true);
    AppStatesApi.getDepositBTC(payload)
      .then((response) => {
        showToast(`Success: amount is ${response.data.data.amount}`, "success");
      })
      .catch((error) => {
        showToast(`${error?.response?.data?.message}`, "error");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="flex flex-col lg:flex-row gap-8">
      <div className="flex flex-col lg:w-1/3 py-3 gap-6">
        <PrimarySelectBox
          label={t("Select Coin")}
          options={coins}
          render={optionRenderFn}
          onChange={onCoinSelectionChange}
          className="mb-4"
          defaultSelect={selectedCoin}
        />
        <div className="flex flex-col">
          <span className="text-sm text-[#D9D9D9] font-normal">
            {isBTC ? "Bitcoin Address" : "Ethereum Address"}
          </span>
          {isBTC ? (
            userData?.btcWallet ? (
              <AddressCopy address={userData?.btcWallet} />
            ) : (
              <></>
            )
          ) : userData?.wallet ? (
            <AddressCopy address={userData?.wallet} />
          ) : (
            <></>
          )}
        </div>
        {isBTC ? (
          <BTCPayment onClick={onClickHandle} isLoading={isLoading} />
        ) : null}
      </div>
      <div className="flex lg:w-1/3">
        <div
          className={`flex flex-col gap-10 ${
            theme === "dark" ? "text-[#D9D9D9]" : "text-[#5F5F5F]"
          } text-sm font-normal`}
        >
          <div>
            {`This address only supports deposit of ${
              selectedCoin?.symbol
            } assets ${
              selectedCoin?.symbol === constants.BTC_SYMBOL
                ? `on Bitcoin network`
                : `on Ethereum`
            }. 
            Do not deposit other assets to this address as the assets will not be credited or
            recoverable.`}
          </div>
          <div>
            {selectedCoin?.symbol === constants.BTC_SYMBOL
              ? `Please note: If the single deposit amount is less than the minimum deposit amount, it will not be credited.`
              : null}
          </div>
          <div>
            {`The platform will not be liable for any loss of assets resulting
            from this. Thank you for your understanding and support!`}
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:w-1/3 gap-4">
        <div className="flex w-[124px] h-[124px] bg-white p-3 border border-[#6E7A8A] rounded-md">
          <QRcode
            size={128}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={
              isBTC
                ? userData?.btcWallet
                  ? userData?.btcWallet
                  : ""
                : userData?.wallet
                ? userData?.wallet
                : ""
            }
            viewBox={`0 0 128 128`}
            level={"L"}
          />
        </div>
      </div>
    </div>
  );
};

export default CryptoPayment;
