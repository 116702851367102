const USEUIcon = ({ isDarkMode }) => {
  return (
    <>
      {isDarkMode ? (
        <svg
          width="69"
          height="69"
          viewBox="0 0 69 69"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_735_10811)">
            <path
              d="M34.5 69C53.5538 69 69 53.5538 69 34.5C69 15.4462 53.5538 0 34.5 0C15.4462 0 0 15.4462 0 34.5C0 53.5538 15.4462 69 34.5 69Z"
              fill="url(#paint0_linear_735_10811)"
            />
            <path
              d="M34.4999 67.928C52.9619 67.928 67.9283 52.9616 67.9283 34.4996C67.9283 16.0377 52.9619 1.07129 34.4999 1.07129C16.0379 1.07129 1.07153 16.0377 1.07153 34.4996C1.07153 52.9616 16.0379 67.928 34.4999 67.928Z"
              fill="url(#paint1_linear_735_10811)"
            />
            <path
              d="M34.4999 64.5467C51.0943 64.5467 64.5467 51.0943 64.5467 34.4999C64.5467 17.9055 51.0943 4.45312 34.4999 4.45312C17.9055 4.45312 4.45312 17.9055 4.45312 34.4999C4.45312 51.0943 17.9055 64.5467 34.4999 64.5467Z"
              fill="url(#paint2_radial_735_10811)"
            />
            <g filter="url(#filter0_d_735_10811)">
              <path
                d="M51.9941 42.0865C50.844 43.0013 49.0944 43.7315 47.005 43.7315C43.1399 43.7315 39.9528 41.6944 38.6721 38.272H36.2952V35.6076H38.1232C38.097 35.3463 38.097 35.1894 38.097 34.8758C38.097 34.6667 38.097 34.4576 38.1232 34.1962H36.2952V31.558H38.6721C39.9512 28.1356 43.2167 25.9678 46.9528 25.9678C48.9376 25.9678 50.2706 26.4121 51.4452 27.1423L49.9831 30.2772C49.2251 29.7545 48.1551 29.5192 46.9528 29.5192C45.4646 29.5192 43.9486 30.1204 43.0354 31.5563H49.4081L48.1812 34.1946H42.2513C42.2251 34.456 42.2251 34.6389 42.2251 34.8742C42.2251 35.0833 42.2251 35.3446 42.2513 35.606H47.6323L46.4578 38.2704H43.1922C44.0809 39.4972 45.4646 40.0984 47.005 40.0984C48.3364 40.0984 49.4342 39.7063 50.1922 39.1052L51.9941 42.0832V42.0865Z"
                fill="#141413"
              />
              <path
                d="M23.6055 49.7925V46.1659C18.8942 45.5222 16.6561 42.0982 16.3523 39.2165L20.5555 38.2004C20.7597 40.3356 22.1826 42.3024 25.3012 42.3024C27.5049 42.3024 28.7922 41.252 28.7922 39.7605C28.7922 38.6088 27.9443 37.7267 26.2503 37.3542L23.1317 36.6763C19.4708 35.8628 17.2001 33.5578 17.2001 30.3036C17.2001 26.7113 19.9789 24.0665 23.6055 23.4899V19.8633H27.0622V23.5912C30.9599 24.3034 32.6556 27.0152 33.1294 29.2859L28.9604 30.4719C28.7562 29.2859 27.8757 27.319 25.097 27.319C22.9275 27.319 21.7415 28.5736 21.7415 29.9622C21.7415 31.0126 22.4881 31.8947 24.0809 32.2329L27.1651 32.9452C31.4353 33.8943 33.3679 36.4362 33.3679 39.4861C33.3679 42.536 31.1315 45.4863 27.0638 46.1299V49.7908H23.6071L23.6055 49.7925Z"
                fill="#141413"
              />
            </g>
            <path
              d="M52.321 41.7594C51.1709 42.6742 49.4213 43.4044 47.332 43.4044C43.4669 43.4044 40.2797 41.3673 38.999 37.9449H36.6221V35.2805H38.4501C38.4239 35.0191 38.4239 34.8623 38.4239 34.5486C38.4239 34.3395 38.4239 34.1304 38.4501 33.8691H36.6221V31.2308H38.999C40.2781 27.8084 43.5436 25.6406 47.2797 25.6406C49.2645 25.6406 50.5975 26.085 51.7721 26.8152L50.31 29.9501C49.552 29.4273 48.482 29.1921 47.2797 29.1921C45.7915 29.1921 44.2755 29.7932 43.3623 31.2292H49.735L48.5081 33.8674H42.5782C42.552 34.1288 42.552 34.3118 42.552 34.547C42.552 34.7561 42.552 35.0175 42.5782 35.2789H47.9593L46.7847 37.9433H43.5191C44.4078 39.1701 45.7915 39.7713 47.332 39.7713C48.6633 39.7713 49.7611 39.3792 50.5191 38.778L52.321 41.7561V41.7594Z"
              fill="url(#paint3_linear_735_10811)"
            />
            <path
              d="M23.9321 49.4653V45.8387C19.2208 45.1951 16.9828 41.7711 16.679 38.8894L20.8822 37.8733C21.0864 40.0084 22.5093 41.9753 25.6278 41.9753C27.8315 41.9753 29.1188 40.9249 29.1188 39.4334C29.1188 38.2817 28.271 37.3996 26.5769 37.0271L23.4584 36.3491C19.7975 35.5356 17.5268 33.2306 17.5268 29.9765C17.5268 26.3842 20.3055 23.7394 23.9321 23.1627V19.5361H27.3888V23.264C31.2866 23.9763 32.9823 26.688 33.456 28.9587L29.2871 30.1447C29.0829 28.9587 28.2024 26.9919 25.4236 26.9919C23.2542 26.9919 22.0682 28.2465 22.0682 29.635C22.0682 30.6855 22.8148 31.5676 24.4075 31.9058L27.4918 32.618C31.762 33.5671 33.6945 36.109 33.6945 39.1589C33.6945 42.2089 31.4581 45.1592 27.3905 45.8028V49.4637H23.9338L23.9321 49.4653Z"
              fill="url(#paint4_linear_735_10811)"
            />
            <g filter="url(#filter1_d_735_10811)">
              <path
                d="M29.6907 9.95506L29.774 8.00781C27.8415 9.5826 23.9502 12.7551 22.0177 14.3315C24.5073 14.1926 29.5192 13.91 32.0088 13.7695L30.6153 12.4267C34.0981 11.7716 37.7509 11.953 41.1618 12.9772C53.3321 16.6218 60.1965 29.6056 56.3396 41.7302C51.7655 56.1876 34.5163 62.3773 21.7236 54.2452C19.2487 52.6933 17.0826 50.6595 15.3297 48.3185C15.3052 48.2859 15.2578 48.2777 15.2235 48.3022C15.1892 48.3267 15.1811 48.3741 15.2056 48.4084C16.7542 50.5925 18.6802 52.5316 20.8594 54.0966C29.4048 60.2748 41.4101 59.9873 49.6794 53.4088C54.8677 49.3706 58.3179 43.1057 58.8913 36.5305C60.4661 20.2075 45.5971 6.98355 29.694 9.9567L29.6907 9.95506Z"
                fill="url(#paint5_linear_735_10811)"
              />
            </g>
            <path
              d="M33.6945 39.1609C33.6945 36.111 31.762 33.5674 27.4918 32.62L24.4075 31.9077C22.8148 31.5679 22.0682 30.6874 22.0682 29.637C22.0682 28.2468 23.2542 26.9938 25.4236 26.9938C28.2024 26.9938 29.0845 28.9591 29.2871 30.1467L33.456 28.9607C32.9806 26.69 31.2866 23.9782 27.3888 23.266V19.5381H23.9321V23.1647C20.3055 23.7413 17.5268 26.3845 17.5268 29.9784C17.5268 33.2326 19.7975 35.5376 23.4584 36.3511L26.5769 37.029C28.271 37.4015 29.1188 38.2836 29.1188 39.4353C29.1188 40.9268 27.8315 41.9772 25.6278 41.9772C22.5093 41.9772 21.0864 40.012 20.8822 37.8752L16.679 38.8913C16.9844 41.773 19.2208 45.1954 23.9321 45.8407V49.4673H27.3888V45.8064C31.4565 45.1627 33.6929 42.2811 33.6929 39.1625L33.6945 39.1609ZM27.3398 45.4813L27.0638 45.5254V49.1389H24.2605V45.5532L23.9779 45.514C20.8871 45.0909 17.5905 42.959 17.04 39.138L20.6045 38.2755C20.7744 39.3439 21.2302 40.2652 21.9326 40.9562C22.8262 41.8351 24.1037 42.299 25.6278 42.299C27.9475 42.299 29.4455 41.1735 29.4455 39.4304C29.4455 38.643 29.0812 37.2398 26.6455 36.704L23.5286 36.026C19.9217 35.2239 17.8535 33.0186 17.8535 29.9719C17.8535 26.672 20.317 24.0648 23.9844 23.4816L24.2605 23.4375V19.8583H27.0638V23.5322L27.3317 23.5812C31.4222 24.3278 32.6866 27.2291 33.0689 28.7255L29.5338 29.7318C29.2675 28.6993 28.3674 26.6622 25.4236 26.6622C22.8932 26.6622 21.7415 28.2027 21.7415 29.6321C21.7415 30.9275 22.689 31.8718 24.334 32.2214L27.4215 32.9336C31.2572 33.7863 33.3678 35.9966 33.3678 39.156C33.3678 42.3154 30.9452 44.9063 27.3382 45.4764L27.3398 45.4813Z"
              fill="url(#paint6_linear_735_10811)"
            />
            <path
              d="M47.332 39.7749C45.7915 39.7749 44.4062 39.1737 43.5192 37.9469H46.7848L47.961 35.2825H42.5799C42.5537 35.0211 42.5537 34.7597 42.5537 34.5506C42.5537 34.3154 42.5537 34.1324 42.5799 33.871H48.5099L49.7367 31.2328H43.364C44.2788 29.7968 45.7932 29.1957 47.2814 29.1957C48.4821 29.1957 49.5537 29.4309 50.3117 29.9536L51.7738 26.8188C50.5976 26.0869 49.2662 25.6426 47.2814 25.6426C43.547 25.6426 40.2814 27.8104 39.0007 31.2328H36.6238V33.871H38.4518C38.4256 34.1324 38.4256 34.3415 38.4256 34.5506C38.4256 34.8643 38.4256 35.0211 38.4518 35.2825H36.6238V37.9469H39.0007C40.2798 41.3692 43.4669 43.4063 47.3337 43.4063C49.423 43.4063 51.1726 42.6745 52.3227 41.7613L50.5208 38.7833C49.7628 39.3844 48.6667 39.7765 47.3337 39.7765L47.332 39.7749ZM47.332 43.078C43.5911 43.078 40.5166 41.067 39.3062 37.8309L39.2261 37.6185H36.9489V35.6075H38.8112L38.7752 35.2481C38.7507 35.0031 38.7507 34.861 38.7507 34.549C38.7507 34.3431 38.7507 34.1471 38.7752 33.9021L38.8112 33.5427H36.9489V31.5578H39.2261L39.3062 31.3455C40.5281 28.0783 43.6581 25.9677 47.2798 25.9677C48.9019 25.9677 50.1712 26.2715 51.3507 26.9462L50.1663 29.4832C49.4198 29.0797 48.4314 28.8673 47.2781 28.8673C45.4975 28.8673 43.9701 29.6645 43.0847 31.0563L42.7645 31.5578H49.2205L48.2975 33.5427H42.2809L42.2515 33.8367C42.2238 34.1144 42.2238 34.3056 42.2238 34.549C42.2238 34.7662 42.2238 35.0358 42.2515 35.3119L42.2809 35.6059H47.4562L46.5691 37.6169H42.8772L43.2513 38.1347C44.1677 39.4008 45.6151 40.0983 47.3288 40.0983C48.5033 40.0983 49.5864 39.7994 50.4195 39.2521L51.8865 41.6747C50.6499 42.5683 49.0065 43.0764 47.3288 43.0764L47.332 43.078Z"
              fill="url(#paint7_linear_735_10811)"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_735_10811"
              x="8.35229"
              y="11.8633"
              width="51.6418"
              height="45.9287"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="4" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.9 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_735_10811"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_735_10811"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_d_735_10811"
              x="8.19141"
              y="3.00781"
              width="55.8147"
              height="62.5361"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="-1" dy="1" />
              <feGaussianBlur stdDeviation="3" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.9 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_735_10811"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_735_10811"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_735_10811"
              x1="34.5"
              y1="0"
              x2="34.5"
              y2="69"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#333333" />
              <stop offset="0.25" stopColor="#B8B8B8" />
              <stop offset="0.5" stopColor="#BBBBBA" />
              <stop offset="0.75" stopColor="#D4D4D4" />
              <stop offset="1" stopColor="#2B2B2B" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_735_10811"
              x1="67.9283"
              y1="34.4996"
              x2="1.07153"
              y2="34.4996"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#D4D4D4" />
              <stop offset="0.5" stopColor="#4D4D4D" />
              <stop offset="1" stopColor="#7D7D7D" />
            </linearGradient>
            <radialGradient
              id="paint2_radial_735_10811"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(34.4999 34.4999) scale(30.0468)"
            >
              <stop offset="0.3" stopColor="#343433" />
              <stop offset="0.66" stopColor="#323231" />
              <stop offset="0.79" stopColor="#2B2B2A" />
              <stop offset="0.88" stopColor="#1F1F1E" />
              <stop offset="0.95" stopColor="#0E0E0E" />
              <stop offset="1" />
            </radialGradient>
            <linearGradient
              id="paint3_linear_735_10811"
              x1="36.6221"
              y1="34.5241"
              x2="52.321"
              y2="34.5241"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#4A4A4A" />
              <stop offset="0.25" stopColor="#B8B8B8" />
              <stop offset="0.5" stopColor="#A8A8A7" />
              <stop offset="0.75" stopColor="#CCCCCC" />
              <stop offset="1" stopColor="#404040" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_735_10811"
              x1="16.679"
              y1="34.4999"
              x2="33.6945"
              y2="34.4999"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#BA9555" />
              <stop offset="0.12" stopColor="#A67124" />
              <stop offset="0.4" stopColor="#FCF6AC" />
              <stop offset="0.67" stopColor="#D3AC53" />
              <stop offset="0.85" stopColor="#BD882C" />
              <stop offset="1" stopColor="#AB6A30" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_735_10811"
              x1="18.2522"
              y1="30.9877"
              x2="58.6969"
              y2="38.12"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#D4D4D4" />
              <stop offset="0.5" stopColor="#4D4D4D" />
              <stop offset="1" stopColor="#7D7D7D" />
            </linearGradient>
            <linearGradient
              id="paint6_linear_735_10811"
              x1="16.679"
              y1="34.5002"
              x2="33.6945"
              y2="34.5002"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#DBA73E" />
              <stop offset="0.16" stopColor="#D89D3A" />
              <stop offset="0.3" stopColor="#A46B28" />
              <stop offset="0.46" stopColor="#8E5723" />
              <stop offset="0.49" stopColor="#F4F3B8" />
              <stop offset="0.56" stopColor="#F7ED9A" />
              <stop offset="0.69" stopColor="#D5B767" />
              <stop offset="0.75" stopColor="#A76F29" />
              <stop offset="0.85" stopColor="#D0AB5B" />
              <stop offset="1" stopColor="#E0C77D" />
            </linearGradient>
            <linearGradient
              id="paint7_linear_735_10811"
              x1="36.6221"
              y1="34.5245"
              x2="52.321"
              y2="34.5245"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#ABABAB" />
              <stop offset="0.16" stopColor="#A4A4A4" />
              <stop offset="0.3" stopColor="#757575" />
              <stop offset="0.46" stopColor="#525252" />
              <stop offset="0.49" stopColor="#EDEDED" />
              <stop offset="0.56" stopColor="#E7E7E7" />
              <stop offset="0.69" stopColor="#B7B7B7" />
              <stop offset="0.75" stopColor="#666666" />
              <stop offset="0.85" stopColor="#ADADAD" />
              <stop offset="1" stopColor="#C6C6C6" />
            </linearGradient>
            <clipPath id="clip0_735_10811">
              <rect width="69" height="69" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <svg
          width="69"
          height="69"
          viewBox="0 0 69 69"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_735_10811)">
            <path
              d="M34.5 69C53.5538 69 69 53.5538 69 34.5C69 15.4462 53.5538 0 34.5 0C15.4462 0 0 15.4462 0 34.5C0 53.5538 15.4462 69 34.5 69Z"
              fill="url(#paint0_linear_735_10811)"
            />
            <path
              d="M34.4999 67.928C52.9619 67.928 67.9283 52.9616 67.9283 34.4996C67.9283 16.0377 52.9619 1.07129 34.4999 1.07129C16.0379 1.07129 1.07153 16.0377 1.07153 34.4996C1.07153 52.9616 16.0379 67.928 34.4999 67.928Z"
              fill="url(#paint1_linear_735_10811)"
            />
            <path
              d="M34.4999 64.5467C51.0943 64.5467 64.5467 51.0943 64.5467 34.4999C64.5467 17.9055 51.0943 4.45312 34.4999 4.45312C17.9055 4.45312 4.45312 17.9055 4.45312 34.4999C4.45312 51.0943 17.9055 64.5467 34.4999 64.5467Z"
              fill="url(#paint2_radial_735_10811)"
            />
            <g filter="url(#filter0_d_735_10811)">
              <path
                d="M51.9941 42.0865C50.844 43.0013 49.0944 43.7315 47.005 43.7315C43.1399 43.7315 39.9528 41.6944 38.6721 38.272H36.2952V35.6076H38.1232C38.097 35.3463 38.097 35.1894 38.097 34.8758C38.097 34.6667 38.097 34.4576 38.1232 34.1962H36.2952V31.558H38.6721C39.9512 28.1356 43.2167 25.9678 46.9528 25.9678C48.9376 25.9678 50.2706 26.4121 51.4452 27.1423L49.9831 30.2772C49.2251 29.7545 48.1551 29.5192 46.9528 29.5192C45.4646 29.5192 43.9486 30.1204 43.0354 31.5563H49.4081L48.1812 34.1946H42.2513C42.2251 34.456 42.2251 34.6389 42.2251 34.8742C42.2251 35.0833 42.2251 35.3446 42.2513 35.606H47.6323L46.4578 38.2704H43.1922C44.0809 39.4972 45.4646 40.0984 47.005 40.0984C48.3364 40.0984 49.4342 39.7063 50.1922 39.1052L51.9941 42.0832V42.0865Z"
                fill="#141413"
              />
              <path
                d="M23.6055 49.7925V46.1659C18.8942 45.5222 16.6561 42.0982 16.3523 39.2165L20.5555 38.2004C20.7597 40.3356 22.1826 42.3024 25.3012 42.3024C27.5049 42.3024 28.7922 41.252 28.7922 39.7605C28.7922 38.6088 27.9443 37.7267 26.2503 37.3542L23.1317 36.6763C19.4708 35.8628 17.2001 33.5578 17.2001 30.3036C17.2001 26.7113 19.9789 24.0665 23.6055 23.4899V19.8633H27.0622V23.5912C30.9599 24.3034 32.6556 27.0152 33.1294 29.2859L28.9604 30.4719C28.7562 29.2859 27.8757 27.319 25.097 27.319C22.9275 27.319 21.7415 28.5736 21.7415 29.9622C21.7415 31.0126 22.4881 31.8947 24.0809 32.2329L27.1651 32.9452C31.4353 33.8943 33.3679 36.4362 33.3679 39.4861C33.3679 42.536 31.1315 45.4863 27.0638 46.1299V49.7908H23.6071L23.6055 49.7925Z"
                fill="#141413"
              />
            </g>
            <path
              d="M52.321 41.7594C51.1709 42.6742 49.4213 43.4044 47.332 43.4044C43.4669 43.4044 40.2797 41.3673 38.999 37.9449H36.6221V35.2805H38.4501C38.4239 35.0191 38.4239 34.8623 38.4239 34.5486C38.4239 34.3395 38.4239 34.1304 38.4501 33.8691H36.6221V31.2308H38.999C40.2781 27.8084 43.5436 25.6406 47.2797 25.6406C49.2645 25.6406 50.5975 26.085 51.7721 26.8152L50.31 29.9501C49.552 29.4273 48.482 29.1921 47.2797 29.1921C45.7915 29.1921 44.2755 29.7932 43.3623 31.2292H49.735L48.5081 33.8674H42.5782C42.552 34.1288 42.552 34.3118 42.552 34.547C42.552 34.7561 42.552 35.0175 42.5782 35.2789H47.9593L46.7847 37.9433H43.5191C44.4078 39.1701 45.7915 39.7713 47.332 39.7713C48.6633 39.7713 49.7611 39.3792 50.5191 38.778L52.321 41.7561V41.7594Z"
              fill="url(#paint3_linear_735_10811)"
            />
            <path
              d="M23.9321 49.4653V45.8387C19.2208 45.1951 16.9828 41.7711 16.679 38.8894L20.8822 37.8733C21.0864 40.0084 22.5093 41.9753 25.6278 41.9753C27.8315 41.9753 29.1188 40.9249 29.1188 39.4334C29.1188 38.2817 28.271 37.3996 26.5769 37.0271L23.4584 36.3491C19.7975 35.5356 17.5268 33.2306 17.5268 29.9765C17.5268 26.3842 20.3055 23.7394 23.9321 23.1627V19.5361H27.3888V23.264C31.2866 23.9763 32.9823 26.688 33.456 28.9587L29.2871 30.1447C29.0829 28.9587 28.2024 26.9919 25.4236 26.9919C23.2542 26.9919 22.0682 28.2465 22.0682 29.635C22.0682 30.6855 22.8148 31.5676 24.4075 31.9058L27.4918 32.618C31.762 33.5671 33.6945 36.109 33.6945 39.1589C33.6945 42.2089 31.4581 45.1592 27.3905 45.8028V49.4637H23.9338L23.9321 49.4653Z"
              fill="url(#paint4_linear_735_10811)"
            />
            <g filter="url(#filter1_d_735_10811)">
              <path
                d="M29.6907 9.95506L29.774 8.00781C27.8415 9.5826 23.9502 12.7551 22.0177 14.3315C24.5073 14.1926 29.5192 13.91 32.0088 13.7695L30.6153 12.4267C34.0981 11.7716 37.7509 11.953 41.1618 12.9772C53.3321 16.6218 60.1965 29.6056 56.3396 41.7302C51.7655 56.1876 34.5163 62.3773 21.7236 54.2452C19.2487 52.6933 17.0826 50.6595 15.3297 48.3185C15.3052 48.2859 15.2578 48.2777 15.2235 48.3022C15.1892 48.3267 15.1811 48.3741 15.2056 48.4084C16.7542 50.5925 18.6802 52.5316 20.8594 54.0966C29.4048 60.2748 41.4101 59.9873 49.6794 53.4088C54.8677 49.3706 58.3179 43.1057 58.8913 36.5305C60.4661 20.2075 45.5971 6.98355 29.694 9.9567L29.6907 9.95506Z"
                fill="url(#paint5_linear_735_10811)"
              />
            </g>
            <path
              d="M33.6945 39.1609C33.6945 36.111 31.762 33.5674 27.4918 32.62L24.4075 31.9077C22.8148 31.5679 22.0682 30.6874 22.0682 29.637C22.0682 28.2468 23.2542 26.9938 25.4236 26.9938C28.2024 26.9938 29.0845 28.9591 29.2871 30.1467L33.456 28.9607C32.9806 26.69 31.2866 23.9782 27.3888 23.266V19.5381H23.9321V23.1647C20.3055 23.7413 17.5268 26.3845 17.5268 29.9784C17.5268 33.2326 19.7975 35.5376 23.4584 36.3511L26.5769 37.029C28.271 37.4015 29.1188 38.2836 29.1188 39.4353C29.1188 40.9268 27.8315 41.9772 25.6278 41.9772C22.5093 41.9772 21.0864 40.012 20.8822 37.8752L16.679 38.8913C16.9844 41.773 19.2208 45.1954 23.9321 45.8407V49.4673H27.3888V45.8064C31.4565 45.1627 33.6929 42.2811 33.6929 39.1625L33.6945 39.1609ZM27.3398 45.4813L27.0638 45.5254V49.1389H24.2605V45.5532L23.9779 45.514C20.8871 45.0909 17.5905 42.959 17.04 39.138L20.6045 38.2755C20.7744 39.3439 21.2302 40.2652 21.9326 40.9562C22.8262 41.8351 24.1037 42.299 25.6278 42.299C27.9475 42.299 29.4455 41.1735 29.4455 39.4304C29.4455 38.643 29.0812 37.2398 26.6455 36.704L23.5286 36.026C19.9217 35.2239 17.8535 33.0186 17.8535 29.9719C17.8535 26.672 20.317 24.0648 23.9844 23.4816L24.2605 23.4375V19.8583H27.0638V23.5322L27.3317 23.5812C31.4222 24.3278 32.6866 27.2291 33.0689 28.7255L29.5338 29.7318C29.2675 28.6993 28.3674 26.6622 25.4236 26.6622C22.8932 26.6622 21.7415 28.2027 21.7415 29.6321C21.7415 30.9275 22.689 31.8718 24.334 32.2214L27.4215 32.9336C31.2572 33.7863 33.3678 35.9966 33.3678 39.156C33.3678 42.3154 30.9452 44.9063 27.3382 45.4764L27.3398 45.4813Z"
              fill="url(#paint6_linear_735_10811)"
            />
            <path
              d="M47.332 39.7749C45.7915 39.7749 44.4062 39.1737 43.5192 37.9469H46.7848L47.961 35.2825H42.5799C42.5537 35.0211 42.5537 34.7597 42.5537 34.5506C42.5537 34.3154 42.5537 34.1324 42.5799 33.871H48.5099L49.7367 31.2328H43.364C44.2788 29.7968 45.7932 29.1957 47.2814 29.1957C48.4821 29.1957 49.5537 29.4309 50.3117 29.9536L51.7738 26.8188C50.5976 26.0869 49.2662 25.6426 47.2814 25.6426C43.547 25.6426 40.2814 27.8104 39.0007 31.2328H36.6238V33.871H38.4518C38.4256 34.1324 38.4256 34.3415 38.4256 34.5506C38.4256 34.8643 38.4256 35.0211 38.4518 35.2825H36.6238V37.9469H39.0007C40.2798 41.3692 43.4669 43.4063 47.3337 43.4063C49.423 43.4063 51.1726 42.6745 52.3227 41.7613L50.5208 38.7833C49.7628 39.3844 48.6667 39.7765 47.3337 39.7765L47.332 39.7749ZM47.332 43.078C43.5911 43.078 40.5166 41.067 39.3062 37.8309L39.2261 37.6185H36.9489V35.6075H38.8112L38.7752 35.2481C38.7507 35.0031 38.7507 34.861 38.7507 34.549C38.7507 34.3431 38.7507 34.1471 38.7752 33.9021L38.8112 33.5427H36.9489V31.5578H39.2261L39.3062 31.3455C40.5281 28.0783 43.6581 25.9677 47.2798 25.9677C48.9019 25.9677 50.1712 26.2715 51.3507 26.9462L50.1663 29.4832C49.4198 29.0797 48.4314 28.8673 47.2781 28.8673C45.4975 28.8673 43.9701 29.6645 43.0847 31.0563L42.7645 31.5578H49.2205L48.2975 33.5427H42.2809L42.2515 33.8367C42.2238 34.1144 42.2238 34.3056 42.2238 34.549C42.2238 34.7662 42.2238 35.0358 42.2515 35.3119L42.2809 35.6059H47.4562L46.5691 37.6169H42.8772L43.2513 38.1347C44.1677 39.4008 45.6151 40.0983 47.3288 40.0983C48.5033 40.0983 49.5864 39.7994 50.4195 39.2521L51.8865 41.6747C50.6499 42.5683 49.0065 43.0764 47.3288 43.0764L47.332 43.078Z"
              fill="url(#paint7_linear_735_10811)"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_735_10811"
              x="8.35229"
              y="11.8633"
              width="51.6418"
              height="45.9287"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="4" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.9 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_735_10811"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_735_10811"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_d_735_10811"
              x="8.19141"
              y="3.00781"
              width="55.8147"
              height="62.5361"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="-1" dy="1" />
              <feGaussianBlur stdDeviation="3" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.9 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_735_10811"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_735_10811"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_735_10811"
              x1="34.5"
              y1="0"
              x2="34.5"
              y2="69"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#333333" />
              <stop offset="0.25" stopColor="#B8B8B8" />
              <stop offset="0.5" stopColor="#BBBBBA" />
              <stop offset="0.75" stopColor="#D4D4D4" />
              <stop offset="1" stopColor="#2B2B2B" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_735_10811"
              x1="67.9283"
              y1="34.4996"
              x2="1.07153"
              y2="34.4996"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#D4D4D4" />
              <stop offset="0.5" stopColor="#4D4D4D" />
              <stop offset="1" stopColor="#7D7D7D" />
            </linearGradient>
            <radialGradient
              id="paint2_radial_735_10811"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(34.4999 34.4999) scale(30.0468)"
            >
              <stop offset="0.3" stopColor="#343433" />
              <stop offset="0.66" stopColor="#323231" />
              <stop offset="0.79" stopColor="#2B2B2A" />
              <stop offset="0.88" stopColor="#1F1F1E" />
              <stop offset="0.95" stopColor="#0E0E0E" />
              <stop offset="1" />
            </radialGradient>
            <linearGradient
              id="paint3_linear_735_10811"
              x1="36.6221"
              y1="34.5241"
              x2="52.321"
              y2="34.5241"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#4A4A4A" />
              <stop offset="0.25" stopColor="#B8B8B8" />
              <stop offset="0.5" stopColor="#A8A8A7" />
              <stop offset="0.75" stopColor="#CCCCCC" />
              <stop offset="1" stopColor="#404040" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_735_10811"
              x1="16.679"
              y1="34.4999"
              x2="33.6945"
              y2="34.4999"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#BA9555" />
              <stop offset="0.12" stopColor="#A67124" />
              <stop offset="0.4" stopColor="#FCF6AC" />
              <stop offset="0.67" stopColor="#D3AC53" />
              <stop offset="0.85" stopColor="#BD882C" />
              <stop offset="1" stopColor="#AB6A30" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_735_10811"
              x1="18.2522"
              y1="30.9877"
              x2="58.6969"
              y2="38.12"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#D4D4D4" />
              <stop offset="0.5" stopColor="#4D4D4D" />
              <stop offset="1" stopColor="#7D7D7D" />
            </linearGradient>
            <linearGradient
              id="paint6_linear_735_10811"
              x1="16.679"
              y1="34.5002"
              x2="33.6945"
              y2="34.5002"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#DBA73E" />
              <stop offset="0.16" stopColor="#D89D3A" />
              <stop offset="0.3" stopColor="#A46B28" />
              <stop offset="0.46" stopColor="#8E5723" />
              <stop offset="0.49" stopColor="#F4F3B8" />
              <stop offset="0.56" stopColor="#F7ED9A" />
              <stop offset="0.69" stopColor="#D5B767" />
              <stop offset="0.75" stopColor="#A76F29" />
              <stop offset="0.85" stopColor="#D0AB5B" />
              <stop offset="1" stopColor="#E0C77D" />
            </linearGradient>
            <linearGradient
              id="paint7_linear_735_10811"
              x1="36.6221"
              y1="34.5245"
              x2="52.321"
              y2="34.5245"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#ABABAB" />
              <stop offset="0.16" stopColor="#A4A4A4" />
              <stop offset="0.3" stopColor="#757575" />
              <stop offset="0.46" stopColor="#525252" />
              <stop offset="0.49" stopColor="#EDEDED" />
              <stop offset="0.56" stopColor="#E7E7E7" />
              <stop offset="0.69" stopColor="#B7B7B7" />
              <stop offset="0.75" stopColor="#666666" />
              <stop offset="0.85" stopColor="#ADADAD" />
              <stop offset="1" stopColor="#C6C6C6" />
            </linearGradient>
            <clipPath id="clip0_735_10811">
              <rect width="69" height="69" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )}
    </>
  );
};

export default USEUIcon;
