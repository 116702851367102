import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { usePageNum } from "PageNumContext";
import AddressCopy from "components/AddressCopy";
import { useAppData } from "providers/app";
import { useUserData } from "UserDataContext";
import WalletIcon from "components/Svg/Icons/WalletIcon";
import PrimarySpinner from "components/common/PrimarySpinner";
import RefreshIcon from "components/Svg/Icons/RefreshIcon";
import * as AppStatesApi from "api/app";
import { ThemeContext } from "ThemeContext";
import BalanceTable from "./BalanceTable";
import { fixDigitFilter } from "utils/fixDigitFilter";

const MyBalance = () => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const { setPageNum } = usePageNum();
  const { userData } = useUserData();
  const {
    balances,
    setBalances,
    setBalanceLoaded,
    totalBalanceInUsd,
    totalBalanceWalletInUsd,
    balanceLoading,
    balanceWalletLoaded,
    balancesWallet,
  } = useAppData();

  const reloadBalance = () => {
    setBalanceLoaded(false);
    AppStatesApi.getBalances(userData).then((res) => {
      const balances = res?.data?.data.sort((a, b) => {
        if (a.viewOrder < b.viewOrder) {
          return -1;
        } else if (a.viewOrder > b.viewOrder) {
          return 1;
        } else {
          return 0;
        }
      });
      setBalances(balances);
      setBalanceLoaded(true);
    });
  };

  const onRefreshClick = () => {
    reloadBalance();
  };
  return (
    <div
      className={`w-full h-[95%] inline-block font-normal dark rounded-2xl text-xs leading-normal transition duration-150 ease-in-out ${
        theme === "dark" ? "bg-[#263238]" : "bg-[#EBF5FB]"
      } dark:text-gray md:p-8 p-2 text-[#128FC8] transition-all overflow-auto`}
    >
      <div
        className={`flex flex-col justify-between w-full ${
          theme === "dark" ? "bg-[#263238]" : "bg-[#EBF5FB]"
        }`}
      >
        <div
          className={`flex p-2.5 ${
            theme === "dark" ? "bg-[#282c2e]" : "bg-white"
          } w-full md:rounded-2xl rounded-md mb-4`}
        >
          <div
            className={`flex flex-col ${
              theme === "dark"
                ? "bg-black text-white"
                : "bg-white text-[#282c2e]"
            } w-full md:rounded-2xl rounded-md md:p-5 p-2.5 gap-y-4`}
          >
            {/* Header */}
            <div className="self-start w-full">
              <div className="flex flex-row gap-x-2 items-center">
                <WalletIcon isDarkMode={theme === "dark"} />
                <h2 className={`text-[14px]`}>My Balance / Add $</h2>
                <div className="inline-block">
                  <RefreshIcon handleIconClick={onRefreshClick} />
                </div>
              </div>
            </div>
            <div className="flex w-full flex-row justify-between">
              <div className="pt-3 lg:w-1/2 md:w-2/5 w-2/7 flex md:flex-row flex-col gap-4 md:divide-x divide-solid divide-[#263238] ">
                <div className="flex-col">
                  <h2 className="text-[15px] ">{t("Total Balance")}</h2>
                  <div className="flex flex-row gap-4">
                    {userData?.wallet ? (
                      <div className="flex-col">
                        <h2 className="text-[11px]  ">{t("ERC20 Address")}</h2>
                        <AddressCopy address={userData?.wallet} />
                      </div>
                    ) : (
                      <></>
                    )}
                    {userData?.btcWallet ? (
                      <div className="flex-col">
                        <h2 className="text-[11px] ">{t("BTC Address")}</h2>
                        <AddressCopy address={userData?.btcWallet} />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <p className="text-[32px] md:pl-4 leading-10">
                  ${" "}
                  {fixDigitFilter(totalBalanceInUsd + totalBalanceWalletInUsd)}
                </p>
              </div>

              <div className="lg:w-1/2 md:3/5 w-4/7 pt-7 pb-1 flex justify-end lg:flex-row flex-col gap-2">
                {/* Buttons */}
                <button
                  className="px-4 py-2 text-[12px] text-[#ffffff] transition-all bg-[#128FC8]-400 rounded-md  hover:opacity-80 hover:shadow-2xl bg-[#0084C9]"
                  onClick={() => {
                    setPageNum(2);
                  }}
                >
                  {t("Add Balance +")}
                </button>
                <button
                  className="px-4 py-2 text-[12px] text-[#ffffff] transition-all bg-[#128FC8]-400 rounded-md  hover:opacity-80 hover:shadow-2xl bg-[#0084C9]"
                  onClick={() => {
                    setPageNum(3);
                  }}
                >
                  {t("Withdraw Balance -")}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`flex p-2.5 ${
            theme === "dark" ? "bg-[#282c2e]" : "bg-white"
          } w-full md:rounded-2xl rounded-md mb-4`}
        >
          {balanceLoading ? (
            <PrimarySpinner size="md" />
          ) : (
            <BalanceTable
              data={balances}
              title={`Nexus Wallet : $${fixDigitFilter(totalBalanceInUsd)}`}
            />
          )}
        </div>

        <div
          className={`flex p-2.5 ${
            theme === "dark" ? "bg-[#282c2e]" : "bg-white"
          } w-full md:rounded-2xl rounded-md mb-4`}
        >
          {balanceWalletLoaded ? (
            <PrimarySpinner size="md" />
          ) : (
            <BalanceTable
              data={balancesWallet}
              title={`Your Ethereum Wallet : $${fixDigitFilter(
                totalBalanceWalletInUsd
              )}`}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MyBalance;
