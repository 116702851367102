import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
const resources = {
  en: {
    translation: {
      settings: "Settings",
      Language: "Language",
      Timezone: "Timezone",
      Template: "Template",
      "Light Mode": "Light Mode",
      "Dark Mode": "Dark Mode",
      Platform: "Platform",
      "Automatic Graphic Scrolling": "Automatic Graphic Scrolling",
      "Auto-Scrolling": "Auto-Scrolling",
      "Open Trade Without Confirmation": "Open Trade Without Confirmation",
      "Use optimized rendering for chart and candles":
        "Use optimized rendering for chart and candles",
      "Performance Mode": "Performance Mode",
      "Use Short Order Element Mode": "Use Short Order Element Mode",
      "Short Order Label": "Short Order Label",
      "Graphic Colors": "Graphic Colors",
      "Up Trend": "Up Trend",
      "Down Trend": "Down Trend",
      buy: "Buy",
      "Buy EU/US with Fiat Money or Crypto":
        "Buy EU/US with Fiat Money or Crypto",
      "Set Amount to buy for EU/US": "Set Amount to buy for EU/US",
      "Buy with Fiat": "Buy with Fiat",
      "Buy with Crypto": "Buy with Crypto",
      "Total Balance": "Total Balance",
      "Add +": "Add +",
      "Withdraw -": "Withdraw -",
      "Transfer ↓↑": "Transfer ↓↑",
      addBalance: "ADD BALANCE",
      selectCoin: "SELECT COIN",
      token: "token",
      depositTo: "DEPOSIT TO",
      address: "ADDRESS",
      protocolSelection:
        "NEXUSPRO SUPPORTS TWO WAYS TO DEPOSIT BNB. IN ADDITION TO THE BINANCE NETWORK, YOU ARE NOW ABLE TO DEPOSIT BNB USING THE TRON NETWORK. SELECT A PROTOCOL TO SEE THE CORRESPONDING ADDRESS.",
      sendBnbOnly:
        "ONLY SEND BNB TO THIS ADDRESS. SENDING ANY OTHER ASSET TO THIS ADDRESS MAY RESULT IN THE LOSS OF YOUR DEPOSIT!",
      ensureBnbNetwork: "ENSURE THE NETWORK IS BNB(BNB)",
      setAmount: "SET AMOUNT TO ADD FUNDS",
      setAmountToAddFunds: "Set Amount to add Funds",
      depositToAddBalance: "Deposit to Add Balance!",
      addFunds: "Add Funds",
      withdrawBalance: "Withdraw Balance",
      sendTo: "Send To",
      withdrawalAmount: "Withdrawal Amount",
      amount: "Amount",
      usdt: "USDT",
      max: "MAX",
      receiveAmount: "Receive Amount",
      withdraw: "Withdraw",
      networkFeeIncluded: "network fee included",
      protocolSelectionWithdraw:
        "NexusPro supports withdrawing Tether USDT to the ETH/ERC-20, BSC and Tron networks. Enter a valid address for the network to be auto-detected. The transaction fee will also update based on the network.",
      ensureNetworkMatchWithdraw:
        "Ensure the network you choose to deposit matches the withdraw network，or assets may be lost",
      withdrawHistory: "Withdraw History",
      dashboard: "Dashboard",
      "myBalance/add": "My Balance / Add $",
      myPositions: "My Positions",
      tradingHistory: "Trading History",
      myAccount: "My Account",
      support: "Support",
    },
  },
  sp: {
    translation: {
      settings: "Configuración",
      Language: "Idioma",
      Timezone: "Zona horaria",
      Template: "Plantilla",
      "Light Mode": "Modo Claro",
      "Dark Mode": "Modo Oscuro",
      Platform: "Plataforma",
      "Automatic Graphic Scrolling": "Desplazamiento gráfico automático",
      "Auto-Scrolling": "Desplazamiento automático",
      "Open Trade Without Confirmation": "Abrir operación sin confirmación",
      "Use optimized rendering for chart and candles":
        "Utilizar renderización optimizada para gráficos y velas",
      "Performance Mode": "Modo de rendimiento",
      "Use Short Order Element Mode":
        "Utilizar modo de elemento de orden corto",
      "Short Order Label": "Etiqueta de orden corta",
      "Graphic Colors": "Colores gráficos",
      "Up Trend": "Tendencia alcista",
      "Down Trend": "Tendencia a la baja",
      buy: "Comprar",
      "Buy EU/US with Fiat Money or Crypto":
        "Comprar EU/US con dinero fiduciario o cripto",
      "Set Amount to buy for EU/US": "Establecer cantidad a comprar para EU/US",
      "Buy with Fiat": "Comprar por Fiat",
      "Buy with Crypto": "Comprar por Cripto",
      "Total Balance": "Saldo Total",
      "Add +": "Añadir +",
      "Withdraw -": "Retirar -",
      "Transfer ↓↑": "Transferir ↓↑",
      addBalance: "AGREGAR SALDO",
      selectCoin: "SELECCIONAR MONEDA",
      token: "token",
      depositTo: "DEPOSITAR EN",
      address: "DIRECCIÓN",
      protocolSelection:
        "NexusPro admite dos formas de depositar BNB. Además de la red de Binance, ahora puedes depositar BNB utilizando la red TRON. Selecciona un protocolo para ver la dirección correspondiente.",
      sendBnbOnly:
        "Solo envíe BNB a esta dirección. Enviar cualquier otro activo a esta dirección puede resultar en la pérdida de su depósito.",
      ensureBnbNetwork: "Asegúrate de que la red sea BNB (BNB)",
      setAmount: "ESTABLECER MONTO PARA AGREGAR FONDOS",
      setAmountToAddFunds: "Establecer monto para agregar fondos",
      depositToAddBalance: "¡Depositar para añadir saldo!",
      addFunds: "Añadir fondos",
      withdrawBalance: "Retirar saldo",
      sendTo: "Enviar a",
      withdrawalAmount: "Monto de retiro",
      amount: "Cantidad",
      usdt: "USDT",
      max: "MÁX",
      receiveAmount: "Monto a recibir",
      withdraw: "Retirar",
      networkFeeIncluded: "tarifa de red incluida",
      protocolSelectionWithdraw:
        "NexusPro admite la retirada de Tether USDT a las redes ETH/ERC-20, BSC y Tron. Ingrese una dirección válida para que la red se detecte automáticamente. La tarifa de transacción también se actualizará según la red.",
      ensureNetworkMatchWithdraw:
        "Asegúrese de que la red que elija para depositar coincida con la red de retiro, o los activos pueden perderse",
      withdrawHistory: "Historial de Retiros",
      dashboard: "Tablero",
      "myBalance/add": "Mi Saldo / Añadir $",
      myPositions: "Mis Posiciones",
      tradingHistory: "Historial de Trading",
      myAccount: "Mi Cuenta",
      support: "Soporte",
    },
  },
  ru: {
    translation: {
      settings: "Настройки",
      Language: "Язык",
      Timezone: "Часовой пояс",
      Template: "Шаблон",
      "Light Mode": "Светлый режим",
      "Dark Mode": "Тёмный режим",
      Platform: "Платформа",
      "Automatic Graphic Scrolling": "Автоматическая прокрутка графики",
      "Auto-Scrolling": "Автоматическая прокрутка",
      "Open Trade Without Confirmation": "Открыть сделку без подтверждения",
      "Use optimized rendering for chart and candles":
        "Использовать оптимизированный рендеринг для графиков и свечей",
      "Performance Mode": "Режим производительности",
      "Use Short Order Element Mode":
        "Использовать режим короткого элемента заказа",
      "Short Order Label": "Метка короткого заказа",
      "Graphic Colors": "Графические цвета",
      "Up Trend": "Восходящий тренд",
      "Down Trend": "Нисходящий тренд",
      buy: "Купить",
      "Buy EU/US with Fiat Money or Crypto":
        "Покупка EU/US за фиатные деньги или крипто",
      "Set Amount to buy for EU/US": "Установить сумму для покупки EU/US",
      "Buy with Fiat": "Купить за фиат",
      "Buy with Crypto": "Купить за крипто",
      "Total Balance": "Общий баланс",
      "Add +": "Добавить +",
      "Withdraw -": "Вывести -",
      "Transfer ↓↑": "Перевести ↓↑",
      addBalance: "ПОПОЛНИТЬ БАЛАНС",
      selectCoin: "ВЫБРАТЬ МОНЕТУ",
      token: "token",
      depositTo: "ДЕПОЗИТ НА",
      address: "АДРЕС",
      protocolSelection:
        "NexusPro поддерживает два способа депонирования BNB. В дополнение к сети Binance, теперь вы можете депонировать BNB, используя сеть TRON. Выберите протокол, чтобы увидеть соответствующий адрес.",
      sendBnbOnly:
        "Отправляйте только BNB по этому адресу. Отправка любого другого актива на этот адрес может привести к потере вашего депозита!",
      ensureBnbNetwork: "Убедитесь, что сеть - BNB (BNB)",
      setAmount: "УСТАНОВИТЬ СУММУ ДЛЯ ДОБАВЛЕНИЯ СРЕДСТВ",
      setAmountToAddFunds: "Установить сумму для добавления средств",
      depositToAddBalance: "Депозит для добавления баланса!",
      addFunds: "Добавить средства",
      withdrawBalance: "Вывести баланс",
      sendTo: "Отправить на",
      withdrawalAmount: "Сумма вывода",
      amount: "Сумма",
      usdt: "USDT",
      max: "МАКС",
      receiveAmount: "Получить сумму",
      withdraw: "Вывести",
      networkFeeIncluded: "включая сетевую комиссию",
      protocolSelectionWithdraw:
        "NexusPro поддерживает вывод Tether USDT на сети ETH/ERC-20, BSC и Tron. Введите действительный адрес для автоматического определения сети. Комиссия за транзакцию также обновится в зависимости от сети.",
      ensureNetworkMatchWithdraw:
        "Убедитесь, что сеть, которую вы выбрали для депозита, соответствует сети вывода, иначе активы могут быть потеряны",
      withdrawHistory: "История вывода",
      dashboard: "Панель управления",
      "myBalance/add": "Мой баланс / Добавить $",
      myPositions: "Мои позиции",
      tradingHistory: "История торговли",
      myAccount: "Мой аккаунт",
      support: "Поддержка",
    },
  },
  zh: {
    translation: {
      settings: "设置",
      Language: "语言",
      Timezone: "时区",
      Template: "模板",
      "Light Mode": "亮模式",
      "Dark Mode": "暗模式",
      Platform: "平台",
      "Automatic Graphic Scrolling": "自动图形滚动",
      "Auto-Scrolling": "自动滚动",
      "Open Trade Without Confirmation": "无需确认即可开仓",
      "Use optimized rendering for chart and candles":
        "使用优化的图表和蜡烛渲染",
      "Performance Mode": "性能模式",
      "Use Short Order Element Mode": "使用短订单元素模式",
      "Short Order Label": "短订单标签",
      "Graphic Colors": "图形颜色",
      "Up Trend": "上升趋势",
      "Down Trend": "下降趋势",
      buy: "购买",
      "Buy EU/US with Fiat Money or Crypto": "使用法币或加密货币购买EU/US",
      "Set Amount to buy for EU/US": "设置购买EU/US的金额",
      "Buy with Fiat": "使用法币购买",
      "Buy with Crypto": "使用加密货币购买",
      "Total Balance": "总余额",
      "Add +": "加 +",
      "Withdraw -": "提款 -",
      "Transfer ↓↑": "转账 ↓↑",
      addBalance: "添加余额",
      selectCoin: "选择币种",
      token: "token",
      depositTo: "存入",
      address: "地址",
      protocolSelection:
        "NexusPro支持两种存款方式，除了Binance网络外，您现在还可以使用TRON网络存款BNB。选择协议以查看相应的地址。",
      sendBnbOnly:
        "请仅将BNB发送到此地址。将任何其他资产发送到此地址可能会导致您的存款损失！",
      ensureBnbNetwork: "确保网络为BNB（BNB）",
      setAmount: "设置金额以添加资金",
      setAmountToAddFunds: "设置金额以添加资金",
      depositToAddBalance: "存款以添加余额！",
      addFunds: "添加资金",
      withdrawBalance: "提取余额",
      sendTo: "发送至",
      withdrawalAmount: "提取金额",
      amount: "金额",
      usdt: "USDT",
      max: "最大",
      receiveAmount: "收到金额",
      withdraw: "提取",
      networkFeeIncluded: "包含网络费",
      protocolSelectionWithdraw:
        "NexusPro支持将Tether USDT提取到ETH/ERC-20、BSC和Tron网络。输入有效地址以自动检测网络。交易费也将根据网络更新。",
      ensureNetworkMatchWithdraw:
        "确保您选择的存款网络与提取网络匹配，否则资产可能会丢失",
      withdrawHistory: "提取历史",
      dashboard: "仪表板",
      "myBalance/add": "我的余额 / 添加 $",
      myPositions: "我的持仓",
      tradingHistory: "交易历史",
      myAccount: "我的账户",
      support: "支持",
    },
  },
  ar: {
    translation: {
      settings: "الإعدادات",
      Language: "لغة",
      Timezone: "المنطقة الزمنية",
      Template: "قالب",
      "Light Mode": "وضع النور",
      "Dark Mode": "وضع الظلام",
      Platform: "منصة",
      "Automatic Graphic Scrolling": "التمرير التلقائي للرسوم البيانية",
      "Auto-Scrolling": "التمرير التلقائي",
      "Open Trade Without Confirmation": "فتح تداول بدون تأكيد",
      "Use optimized rendering for chart and candles":
        "استخدام تقديم الرسوم البيانية والشموع بشكل محسن",
      "Performance Mode": "وضع الأداء",
      "Use Short Order Element Mode": "استخدام وضع العنصر للطلب القصير",
      "Short Order Label": "علامة الطلب القصير",
      "Graphic Colors": "ألوان الرسوم البيانية",
      "Up Trend": "الاتجاه الصاعد",
      "Down Trend": "اتجاه هابط",
      buy: "شراء",
      "Buy EU/US with Fiat Money or Crypto":
        "شراء EU/US بالأموال الورقية أو العملات الرقمية",
      "Set Amount to buy for EU/US": "تحديد المبلغ لشراء EU/US",
      "Buy with Fiat": "شراء بالأموال الورقية",
      "Buy with Crypto": "شراء بالعملات الرقمية",
      "Total Balance": "الرصيد الإجمالي",
      "Add +": "إضافة +",
      "Withdraw -": "سحب -",
      "Transfer ↓↑": "تحويل ↓↑",
      addBalance: "إضافة رصيد",
      selectCoin: "اختيار العملة",
      token: "token",
      depositTo: "إيداع في",
      address: "العنوان",
      protocolSelection:
        "1. يدعم NexusPro طريقتين لإيداع BNB. بالإضافة إلى شبكة Binance ، يمكنك الآن إيداع BNB باستخدام شبكة TRON. حدد بروتوكول لرؤية العنوان المقابل. 2. أرسل فقط BNB إلى هذا العنوان. قد يؤدي إرسال أي أصل آخر إلى فقدان إيداعك! 3. تأكد من أن الشبكة هي BNB (BNB)",
      sendBnbOnly:
        "أرسل فقط BNB إلى هذا العنوان. قد يؤدي إرسال أي أصل آخر إلى فقدان إيداعك!",
      ensureBnbNetwork: "تأكد من أن الشبكة هي BNB (BNB)",
      setAmount: "تحديد المبلغ لإضافة الأموال",
      setAmountToAddFunds: "تحديد المبلغ لإضافة الأموال",
      depositToAddBalance: "إيداع لإضافة رصيد!",
      addFunds: "إضافة الأموال",
      withdrawBalance: "سحب الرصيد",
      sendTo: "إرسال إلى",
      withdrawalAmount: "مبلغ السحب",
      amount: "المبلغ",
      usdt: "USDT",
      max: "الحد الأقصى",
      receiveAmount: "مبلغ الاستلام",
      withdraw: "سحب",
      networkFeeIncluded: "تكلفة الشبكة مدرجة",
      protocolSelectionWithdraw:
        "تدعم NexusPro سحب Tether USDT إلى شبكات ETH/ERC-20 و BSC و Tron. أدخل عنوانًا صالحًا لاكتشاف الشبكة تلقائيًا. ستتحديث رسوم المعاملة أيضًا بناءً على الشبكة.",
      ensureNetworkMatchWithdraw:
        "تأكد من أن الشبكة التي تختارها للإيداع تتطابق مع شبكة السحب، وإلا قد يتم فقدان الأصول",
      withdrawHistory: "تاريخ السحب",
      dashboard: "لوحة التحكم",
      "myBalance/add": "رصيدي / إضافة $",
      myPositions: "مراكزي",
      tradingHistory: "تاريخ التداول",
      myAccount: "حسابي",
      support: "الدعم",
    },
  },
  fr: {
    translation: {
      settings: "Paramètres",
      Language: "Langue",
      Timezone: "Fuseau horaire",
      Template: "Modèle",
      "Light Mode": "Mode clair",
      "Dark Mode": "Mode sombre",
      Platform: "Plateforme",
      "Automatic Graphic Scrolling": "Défilement graphique automatique",
      "Auto-Scrolling": "Défilement automatique",
      "Open Trade Without Confirmation": "Ouvrir un trade sans confirmation",
      "Use optimized rendering for chart and candles":
        "Utiliser un rendu optimisé pour les graphiques et les bougies",
      "Performance Mode": "Mode performance",
      "Use Short Order Element Mode": "Utiliser le mode élément d'ordre court",
      "Short Order Label": "Étiquette d'ordre court",
      "Graphic Colors": "Couleurs graphiques",
      "Up Trend": "Tendance haussière",
      "Down Trend": "Tendance baissière",
      buy: "Acheter",
      "Buy EU/US with Fiat Money or Crypto":
        "Acheter EU/US avec de l'argent fiat ou de la cryptomonnaie",
      "Set Amount to buy for EU/US": "Définir le montant à acheter pour EU/US",
      "Buy with Fiat": "Acheter par Fiat",
      "Buy with Crypto": "Acheter par Crypto",
      "Total Balance": "Solde total",
      "Add +": "Ajouter +",
      "Withdraw -": "Retirer -",
      "Transfer ↓↑": "Transfert ↓↑",
      addBalance: "AJOUTER DU SOLDE",
      selectCoin: "SÉLECTIONNER UNE CRYPTO",
      token: "token",
      depositTo: "DÉPOSER DANS",
      address: "ADRESSE",
      protocolSelection:
        "1. NexusPro prend en charge deux façons de déposer du BNB. En plus du réseau Binance, vous pouvez désormais déposer du BNB en utilisant le réseau TRON. Sélectionnez un protocole pour voir l'adresse correspondante. 2. Envoyez uniquement du BNB à cette adresse. Envoyer tout autre actif à cette adresse peut entraîner la perte de votre dépôt ! 3. Assurez-vous que le réseau est BNB (BNB)",
      sendBnbOnly:
        "Envoyez uniquement du BNB à cette adresse. Envoyer tout autre actif à cette adresse peut entraîner la perte de votre dépôt !",
      ensureBnbNetwork: "Assurez-vous que le réseau est BNB (BNB)",
      setAmount: "DÉFINIR LE MONTANT POUR AJOUTER DES FONDS",
      setAmountToAddFunds: "Définir le montant pour ajouter des fonds",
      depositToAddBalance: "Déposer pour ajouter du solde !",
      addFunds: "Ajouter des fonds",
      withdrawBalance: "Retirer le solde",
      sendTo: "Envoyer à",
      withdrawalAmount: "Montant du retrait",
      amount: "Montant",
      usdt: "USDT",
      max: "MAX",
      receiveAmount: "Montant à recevoir",
      withdraw: "Retirer",
      networkFeeIncluded: "frais de réseau inclus",
      protocolSelectionWithdraw:
        "NexusPro prend en charge le retrait de Tether USDT vers les réseaux ETH/ERC-20, BSC et Tron. Entrez une adresse valide pour que le réseau soit détecté automatiquement. Les frais de transaction seront également mis à jour en fonction du réseau.",
      ensureNetworkMatchWithdraw:
        "Assurez-vous que le réseau que vous choisissez pour déposer correspond au réseau de retrait, sinon les actifs peuvent être perdus",
      withdrawHistory: "Historique des Retraits",
      dashboard: "Tableau de bord",
      "myBalance/add": "Mon Solde / Ajouter $",
      myPositions: "Mes Positions",
      tradingHistory: "Historique de Trading",
      myAccount: "Mon Compte",
      support: "Support",
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
    fallbackLng: "sp",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
