const CalculatorIcon = ({ isDarkMode }) => {
  return (
    <>
      {isDarkMode ? (
        <svg
          width="40"
          height="28"
          viewBox="0 0 40 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_735_10726)">
            <path
              d="M36 0H4C1.79086 0 0 1.79086 0 4V24C0 26.2091 1.79086 28 4 28H36C38.2091 28 40 26.2091 40 24V4C40 1.79086 38.2091 0 36 0Z"
              fill="#0084C9"
            />
            <path
              d="M24.8571 6H15.7143C15.2596 6 14.8236 6.18061 14.5021 6.5021C14.1806 6.82359 14 7.25963 14 7.71429V20.2857C14 20.7404 14.1806 21.1764 14.5021 21.4979C14.8236 21.8194 15.2596 22 15.7143 22H24.8571C25.3118 22 25.7478 21.8194 26.0693 21.4979C26.3908 21.1764 26.5714 20.7404 26.5714 20.2857V7.71429C26.5714 7.25963 26.3908 6.82359 26.0693 6.5021C25.7478 6.18061 25.3118 6 24.8571 6ZM15.7143 7.14286H24.8571C25.0087 7.14286 25.154 7.20306 25.2612 7.31022C25.3684 7.41739 25.4286 7.56273 25.4286 7.71429V11.7143H15.1429V7.71429C15.1429 7.56273 15.2031 7.41739 15.3102 7.31022C15.4174 7.20306 15.5627 7.14286 15.7143 7.14286ZM25.4286 20.2857C25.4286 20.4373 25.3684 20.5826 25.2612 20.6898C25.154 20.7969 25.0087 20.8571 24.8571 20.8571H15.7143C15.5627 20.8571 15.4174 20.7969 15.3102 20.6898C15.2031 20.5826 15.1429 20.4373 15.1429 20.2857V12.8571H25.4286V20.2857Z"
              fill="black"
            />
            <path
              d="M23.7143 19.7143C23.8658 19.7143 24.0111 19.6541 24.1183 19.5469C24.2255 19.4398 24.2857 19.2944 24.2857 19.1429V14.5714C24.2857 14.4199 24.2255 14.2745 24.1183 14.1674C24.0111 14.0602 23.8658 14 23.7143 14C23.5627 14 23.4174 14.0602 23.3102 14.1674C23.203 14.2745 23.1428 14.4199 23.1428 14.5714V19.1429C23.1428 19.2944 23.203 19.4398 23.3102 19.5469C23.4174 19.6541 23.5627 19.7143 23.7143 19.7143Z"
              fill="black"
            />
            <path
              d="M16.8571 15.1433C16.9317 15.1423 17.0055 15.1268 17.0742 15.0975C17.1435 15.0686 17.2072 15.028 17.2628 14.9775C17.3157 14.9241 17.3576 14.8608 17.3861 14.7912C17.4145 14.7216 17.4289 14.647 17.4285 14.5718C17.4264 14.4205 17.3672 14.2756 17.2628 14.1661L17.1771 14.0975C17.1452 14.0759 17.1106 14.0586 17.0742 14.0461C17.0402 14.0287 17.0035 14.0171 16.9656 14.0118C16.8923 13.9948 16.8161 13.9948 16.7428 14.0118L16.6399 14.0461C16.6035 14.0586 16.5689 14.0759 16.5371 14.0975L16.4514 14.1661C16.3469 14.2756 16.2877 14.4205 16.2856 14.5718C16.2856 14.7234 16.3458 14.8687 16.453 14.9759C16.5602 15.083 16.7055 15.1433 16.8571 15.1433Z"
              fill="black"
            />
            <path
              d="M18.7371 14.9775C18.7903 15.0311 18.8535 15.0736 18.9231 15.1026C18.9927 15.1316 19.0674 15.1466 19.1429 15.1466C19.2183 15.1466 19.293 15.1316 19.3626 15.1026C19.4322 15.0736 19.4954 15.0311 19.5486 14.9775C19.599 14.922 19.6396 14.8582 19.6686 14.789C19.6978 14.7203 19.7134 14.6465 19.7143 14.5718C19.7122 14.4205 19.653 14.2756 19.5486 14.1661C19.5205 14.1403 19.4898 14.1173 19.4571 14.0975C19.428 14.0749 19.3951 14.0575 19.36 14.0461C19.326 14.0287 19.2893 14.0171 19.2514 14.0118C19.1781 13.9948 19.1019 13.9948 19.0286 14.0118L18.9257 14.0461C18.8893 14.0586 18.8547 14.0759 18.8229 14.0975C18.7929 14.1186 18.7643 14.1415 18.7371 14.1661C18.6842 14.2197 18.6433 14.284 18.6171 14.3547C18.5869 14.4231 18.5713 14.497 18.5713 14.5718C18.5713 14.6466 18.5869 14.7206 18.6171 14.789C18.6461 14.8582 18.6867 14.922 18.7371 14.9775Z"
              fill="black"
            />
            <path
              d="M21.4286 15.1429C21.5802 15.1429 21.7255 15.0827 21.8327 14.9756C21.9398 14.8684 22 14.723 22 14.5715C21.9979 14.4202 21.9387 14.2753 21.8343 14.1658C21.8062 14.1399 21.7756 14.117 21.7429 14.0972C21.7137 14.0746 21.6809 14.0572 21.6457 14.0458C21.6117 14.0284 21.575 14.0168 21.5372 14.0115C21.4277 13.9883 21.3137 14.0003 21.2115 14.0458C21.1413 14.073 21.0772 14.1138 21.0229 14.1658C20.9185 14.2753 20.8593 14.4202 20.8572 14.5715C20.8572 14.723 20.9174 14.8684 21.0245 14.9756C21.1317 15.0827 21.2771 15.1429 21.4286 15.1429Z"
              fill="black"
            />
            <path
              d="M16.8571 17.4284C16.9317 17.4275 17.0055 17.412 17.0742 17.3827C17.1435 17.3538 17.2072 17.3132 17.2628 17.2627C17.3157 17.2093 17.3576 17.146 17.3861 17.0764C17.4145 17.0067 17.4289 16.9322 17.4285 16.857C17.4264 16.7057 17.3672 16.5608 17.2628 16.4513C17.2356 16.4267 17.207 16.4038 17.1771 16.3827C17.1452 16.3611 17.1106 16.3438 17.0742 16.3313C17.0402 16.3139 17.0035 16.3023 16.9656 16.297C16.8923 16.2799 16.8161 16.2799 16.7428 16.297L16.6399 16.3313C16.6035 16.3438 16.5689 16.3611 16.5371 16.3827C16.5071 16.4038 16.4785 16.4267 16.4514 16.4513C16.3469 16.5608 16.2877 16.7057 16.2856 16.857C16.2856 17.0085 16.3458 17.1539 16.453 17.261C16.5602 17.3682 16.7055 17.4284 16.8571 17.4284Z"
              fill="black"
            />
            <path
              d="M18.7371 17.263C18.7903 17.3165 18.8535 17.359 18.9231 17.3881C18.9927 17.4171 19.0674 17.432 19.1429 17.432C19.2183 17.432 19.293 17.4171 19.3626 17.3881C19.4322 17.359 19.4954 17.3165 19.5486 17.263C19.599 17.2074 19.6396 17.1436 19.6686 17.0744C19.6988 17.006 19.7144 16.932 19.7144 16.8573C19.7144 16.7825 19.6988 16.7085 19.6686 16.6401C19.6414 16.57 19.6006 16.5059 19.5486 16.4515C19.5205 16.4257 19.4898 16.4027 19.4571 16.383C19.428 16.3604 19.3951 16.343 19.36 16.3315C19.326 16.3141 19.2893 16.3026 19.2514 16.2973C19.1596 16.2789 19.0647 16.2833 18.975 16.3102C18.8854 16.3371 18.8037 16.3857 18.7371 16.4515C18.6851 16.5059 18.6443 16.57 18.6171 16.6401C18.5869 16.7085 18.5713 16.7825 18.5713 16.8573C18.5713 16.932 18.5869 17.006 18.6171 17.0744C18.6461 17.1436 18.6867 17.2074 18.7371 17.263Z"
              fill="black"
            />
            <path
              d="M21.0228 17.2633C21.1027 17.3439 21.2048 17.3989 21.3161 17.4212C21.4274 17.4435 21.5428 17.4322 21.6477 17.3887C21.7525 17.3452 21.842 17.2714 21.9047 17.1768C21.9675 17.0823 22.0006 16.9711 22 16.8576C21.9978 16.7063 21.9387 16.5614 21.8342 16.4519C21.8061 16.4261 21.7755 16.4031 21.7428 16.3833C21.7136 16.3607 21.6808 16.3433 21.6457 16.3319C21.6116 16.3145 21.5749 16.3029 21.5371 16.2976C21.4276 16.2745 21.3136 16.2865 21.2114 16.3319C21.1405 16.3576 21.0761 16.3985 21.0227 16.4518C20.9694 16.5052 20.9285 16.5696 20.9028 16.6405C20.8708 16.7083 20.8552 16.7827 20.8571 16.8576C20.8562 16.9319 20.8698 17.0057 20.8971 17.0748C20.9302 17.1431 20.9725 17.2065 21.0228 17.2633Z"
              fill="black"
            />
            <path
              d="M16.8571 19.7145C16.9317 19.7136 17.0055 19.6981 17.0742 19.6688C17.1435 19.6399 17.2072 19.5993 17.2628 19.5488C17.3157 19.4954 17.3576 19.4321 17.3861 19.3625C17.4145 19.2929 17.4289 19.2183 17.4285 19.1431C17.4264 18.9918 17.3672 18.8469 17.2628 18.7374L17.1771 18.6688C17.1452 18.6472 17.1106 18.6299 17.0742 18.6174C17.0402 18.6 17.0035 18.5884 16.9656 18.5831C16.8923 18.566 16.8161 18.566 16.7428 18.5831L16.6399 18.6174C16.6035 18.6299 16.5689 18.6472 16.5371 18.6688L16.4514 18.7374C16.3469 18.8469 16.2877 18.9918 16.2856 19.1431C16.2856 19.2947 16.3458 19.44 16.453 19.5472C16.5602 19.6543 16.7055 19.7145 16.8571 19.7145Z"
              fill="black"
            />
            <path
              d="M18.7371 19.5481C18.7903 19.6017 18.8535 19.6442 18.9231 19.6732C18.9927 19.7022 19.0674 19.7172 19.1429 19.7172C19.2183 19.7172 19.293 19.7022 19.3626 19.6732C19.4322 19.6442 19.4954 19.6017 19.5486 19.5481C19.599 19.4926 19.6396 19.4288 19.6686 19.3596C19.6988 19.2912 19.7144 19.2172 19.7144 19.1424C19.7144 19.0676 19.6988 18.9937 19.6686 18.9253C19.6414 18.8551 19.6006 18.791 19.5486 18.7367C19.5205 18.7108 19.4898 18.6879 19.4571 18.6681C19.428 18.6455 19.3951 18.6281 19.36 18.6167C19.326 18.5993 19.2893 18.5877 19.2514 18.5824C19.1596 18.564 19.0647 18.5685 18.975 18.5954C18.8854 18.6223 18.8037 18.6708 18.7371 18.7367C18.6851 18.791 18.6443 18.8551 18.6171 18.9253C18.5869 18.9937 18.5713 19.0676 18.5713 19.1424C18.5713 19.2172 18.5869 19.2912 18.6171 19.3596C18.6461 19.4288 18.6867 19.4926 18.7371 19.5481Z"
              fill="black"
            />
            <path
              d="M21.4285 19.7142C21.5801 19.7142 21.7254 19.654 21.8326 19.5468C21.9397 19.4397 22 19.2943 22 19.1428C21.9978 18.9915 21.9387 18.8466 21.8342 18.7371C21.8061 18.7112 21.7755 18.6882 21.7428 18.6685C21.7136 18.6459 21.6808 18.6285 21.6457 18.6171C21.6116 18.5997 21.5749 18.5881 21.5371 18.5828C21.4276 18.5596 21.3136 18.5716 21.2114 18.6171C21.1405 18.6427 21.0761 18.6837 21.0227 18.737C20.9694 18.7903 20.9285 18.8547 20.9028 18.9256C20.8708 18.9935 20.8552 19.0678 20.8571 19.1428C20.8571 19.2943 20.9173 19.4397 21.0245 19.5468C21.1316 19.654 21.277 19.7142 21.4285 19.7142Z"
              fill="black"
            />
          </g>
          <defs>
            <clipPath id="clip0_735_10726">
              <rect width="40" height="28" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <svg
          width="40"
          height="28"
          viewBox="0 0 40 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_733_10503)">
            <path
              d="M36 0H4C1.79086 0 0 1.79086 0 4V24C0 26.2091 1.79086 28 4 28H36C38.2091 28 40 26.2091 40 24V4C40 1.79086 38.2091 0 36 0Z"
              fill="#D6ECF7"
            />
            <path
              d="M24.8571 6H15.7143C15.2596 6 14.8236 6.18061 14.5021 6.5021C14.1806 6.82359 14 7.25963 14 7.71429V20.2857C14 20.7404 14.1806 21.1764 14.5021 21.4979C14.8236 21.8194 15.2596 22 15.7143 22H24.8571C25.3118 22 25.7478 21.8194 26.0693 21.4979C26.3908 21.1764 26.5714 20.7404 26.5714 20.2857V7.71429C26.5714 7.25963 26.3908 6.82359 26.0693 6.5021C25.7478 6.18061 25.3118 6 24.8571 6ZM15.7143 7.14286H24.8571C25.0087 7.14286 25.154 7.20306 25.2612 7.31022C25.3684 7.41739 25.4286 7.56273 25.4286 7.71429V11.7143H15.1429V7.71429C15.1429 7.56273 15.2031 7.41739 15.3102 7.31022C15.4174 7.20306 15.5627 7.14286 15.7143 7.14286ZM25.4286 20.2857C25.4286 20.4373 25.3684 20.5826 25.2612 20.6898C25.154 20.7969 25.0087 20.8571 24.8571 20.8571H15.7143C15.5627 20.8571 15.4174 20.7969 15.3102 20.6898C15.2031 20.5826 15.1429 20.4373 15.1429 20.2857V12.8571H25.4286V20.2857Z"
              fill="#0084C9"
            />
            <path
              d="M23.7143 19.7143C23.8658 19.7143 24.0111 19.6541 24.1183 19.5469C24.2255 19.4398 24.2857 19.2944 24.2857 19.1429V14.5714C24.2857 14.4199 24.2255 14.2745 24.1183 14.1674C24.0111 14.0602 23.8658 14 23.7143 14C23.5627 14 23.4174 14.0602 23.3102 14.1674C23.203 14.2745 23.1428 14.4199 23.1428 14.5714V19.1429C23.1428 19.2944 23.203 19.4398 23.3102 19.5469C23.4174 19.6541 23.5627 19.7143 23.7143 19.7143Z"
              fill="#0084C9"
            />
            <path
              d="M16.8571 15.1428C16.9317 15.1419 17.0055 15.1263 17.0742 15.097C17.1435 15.0681 17.2072 15.0275 17.2628 14.977C17.3157 14.9237 17.3576 14.8603 17.3861 14.7907C17.4145 14.7211 17.4289 14.6465 17.4285 14.5713C17.4264 14.42 17.3672 14.2751 17.2628 14.1656L17.1771 14.097C17.1452 14.0754 17.1106 14.0581 17.0742 14.0456C17.0402 14.0282 17.0035 14.0166 16.9656 14.0113C16.8923 13.9943 16.8161 13.9943 16.7428 14.0113L16.6399 14.0456C16.6035 14.0581 16.5689 14.0754 16.5371 14.097L16.4514 14.1656C16.3469 14.2751 16.2877 14.42 16.2856 14.5713C16.2856 14.7229 16.3458 14.8682 16.453 14.9754C16.5602 15.0826 16.7055 15.1428 16.8571 15.1428Z"
              fill="#0084C9"
            />
            <path
              d="M18.7371 14.977C18.7903 15.0306 18.8535 15.0731 18.9231 15.1021C18.9927 15.1311 19.0674 15.1461 19.1429 15.1461C19.2183 15.1461 19.293 15.1311 19.3626 15.1021C19.4322 15.0731 19.4954 15.0306 19.5486 14.977C19.599 14.9215 19.6396 14.8577 19.6686 14.7885C19.6978 14.7198 19.7134 14.646 19.7143 14.5713C19.7122 14.42 19.653 14.2751 19.5486 14.1656C19.5205 14.1398 19.4898 14.1168 19.4571 14.097C19.428 14.0744 19.3951 14.057 19.36 14.0456C19.326 14.0282 19.2893 14.0166 19.2514 14.0113C19.1781 13.9943 19.1019 13.9943 19.0286 14.0113L18.9257 14.0456C18.8893 14.0581 18.8547 14.0754 18.8229 14.097C18.7929 14.1181 18.7643 14.141 18.7371 14.1656C18.6842 14.2193 18.6433 14.2835 18.6171 14.3542C18.5869 14.4226 18.5713 14.4966 18.5713 14.5713C18.5713 14.6461 18.5869 14.7201 18.6171 14.7885C18.6461 14.8577 18.6867 14.9215 18.7371 14.977Z"
              fill="#0084C9"
            />
            <path
              d="M21.4286 15.1429C21.5802 15.1429 21.7255 15.0827 21.8327 14.9756C21.9398 14.8684 22 14.723 22 14.5715C21.9979 14.4202 21.9387 14.2753 21.8343 14.1658C21.8062 14.1399 21.7756 14.117 21.7429 14.0972C21.7137 14.0746 21.6809 14.0572 21.6457 14.0458C21.6117 14.0284 21.575 14.0168 21.5372 14.0115C21.4277 13.9883 21.3137 14.0003 21.2115 14.0458C21.1413 14.073 21.0772 14.1138 21.0229 14.1658C20.9185 14.2753 20.8593 14.4202 20.8572 14.5715C20.8572 14.723 20.9174 14.8684 21.0245 14.9756C21.1317 15.0827 21.2771 15.1429 21.4286 15.1429Z"
              fill="#0084C9"
            />
            <path
              d="M16.8571 17.4284C16.9317 17.4275 17.0055 17.412 17.0742 17.3827C17.1435 17.3538 17.2072 17.3132 17.2628 17.2627C17.3157 17.2093 17.3576 17.146 17.3861 17.0764C17.4145 17.0067 17.4289 16.9322 17.4285 16.857C17.4264 16.7057 17.3672 16.5608 17.2628 16.4513C17.2356 16.4267 17.207 16.4038 17.1771 16.3827C17.1452 16.3611 17.1106 16.3438 17.0742 16.3313C17.0402 16.3139 17.0035 16.3023 16.9656 16.297C16.8923 16.2799 16.8161 16.2799 16.7428 16.297L16.6399 16.3313C16.6035 16.3438 16.5689 16.3611 16.5371 16.3827C16.5071 16.4038 16.4785 16.4267 16.4514 16.4513C16.3469 16.5608 16.2877 16.7057 16.2856 16.857C16.2856 17.0085 16.3458 17.1539 16.453 17.261C16.5602 17.3682 16.7055 17.4284 16.8571 17.4284Z"
              fill="#0084C9"
            />
            <path
              d="M18.7371 17.263C18.7903 17.3165 18.8535 17.359 18.9231 17.3881C18.9927 17.4171 19.0674 17.432 19.1429 17.432C19.2183 17.432 19.293 17.4171 19.3626 17.3881C19.4322 17.359 19.4954 17.3165 19.5486 17.263C19.599 17.2074 19.6396 17.1436 19.6686 17.0744C19.6988 17.006 19.7144 16.932 19.7144 16.8573C19.7144 16.7825 19.6988 16.7085 19.6686 16.6401C19.6414 16.57 19.6006 16.5059 19.5486 16.4515C19.5205 16.4257 19.4898 16.4027 19.4571 16.383C19.428 16.3604 19.3951 16.343 19.36 16.3315C19.326 16.3141 19.2893 16.3026 19.2514 16.2973C19.1596 16.2789 19.0647 16.2833 18.975 16.3102C18.8854 16.3371 18.8037 16.3857 18.7371 16.4515C18.6851 16.5059 18.6443 16.57 18.6171 16.6401C18.5869 16.7085 18.5713 16.7825 18.5713 16.8573C18.5713 16.932 18.5869 17.006 18.6171 17.0744C18.6461 17.1436 18.6867 17.2074 18.7371 17.263Z"
              fill="#0084C9"
            />
            <path
              d="M21.0228 17.2629C21.1027 17.3434 21.2048 17.3984 21.3161 17.4207C21.4274 17.4431 21.5428 17.4317 21.6477 17.3882C21.7525 17.3447 21.842 17.2709 21.9047 17.1763C21.9675 17.0818 22.0006 16.9706 22 16.8571C21.9978 16.7058 21.9387 16.5609 21.8342 16.4514C21.8061 16.4256 21.7755 16.4026 21.7428 16.3829C21.7136 16.3602 21.6808 16.3428 21.6457 16.3314C21.6116 16.314 21.5749 16.3024 21.5371 16.2971C21.4276 16.274 21.3136 16.286 21.2114 16.3314C21.1405 16.3571 21.0761 16.398 21.0227 16.4514C20.9694 16.5047 20.9285 16.5691 20.9028 16.64C20.8708 16.7078 20.8552 16.7822 20.8571 16.8571C20.8562 16.9314 20.8698 17.0052 20.8971 17.0743C20.9302 17.1426 20.9725 17.206 21.0228 17.2629Z"
              fill="#0084C9"
            />
            <path
              d="M16.8571 19.7141C16.9317 19.7131 17.0055 19.6976 17.0742 19.6683C17.1435 19.6394 17.2072 19.5988 17.2628 19.5483C17.3157 19.4949 17.3576 19.4316 17.3861 19.362C17.4145 19.2924 17.4289 19.2178 17.4285 19.1426C17.4264 18.9913 17.3672 18.8464 17.2628 18.7369L17.1771 18.6683C17.1452 18.6467 17.1106 18.6294 17.0742 18.6169C17.0402 18.5995 17.0035 18.5879 16.9656 18.5826C16.8923 18.5656 16.8161 18.5656 16.7428 18.5826L16.6399 18.6169C16.6035 18.6294 16.5689 18.6467 16.5371 18.6683L16.4514 18.7369C16.3469 18.8464 16.2877 18.9913 16.2856 19.1426C16.2856 19.2942 16.3458 19.4395 16.453 19.5467C16.5602 19.6538 16.7055 19.7141 16.8571 19.7141Z"
              fill="#0084C9"
            />
            <path
              d="M18.7371 19.5486C18.7903 19.6022 18.8535 19.6447 18.9231 19.6737C18.9927 19.7027 19.0674 19.7176 19.1429 19.7176C19.2183 19.7176 19.293 19.7027 19.3626 19.6737C19.4322 19.6447 19.4954 19.6022 19.5486 19.5486C19.599 19.4931 19.6396 19.4293 19.6686 19.36C19.6988 19.2916 19.7144 19.2177 19.7144 19.1429C19.7144 19.0681 19.6988 18.9942 19.6686 18.9258C19.6414 18.8556 19.6006 18.7915 19.5486 18.7372C19.5205 18.7113 19.4898 18.6884 19.4571 18.6686C19.428 18.646 19.3951 18.6286 19.36 18.6172C19.326 18.5998 19.2893 18.5882 19.2514 18.5829C19.1596 18.5645 19.0647 18.569 18.975 18.5959C18.8854 18.6228 18.8037 18.6713 18.7371 18.7372C18.6851 18.7915 18.6443 18.8556 18.6171 18.9258C18.5869 18.9942 18.5713 19.0681 18.5713 19.1429C18.5713 19.2177 18.5869 19.2916 18.6171 19.36C18.6461 19.4293 18.6867 19.4931 18.7371 19.5486Z"
              fill="#0084C9"
            />
            <path
              d="M21.4285 19.7142C21.5801 19.7142 21.7254 19.654 21.8326 19.5468C21.9397 19.4397 22 19.2943 22 19.1428C21.9978 18.9915 21.9387 18.8466 21.8342 18.7371C21.8061 18.7112 21.7755 18.6882 21.7428 18.6685C21.7136 18.6459 21.6808 18.6285 21.6457 18.6171C21.6116 18.5997 21.5749 18.5881 21.5371 18.5828C21.4276 18.5596 21.3136 18.5716 21.2114 18.6171C21.1405 18.6427 21.0761 18.6837 21.0227 18.737C20.9694 18.7903 20.9285 18.8547 20.9028 18.9256C20.8708 18.9935 20.8552 19.0678 20.8571 19.1428C20.8571 19.2943 20.9173 19.4397 21.0245 19.5468C21.1316 19.654 21.277 19.7142 21.4285 19.7142Z"
              fill="#0084C9"
            />
          </g>
          <defs>
            <clipPath id="clip0_733_10503">
              <rect width="40" height="28" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )}
    </>
  );
};

export default CalculatorIcon;
