import { backendAPIAxios as axios } from "utils/initAxios";

export const getOTCMemberships = () => axios.get("/otc/memberships");
export const getOTCConstants = () => axios.get("/otc/constants");
export const createOTCOrder = (payload) =>
  axios.post("/otc/create_order_by_crypto", payload);
export const getOTCOrders = (payload) => axios.post("/otc/orders", payload);
export const getOTCOrderID = (userId) =>
  axios.post("/otc/generate_order_id", { userId });
export const claimOrder = (payload) => axios.post("/otc/claim", payload);
