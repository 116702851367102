import React, { forwardRef, useContext } from "react";
import useTranslationWithStorage from "hooks/useTranslationWithStorage";
import { ThemeContext } from "ThemeContext";

const Switcher = forwardRef(() => {
  const { theme, changeTheme } = useContext(ThemeContext);
  const { t } = useTranslationWithStorage();

  return (
    <>
      <div className="flex flex-col items-center justify-center w-full gap-2 md:flex-row">
        <div
          className={`w-1/2 flex flex-row justify-between p-4 border ${
            theme === "dark" ? "border-[#6E7A8A]" : "border-[#E2E6E7]"
          } hover:border-[#6E7A8A] ${
            theme === "dark" ? "bg-[#000000]" : "bg-[#263238]"
          } rounded-md cursor-pointer`}
        >
          <label
            className="text-[#B0B5BC] text-[12px] w-full cursor-pointer"
            htmlFor="dark_mode"
          >
            {t("Dark Mode")}
          </label>
          <input
            name="display_mode"
            type="radio"
            id="dark_mode"
            className="outline-none text-[#0084C9]"
            value="dark"
            defaultChecked={theme === "dark"}
            onChange={(e) => {
              changeTheme("dark");
            }}
          />
        </div>
        <div
          className={`w-1/2 flex flex-row justify-between p-4 border ${
            theme === "dark" ? "border-[#6E7A8A]" : "border-[#E2E6E7]"
          } hover:border-[#6E7A8A] ${
            theme === "dark" ? "bg-[#263238]" : "bg-white"
          } rounded-md cursor-pointer`}
        >
          <label
            className="text-[#B0B5BC] text-[12px] w-full cursor-pointer"
            htmlFor="light_mode"
          >
            {t("Light Mode")}
          </label>
          <input
            name="display_mode"
            type="radio"
            id="light_mode"
            defaultChecked={theme === "light"}
            className="outline-none text-[#0084C9]"
            onChange={(e) => {
              changeTheme("light");
            }}
            value="light"
          />
        </div>
      </div>
    </>
  );
});

export default Switcher;
