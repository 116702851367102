import useToast from 'hooks/useToast';
import { useState } from 'react';
import PhoneNumberInput from 'components/Input/PhoneNumberInput';
import axios from 'axios';
import CheckingCode from './CheckingCode';

function TwoFactorAuth({ setSelectedPage, to }) {
  const { showToast } = useToast();
  const [formData, setFormData] = useState({
    number: ''
  });
  const [checking, setchecking] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/2fa/sending`, {
          phoneNumber: formData.number
        })
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            showToast(res.data, 'success');
            setchecking(true);
          }
        });

      // setSelectedPage("pwd");
    } catch (error) {
      // Handle signup error
      console.log('Sending SMS error:', error);
      showToast(error?.response?.data?.message, 'error');
    }
  };

  return (
    <div className={` flex flex-1 h-fit bg-[#263238]`}>
      <div
        className="flex flex-col w-full h-screen gap-10 pt-10 overflow-y-auto md:gap-16 sm:pt-24 lg:pt-25"
        style={{
          backgroundImage: `url("https://staticdash.dev.nexuspro.io/assets/images/Mask group (1).png")`,
          // backgroundImage: `url("${process.env.REACT_APP_PUBLIC_URL}/assets/images/Mask group (1).png")`,
          backgroundPosition: 'bottom',
          backgroundSize: 'cover'
        }}
      >
        <span className="flex items-center justify-center h-10 mt-0">
          <img
            src={
              process.env.REACT_APP_PUBLIC_URL +
              '/assets/images/Nexus Pro_Logo.png'
            }
            alt="wing"
            className="md:h-[80px] h-[50px]"
          />
        </span>
        {checking ? (
          <CheckingCode
            number={formData.number}
            setSelectedPage={setSelectedPage}
            to={to}
          />
        ) : (
          <div className="fade-in-load bg-[#ffffff] rounded-2xl transition-all shadow-[1px_1px_1px_1px_rgba(0,0,0,0.1)] flex flex-col py-10 px-10 w-[80%] 2xl:w-1/3 mx-auto justify-center items-center max-w-[600px] gap-7">
            <div className="w-full text-base text-start ">
              <span
                className="font-semibold text-[#128FC8] cursor-pointer hover:opacity-70"
                onClick={() => {
                  setSelectedPage('signup');
                }}
              >
                ← Back to Sign Up
              </span>
            </div>
            <span className="text-4xl font-extrabold text-[#5F5F5F]">
              Two Factor Authentication
            </span>

            <form
              onSubmit={handleSubmit}
              className="flex flex-col w-full gap-10"
            >
              <PhoneNumberInput
                label="Phone Number"
                onChangeHandle={(v) => {
                  setFormData({ number: v });
                }}
              />
              <div className="flex flex-col items-center justify-center w-full gap-5">
                <button
                  type="submit"
                  className={`inline-block font-bold rounded-lg py-4 text-lg leading-normal transition duration-150 ease-in-out border-1 bg-[#0084C9] text-[#FFFFFF]  w-full`}
                >
                  Send Code to Your Phone
                </button>
              </div>
            </form>
          </div>
        )}

        <span className="flex flex-col w-full text-xl font-bold text-center text-white">
          <span>Copyright © 2013 - 2023 NexusPro Operations Limited.</span>
          <span>All rights reserved.</span>
        </span>
      </div>
    </div>
  );
}

export default TwoFactorAuth;
