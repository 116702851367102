import { ThemeContext } from "ThemeContext";
import SimpleTable from "components/SimpleTable";
import { useContext, useMemo } from "react";
import { SpinningCircles } from "react-loading-icons";
import { formattedSpecifiedDate } from "utils/dateFormat";
import shortTransactionHash from "utils/shortTransactionHash";

const DepositTable = ({ loading = false, data = [] }) => {
  const { theme } = useContext(ThemeContext);
  const columns = useMemo(
    () => [
      {
        Header: "Crypto",
        accessor: "symbol",
        Cell: ({ row }) => {
          return (
            <div className="flex flex-row items-center text-center align-middle ml-2 py-2">
              {row.original.icon && (
                <div>
                  <img
                    src={row.original.icon}
                    alt="icon"
                    className="w-7 h-7"
                  ></img>
                </div>
              )}
              <div className="flex md:flex-row flex-col items-start justify-between gap-1 pl-1">
                <span className="ml-1">{row.original.symbol}</span>
              </div>
            </div>
          );
        },
      },
      {
        Header: "Time",
        accessor: "process_date",
        Cell: ({ row }) => {
          return (
            <div className="text-sm font-normal ml-2 py-2">
              {formattedSpecifiedDate(row?.original?.process_date)}
            </div>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row }) => {
          return (
            <div className="text-sm font-normal ml-2 py-2">
              {row?.original?.status}
            </div>
          );
        },
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ row }) => {
          return (
            <div className="text-sm font-normal ml-2 py-2">
              {row?.original?.amount}
            </div>
          );
        },
      },
      {
        Header: "TxID",
        accessor: "transaction_hash",
        Cell: ({ row }) => {
          return (
            <div className="text-sm font-normal ml-2 py-2">
              {shortTransactionHash(row?.original?.transaction_hash)}
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <div
      className={`w-full h-[95%] inline-block dark font-normal rounded-2xl text-xs uppercase leading-normal transition duration-150 ease-in-out dark:text-gray text-[#128FC8] `}
    >
      <div
        className={`flex flex-col w-full rounded-2xl h-[100%] overflow-y-auto ${
          theme === "dark" ? "bg-[#000000]" : "bg-[#FFFFFF]"
        }`}
      >
        {loading ? (
          <div className="flex items-center justify-center w-full h-screen -translate-y-1/4">
            <SpinningCircles stroke="#007bff" speed={1.5} fill="#007bff" />
          </div>
        ) : (
          <SimpleTable title={``} columns={columns} data={data} />
        )}
      </div>
    </div>
  );
};

export default DepositTable;
