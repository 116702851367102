const UnCheckedIcon = ({ isDarkMode }) => {
  return (
    <>
      {isDarkMode ? (
        <svg
          width="34"
          height="34"
          viewBox="0 0 34 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.5"
            y="0.5"
            width="33"
            height="33"
            rx="5.5"
            stroke="#6E7A8A"
          />
        </svg>
      ) : (
        <svg
          width="34"
          height="34"
          viewBox="0 0 34 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.5"
            y="0.5"
            width="33"
            height="33"
            rx="5.5"
            stroke="#6E7A8A"
          />
        </svg>
      )}
    </>
  );
};

export default UnCheckedIcon;
