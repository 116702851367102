
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "ThemeContext";

const SidebarItem = ({
  icon,
  label,
  show,
  onClickHandle,
  isSelected,
  hasSeperatorLine = false,
}) => {
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();
  return (
    <>
      {show || show === undefined ? (
        <div>
          <div
            className={`p-3 rounded-md mb-3 ${
              theme === "dark"
                ? "bg-[#202630] hover:bg-[#263238] "
                : "hover:bg-[#cce4f0]"
            } cursor-pointer transition-all font-normal  ${
              isSelected
                ? theme === "dark"
                  ? "bg-black text-[#FFFFFF] font-semibold "
                  : "text-black bg-[#a3d7f1]"
                : theme === "dark"
                ? "border-[#5F5F5F] text-[#D9D9D9] "
                : "border-[#ffffff] text-gray-800 hover:text-white"
            }`}
            onClick={onClickHandle}
          >
            <div className={`flex items-center transition-all`}>
              {icon}
              <span className="ml-4 font-inter text-[14px] leading-5 ">
                {t(label)}
              </span>
            </div>
          </div>
          {hasSeperatorLine ? (
            <div className="border-t border-[#5F5F5F] my-6"></div>
          ) : null}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default SidebarItem;
