import AmountInput from "./AmountInput";
import Seperator from "./Seperator";
import TextInput from "./TextInput";
import CheckComponent from "./CheckComponent";
import { useTranslation } from "react-i18next";
import SelectInput from "./SelectInput";
import PrimarySelectBox from "components/common/PrimarySelectBox";
import { useContext } from "react";
import { ThemeContext } from "ThemeContext";

const optionRenderFn = (option) => {
  return (
    <div className="flex items-center">
      <img src={option.icon} alt="coin_icon" className="w-5 h-5 mr-2" />
      <span>{option.name}</span>
    </div>
  );
};

export default function InputPanel({
  onEUUSChange = (f) => f,
  onUSEUChange = (f) => f,
  onEUUSPriceChange = (f) => f,
  onUSEUPriceChange = (f) => f,
  onDiscountChange = (f) => f,
  // onNexusWalletChange = (f) => f,
  // onOtherWalletChange = (f) => f,
  // onAddressChange = (f) => f,
  onCoinSelectionChange = (f) => f,
  onMembershipChange = (f) => f,
  totalAmount = "",
  memberships = [],
  minOrderAmountOTC = 0,
  coins = [],
  selectedCoin = null,
  isLoading = false,
  balanceStr = "",
  ...props
}) {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);

  return (
    <div {...props} className={`${props.className}`}>
      <div className="text-center">
        <p className={`text-[20px] ${theme === "dark" ? "text-white" : ""}`}>
          Select the asset/s and the amount
        </p>
        <p
          className={`text-[12px] mb-6 ${
            theme === "dark" ? "text-[#B0B5BC]" : ""
          }`}
        >
          {`Minimum order is $${minOrderAmountOTC} USD value or more`}
        </p>
      </div>
      <div
        className={`grid grid-cols-2 gap-12 rounded-2xl py-4 px-12 ${
          theme === "dark" ? "bg-[#181F23]" : "bg-[#FFFFFF]"
        }`}
      >
        <AmountInput
          onCheckChange={onUSEUChange}
          type="USEU"
          onPriceChange={onUSEUPriceChange}
        />
        <AmountInput
          onCheckChange={onEUUSChange}
          type="EUUS"
          onPriceChange={onEUUSPriceChange}
        />
      </div>
      <Seperator />
      <div className="text-left">
        <PrimarySelectBox
          label={t("Payment Via")}
          options={coins}
          render={optionRenderFn}
          onChange={onCoinSelectionChange}
          className="mb-4"
          defaultSelect={selectedCoin}
          isLoading={isLoading}
        />

        <TextInput
          label="Amount"
          className="mb-6"
          valueIfReadOnly={totalAmount}
          balanceStr={balanceStr}
          readOnly
        />
        <CheckComponent
          label="Discount"
          onChange={onDiscountChange}
          className="mb-2"
        />
        <SelectInput
          options={memberships}
          onChange={onMembershipChange}
          className="mb-8"
        />
        {/* <div className="flex justify-between">
          <CheckComponent
            label="Nexus Pro Client Wallet"
            onChange={onNexusWalletChange}
            className="mb-2"
          />
          <CheckComponent
            label="Other Wallet Address (ERC-20)"
            onChange={onOtherWalletChange}
            className="mb-2"
          />
        </div>
        <TextInput placeholder="Add Address Here" onChange={onAddressChange} /> */}
      </div>
    </div>
  );
}
