import React, { useContext } from "react";
import { useAppData } from "providers/app";
import Card from "components/Card";
import { constants } from "../../constants";
import { ThemeContext } from "ThemeContext";

const DashboardContent = () => {
  const { balances, infoEUUS, infoUSEU } = useAppData();
  const { theme } = useContext(ThemeContext);

  const balancesFilter = balances.filter(
    (balance) => balance.showOnBuy === true
  );

  return (
    <div
      className={`overflow-auto ${
        theme === "dark" ? "bg-[#263238]" : "bg-[#EBF5FB]"
      } p-2 sm:p-8 w-full`}
    >
      <div className="flex flex-wrap align-middle items-center justify-center w-full gap-5">
        {balancesFilter.map((item, index) => (
          <React.Fragment key={index}>
            <Card
              key={"EU / US" + index}
              title="EU / US"
              nexusTokenSymbol={constants.EUUS_SYMBOL}
              from={item.symbol}
              to={constants.EUR_SYMBOL}
              symbol={item.symbol}
              rate={item.price}
              compareRate={infoEUUS.price}
              // loadingRate={EUUSInitialData?.isLoading}
              imageOrigin={
                <img
                  loading="lazy"
                  src={theme === "dark" ? item.icon : item.icon}
                  alt="wing"
                  className="h-[70px] w-[70px]"
                />
              }
              imageCompare={
                <img
                  src={theme === "dark" ? infoEUUS.icon : infoEUUS.icon}
                  alt="wing"
                  className="h-[70px] w-[70px]"
                />
              }
            />
            <Card
              key={"US / EU" + index}
              title="US / EU"
              nexusTokenSymbol={constants.USEU_SYMBOL}
              from={item.symbol}
              rate={item.price}
              compareRate={infoUSEU.price}
              to={constants.USD_SYMBOL}
              symbol={item.symbol}
              // loadingRate={useuInitialData?.isLoading}
              imageOrigin={
                <img
                  loading="lazy"
                  src={theme === "dark" ? item.icon : item.icon}
                  alt="wing"
                  className="h-[70px] w-[70px]"
                />
              }
              imageCompare={
                <img
                  src={theme === "dark" ? infoUSEU.icon : infoUSEU.icon}
                  alt="wing"
                  className="h-[70px] w-[70px]"
                />
              }
            />
          </React.Fragment>
        ))}
        {/* <PriceChart
          title="US / EU"
          from="USD"
          rate={priceOfUSEU}
          to="EUR"
          // loadingRate={useuInitialData?.isLoading}
          image_dark={
            <img
              loading="lazy"
              src={"https://www.datocms-assets.com/51952/1638999972-wbtc.png"}
              alt="wing"
              className="h-[70px] w-[70px]"
            />
          }
          image_light={
            <img
              src={
                "https://staticdash.dev.nexuspro.io" +
                "/assets/images/Nexus Pro_Icons_08.png"
              }
              alt="wing"
              className="h-[70px] w-[70px]"
            />
          }
        />
        <PriceChart
          title="EU / US"
          from="EUR"
          to="USD"
          rate={priceOfEUUS}
          // loadingRate={useuInitialData?.isLoading}
          image_dark={
            <img
              loading="lazy"
              src={"https://www.datocms-assets.com/51952/1638999972-wbtc.png"}
              alt="wing"
              className="h-[70px] w-[70px]"
            />
          }
          image_light={
            <img
              src={
                "https://staticdash.dev.nexuspro.io" +
                "/assets/images/Nexus Pro_Icons_07.png"
              }
              alt="wing"
              className="h-[70px] w-[70px]"
            />
          }
        />
        <PriceChart
          title="EU / US"
          from="EUR"
          to="USD"
          rate={priceOfEUUS}
          // loadingRate={useuInitialData?.isLoading}
          image_dark={
            <img
              loading="lazy"
              src={
                "https://static.dev.nexuspro.io" +
                "/assets/images/Nexus Pro_Icons_03-min.png"
              }
              alt="wing"
              className="h-[70px] w-[70px]"
            />
          }
          image_light={
            <img
              src={
                "https://staticdash.dev.nexuspro.io" +
                "/assets/images/Nexus Pro_Icons_07.png"
              }
              alt="wing"
              className="h-[70px] w-[70px]"
            />
          }
        />
        <PriceChart
          title="EU / US"
          from="EUR"
          to="USD"
          rate={priceOfEUUS}
          // loadingRate={useuInitialData?.isLoading}
          image_dark={
            <img
              loading="lazy"
              src={
                "https://static.dev.nexuspro.io" +
                "/assets/images/Nexus Pro_Icons_03-min.png"
              }
              alt="wing"
              className="h-[70px] w-[70px]"
            />
          }
          image_light={
            <img
              src={
                "https://staticdash.dev.nexuspro.io" +
                "/assets/images/Nexus Pro_Icons_07.png"
              }
              alt="wing"
              className="h-[70px] w-[70px]"
            />
          }
        /> */}
      </div>
    </div>
  );
};

export default DashboardContent;
