import { useEffect, useState, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useUserData } from "UserDataContext";
import useToast from "hooks/useToast";
import { useAppData } from "providers/app";
import * as TransactionAPI from "api/transaction";
import { fixDigitFilter, fixFloorFilter } from "utils/fixDigitFilter";
import isEmpty from "utils/isEmpty";
import * as AppStatesApi from "api/app";
import Checkout from "components/Checkout";
import InputNumber from "components/Input/Input_Number";
import ButtonDark from "components/Input/Button_dark";
import MetaMaskIcon from "components/Svg/Icons/MetaMask";
import Wallet from "components/Svg/Icons/Wallet";
import PrimarySelectBox from "components/common/PrimarySelectBox";
import { DialogCustom } from ".";
import TransactionInfo from "./TransactionInfo";
import { constants } from "../../constants";
import { ThemeContext } from "ThemeContext";

const optionRenderFn = (option) => {
  return (
    <div className="flex items-center">
      <img src={option.icon} alt="coin_icon" className="w-5 h-5 mr-2" />
      <span>{option.name}</span>
    </div>
  );
};

const BuyTokenDialog = ({
  isOpen,
  onClose,
  nexusTokenSymbol,
  symbol,
  value,
  orginValue,
}) => {
  const [buyingWithToken, setBuyingWithToken] = useState(false);
  const [possibleToBuyWithToken, setPossibleToBuyToken] = useState(false);
  const {
    coins,
    constantsAll,
    infoEUUS,
    infoUSEU,
    defaultSelectedCoin,
    setBalanceLoaded,
    setBalances,
  } = useAppData();
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const feePercent = constantsAll.fee_trade;

  const [inputValueFiat, setinputValueFiat] = useState(Number(value));
  const [inputValueCrypto, setinputValueCrypto] = useState(Number(value));
  const stripeButton = useRef(null);
  const [selectedCoin, setSelectedCoin] = useState(
    coins.filter((b) => b.symbol === symbol)[0]
  );
  const [estimatedOutInFiat, setEstimatedOutInFiat] = useState(orginValue);
  const [estimatedOutInCrypto, setEstimatedOutInCrypto] = useState(orginValue);
  const { userData } = useUserData();
  const { showToast } = useToast();

  const feeValue = fixFloorFilter(
    Number((feePercent * Number(inputValueCrypto)) / 100),
    6
  );
  useEffect(() => {
    if (isEmpty(selectedCoin)) {
      setSelectedCoin(defaultSelectedCoin);
    }
  }, [selectedCoin, value]);

  useEffect(() => {
    let possiblility =
      selectedCoin.balance >= Number(inputValueCrypto) + Number(feeValue);
    setPossibleToBuyToken(possiblility);
  }, [inputValueCrypto, feeValue]);

  useEffect(() => {
    setinputValueFiat(value);
    if (selectedCoin) {
      setinputValueCrypto(
        selectedCoin?.price === 0
          ? 0
          : fixDigitFilter(value / selectedCoin?.price, 8)
      );
    }
  }, [value, selectedCoin]);

  useEffect(() => {
    let priceOfToken = 0;
    priceOfToken =
      nexusTokenSymbol === constants.EUUS_SYMBOL
        ? infoEUUS.price
        : infoUSEU.price;
    setEstimatedOutInFiat(fixDigitFilter(inputValueFiat / priceOfToken));
  }, [inputValueFiat, infoEUUS.price, infoUSEU.price, nexusTokenSymbol]);

  useEffect(() => {
    if (selectedCoin) {
      let priceOfToken = 0;
      priceOfToken =
        nexusTokenSymbol === constants.EUUS_SYMBOL
          ? infoEUUS.price
          : infoUSEU.price;
      setEstimatedOutInCrypto(
        fixDigitFilter((inputValueCrypto * selectedCoin?.price) / priceOfToken)
      );
    }
  }, [
    infoEUUS.price,
    infoUSEU.price,
    inputValueCrypto,
    selectedCoin,
    nexusTokenSymbol,
  ]);

  const reloadBalance = () => {
    setBalanceLoaded(false);
    AppStatesApi.getBalances(userData).then((res) => {
      const balances = res?.data?.data.sort((a, b) => {
        if (a.viewOrder < b.viewOrder) {
          return -1;
        } else if (a.viewOrder > b.viewOrder) {
          return 1;
        } else {
          return 0;
        }
      });
      setBalances(balances);
      setBalanceLoaded(true);
    });
  };

  const onBuyWithCrypto = () => {
    const payload = {
      userId: userData.id,
      in_token: {
        symbol: selectedCoin.symbol,
      },
      in_amount: Number(inputValueCrypto),
      out_token: nexusTokenSymbol,
    };
    setBuyingWithToken(true);
    TransactionAPI.buyWithCrypto(payload)
      .then(() => {
        showToast("Success to buy Nexus token", "success");
        onClose();
        reloadBalance();
      })
      .catch((err) => {
        showToast(`Error: ${err.response.data.message}`, "error");
        onClose();
      })
      .finally(() => {
        setBuyingWithToken(false);
      });
  };

  const onCoinSelectionChange = (coin) => {
    setSelectedCoin(coins.filter((c) => c.symbol === coin.symbol)[0]);
  };

  const onInputChange = (value) => {
    setinputValueCrypto(value);
  };

  const Alert = (
    <p className="text-center text-red-400">
      Your balance should be more than{" "}
      {fixFloorFilter(parseFloat(inputValueCrypto) + parseFloat(feeValue), 6)}{" "}
      {selectedCoin.symbol}
    </p>
  );
  
  return (
    <DialogCustom
      header={
        <div className="text-center w-full mt-6">
          Buy <span className="text-[#0084C9]">{nexusTokenSymbol}</span> with
          Fiat Money or Crypto
        </div>
      } // Translate the header
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      body={
        <div
          className={`grid sm:grid-cols-1 md:grid-cols-2 md:divide-x ${
            theme === "dark" ? "text-[#ffffff]" : "text-[#333232]"
          } md:divide-[#6E7A8A] gap-6 p-6`}
        >
          <div>
            <div className="grid justify-items-center mb-3">
              <Wallet />
            </div>
            <InputNumber
              value={inputValueFiat}
              setValue={setinputValueFiat}
              label={t(`Set Amount to buy for ${nexusTokenSymbol}`)} // Translate the label
              prefix="$"
              className="mb-4"
            />
            <Checkout
              description={t(`Buy ${nexusTokenSymbol}!`)} // Translate the description
              amount={inputValueFiat}
              crypto_amount={estimatedOutInFiat}
              wallet_address={constants.FIAT_TREASURY_WALLET}
              stripeRef={stripeButton}
              className="mb-6"
            >
              <div className="flex items-center text-white justify-center w-full font-bold">
                <span>You will get </span>
                <span className="font-bold mx-[5px] text-[#00C880]">
                  {estimatedOutInFiat}
                </span>{" "}
                <span>{nexusTokenSymbol}</span>
              </div>
            </Checkout>
            <ButtonDark
              label={t("Buy with Fiat")}
              onClickHandle={() => {
                stripeButton.current.click();
              }}
            />
          </div>

          <div className="pl-0 sm:pl-6">
            <div className="grid justify-items-center mt-2 mb-7">
              <MetaMaskIcon />
            </div>
            <PrimarySelectBox
              label="Select Coin"
              options={coins}
              render={optionRenderFn}
              onChange={onCoinSelectionChange}
              className="mb-4"
              defaultSelect={selectedCoin}
            />
            <InputNumber
              value={inputValueCrypto}
              setValue={onInputChange}
              label={t(`Set Amount to buy for ${nexusTokenSymbol}`)} // Translate the label
              className="mb-4"
            />
            <div className="text-center mb-6 font-bold">
              <span>You will get </span>
              <span className="mx-[5px] text-[#00C880]">
                {estimatedOutInCrypto}
              </span>{" "}
              <span>{nexusTokenSymbol}</span>
            </div>
            <ButtonDark
              className="mb-2"
              label={t("Buy with Crypto")}
              onClickHandle={onBuyWithCrypto}
              loading={buyingWithToken}
              disabled={!possibleToBuyWithToken}
            />
            {!possibleToBuyWithToken ? Alert : null}
            <TransactionInfo
              selectedCoinBalance={selectedCoin.balance}
              selectedCoinSymbol={selectedCoin.symbol}
              feePercent={feePercent}
              feeValue={feeValue}
            />
          </div>
        </div>
      }
    />
  );
};

export default BuyTokenDialog;
