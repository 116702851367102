import { useContext } from "react";
import { SpinningCircles } from "react-loading-icons";
import { ThemeContext } from "ThemeContext";

const ButtonWhite = ({
  dark,
  size,
  label,
  color,
  onClickHandle = (f) => f,
  postingFlag = false,
}) => {
  const { theme } = useContext(ThemeContext);
  const disabled = postingFlag;
  return (
    <button
      type="button"
      className={`inline-block font-normal rounded-lg px-5 py-2 text-[14px] leading-normal transition duration-150 ease-in-out 
      ${disabled ? "bg-[#263238]" : theme === "dark" ? "bg-[#0084C9] " : "  bg-[#b3d7eb] "}
      text-[#EBF5FB]`}
      onClick={onClickHandle}
      disabled={disabled}
    >
      <div className="flex items-center justify-center">
        {postingFlag ? (
          <SpinningCircles
            stroke="#ffffff"
            speed={1.5}
            fill="#ffffff"
            width={20}
            height={20}
          />
        ) : null}
        <span
          className={`font-inter ${disabled ? "text-[#b0b5bc]" : "text-black"}`}
        >
          {label}
        </span>
      </div>
    </button>
  );
};

export default ButtonWhite;
