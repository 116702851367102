export const constants = {
  intervalRequestTokenPrices: 30000,
  intervalRequestEstimatedGas: 20000,
  PROJECT_ID: "4f540cadece68427ad9a3aadd2158678",
  FIAT_TREASURY_WALLET: "0xab3323641374019A6620867684D8C328af9a87d4",
  BTC_SYMBOL: "BTC",
  ETH_SYMBOL: "ETH",
  USDT_SYMBOL: "USDT",
  WBTC_SYMBOL: "WBTC",
  WETH_SYMBOL: "WETH",
  USDC_SYMBOL: "USDC",
  EUR_SYMBOL: "EUR",
  USD_SYMBOL: "USD",
  EUUS_SYMBOL: "EUUS",
  USEU_SYMBOL: "USEU",
  PRE_ALPHAVANTAGE_URL: "https://www.alphavantage.co/query?",
  ALPHAVANTAGE_CRYPTO_INDEX_BY_TITLE: {
    CRYPTO_INTRADAY: "Time Series Crypto (15min)",
    DIGITAL_CURRENCY_DAILY: "Time Series (Digital Currency Daily)",
    DIGITAL_CURRENCY_WEEKLY: "Time Series (Digital Currency Weekly)",
    DIGITAL_CURRENCY_MONTHLY: "Time Series (Digital Currency Monthly)",
  },
  ALPHAVANTAGE_CRYPTO_INTRADAY_INFO: {
    title: "CRYPTO_INTRADAY",
  },
  ALPHAVANTAGE_DIGITAL_CURRENCY_DAILY_INFO: {
    title: "DIGITAL_CURRENCY_DAILY",
  },
  ALPHAVANTAGE_DIGITAL_CURRENCY_WEEKLY_INFO: {
    title: "DIGITAL_CURRENCY_WEEKLY",
  },
  ALPHAVANTAGE_DIGITAL_CURRENCY_MONTHLY_INFO: {
    title: "DIGITAL_CURRENCY_MONTHLY",
  },
  FOR_URL_SYMBOL: {
    BTC: "BTC",
    WBTC: "BTC",
    ETH: "ETH",
    WETH: "ETH",
    USDT: "USDT",
    USDC: "USDT",
  },
  FOR_FETCH_URL: {
    "1d": ["Time Series Crypto (15min)", "CRYPTO_INTRADAY", 1],
    "7d": ["Time Series (Digital Currency Daily)", "DIGITAL_CURRENCY_DAILY", 7],
    "30d": [
      "Time Series (Digital Currency Daily)",
      "DIGITAL_CURRENCY_DAILY",
      30,
    ],
    "90d": [
      "Time Series (Digital Currency Weekly)",
      "DIGITAL_CURRENCY_WEEKLY",
      90,
    ],
    "365d": [
      "Time Series (Digital Currency Monthly)",
      "DIGITAL_CURRENCY_MONTHLY",
      365,
    ],
  },
  TRADE_STATUS:{
    
  }
};
