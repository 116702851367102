const LogoutIcon = ({ isDarkMode }) => {
  return (
    <>
      {isDarkMode ? (
        <svg
          width="13"
          height="15"
          viewBox="0 0 13 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1496_2660)">
            <path
              d="M6.55996 10.53C6.69996 10.67 6.86996 10.74 7.04996 10.74C7.22996 10.74 7.39996 10.67 7.53996 10.53L10.09 7.98C10.37 7.7 10.37 7.26 10.09 6.99L7.53996 4.46C7.25996 4.18 6.81996 4.18 6.54996 4.46C6.26996 4.74 6.26996 5.18 6.54996 5.45L7.90996 6.81H3.65996C3.27996 6.81 2.95996 7.12 2.95996 7.51C2.95996 7.9 3.26996 8.21 3.65996 8.21H7.89996L6.53996 9.57C6.27996 9.81 6.27996 10.27 6.55996 10.53Z"
              fill="#D9D9D9"
            />
            <path
              d="M11.86 5.23C12.24 5.23 12.56 4.92 12.56 4.53V2.51C12.56 1.11 11.44 0 10.05 0H2.51C1.12 0 0 1.12 0 2.51V12.5C0 13.88 1.12 14.99 2.51 14.99H10.06C11.44 14.99 12.55 13.87 12.55 12.48V10.46C12.55 10.08 12.24 9.76 11.85 9.76C11.46 9.76 11.15 10.07 11.15 10.46V12.48C11.15 13.09 10.64 13.6 10.03 13.6H2.51C1.9 13.6 1.39 13.09 1.39 12.48V2.51C1.39 1.9 1.9 1.39 2.51 1.39H10.06C10.67 1.39 11.18 1.9 11.18 2.51V4.53C11.16 4.91 11.48 5.23 11.86 5.23Z"
              fill="#D9D9D9"
            />
          </g>
          <defs>
            <clipPath id="clip0_1496_2660">
              <rect width="12.56" height="15" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <svg
          width="13"
          height="15"
          viewBox="0 0 13 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1496_2660)">
            <path
              d="M6.55996 10.53C6.69996 10.67 6.86996 10.74 7.04996 10.74C7.22996 10.74 7.39996 10.67 7.53996 10.53L10.09 7.98C10.37 7.7 10.37 7.26 10.09 6.99L7.53996 4.46C7.25996 4.18 6.81996 4.18 6.54996 4.46C6.26996 4.74 6.26996 5.18 6.54996 5.45L7.90996 6.81H3.65996C3.27996 6.81 2.95996 7.12 2.95996 7.51C2.95996 7.9 3.26996 8.21 3.65996 8.21H7.89996L6.53996 9.57C6.27996 9.81 6.27996 10.27 6.55996 10.53Z"
              fill="#D9D9D9"
            />
            <path
              d="M11.86 5.23C12.24 5.23 12.56 4.92 12.56 4.53V2.51C12.56 1.11 11.44 0 10.05 0H2.51C1.12 0 0 1.12 0 2.51V12.5C0 13.88 1.12 14.99 2.51 14.99H10.06C11.44 14.99 12.55 13.87 12.55 12.48V10.46C12.55 10.08 12.24 9.76 11.85 9.76C11.46 9.76 11.15 10.07 11.15 10.46V12.48C11.15 13.09 10.64 13.6 10.03 13.6H2.51C1.9 13.6 1.39 13.09 1.39 12.48V2.51C1.39 1.9 1.9 1.39 2.51 1.39H10.06C10.67 1.39 11.18 1.9 11.18 2.51V4.53C11.16 4.91 11.48 5.23 11.86 5.23Z"
              fill="#D9D9D9"
            />
          </g>
          <defs>
            <clipPath id="clip0_1496_2660">
              <rect width="12.56" height="15" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )}
    </>
  );
};

export default LogoutIcon;
