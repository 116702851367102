import { useContext } from "react";
import { ThemeContext } from "ThemeContext";

export default function ReceiptItem({ title = "", value = "", ...props }) {
  const { theme } = useContext(ThemeContext);
  return (
    <div
      {...props}
      className={`flex justify-between text-left border-b border-[#6E7A8A] py-3 ${props.className}`}
    >
      <span
        className={`text-[14px] w-1/2 font-bold ${
          theme === "dark" ? "text-[#D9D9D9]" : ""
        }`}
      >
        {title}
      </span>
      <span
        className={`text-[14px] w-1/2 ${
          theme === "dark" ? "text-[#B0B5BC]" : ""
        }`}
        dangerouslySetInnerHTML={{ __html: value }}
      />
    </div>
  );
}
