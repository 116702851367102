import React, { useState } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

const PhoneNumberInput = ({
  placeholder,
  label,
  defaultValue,
  onChangeHandle
}) => {
  const [value, setValue] = useState(defaultValue);

  return (
    <div className="flex relative flex-col space-y-2 w-full cursor-pointer items-start justify-start">
      <span className=" text-black">{label}</span>
      <PhoneInput
        withCountryCallingCode
        smartCaret
        focusInputOnCountrySelection
        placeholder="Enter phone number"
        value={value}
        onChange={(e) => {
          onChangeHandle(e);
          setValue(e);
        }}
      />
    </div>
  );
};

export default PhoneNumberInput;
