import { useContext, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { SpinningCircles } from "react-loading-icons";
import { ThemeContext } from "ThemeContext";

export default function PrimarySelectBox({
  label = "",
  options = [],
  onChange = (f) => f,
  placeholder = "please select",
  render = null,
  labelField = "label",
  defaultSelect,
  isLoading = false,
  ...props
}) {
  const { theme } = useContext(ThemeContext);
  const defaultOptionRenderFn = (option) => (
    <div>{option[labelField] ? option[labelField] : ""}</div>
  );
  const renderOptionFn = render ? render : defaultOptionRenderFn;

  const [selectedOption, setSelectedOption] = useState(defaultSelect);
  const [showOptions, setShowOptions] = useState(false);

  const handleClickOption = (option) => {
    setSelectedOption(option);
    setShowOptions(false);
    onChange(option);
  };

  const handleComboClick = () => {
    setShowOptions(!showOptions);
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center w-full -translate-y-1/4">
        <SpinningCircles
          stroke="#007bff"
          speed={1.5}
          fill="#007bff"
          width={20}
          height={20}
        />
      </div>
    );
  }

  return (
    <div {...props} className={`${props.className}`}>
      {label ? (
        <div className="text-left">
          <label className={`text-sm font-normal block mb-2 text-gray-600`}>
            {label}
          </label>
        </div>
      ) : null}
      <div
        className="flex justify-between p-2.5 border border-[#6e7a8a] text-[#6E7A8A] rounded-md text-left"
        onClick={handleComboClick}
      >
        <span>{renderOptionFn(selectedOption)}</span>
        <ChevronDownIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
      </div>
      <div className="relative">
        {showOptions ? (
          <div
            className={`border border-[#6e7a8a] absolute top-0 w-full z-50 ${
              theme === "dark" ? "bg-[#181F23]" : "bg-[#d5ebf5]"
            } `}
          >
            {options.map((option, idx) => (
              <div
                className="p-2 text-[#6E7A8A] cursor-pointer"
                onClick={() => handleClickOption(option)}
                key={idx}
              >
                {renderOptionFn(option)}
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
}
