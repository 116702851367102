import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import shortTransactionHash from "utils/shortTransactionHash";

const HashCodeCopy = ({ hashCode }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const truncatedAddress = shortTransactionHash(hashCode);

  return (
    <div>
      {hashCode !== undefined && hashCode !== "" ? (
        <div className="relative cursor-pointer group font-inter">
          {!copied && (
            <div className="absolute font-inter invisible px-4 py-2 text-sm text-center text-black bg-white rounded-md bottom-10 group-hover:visible w-52">
              <p className="text-gray-600  leading-2 ">
                {" "}
                <span className="relative w-full text-gray-600 bg-white rounded ">
                  Copy to clipboard!
                </span>
              </p>
              <svg
                className="absolute z-10  bottom-[-10px] "
                width="16"
                height="10"
                viewBox="0 0 16 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8 10L0 0L16 1.41326e-06L8 10Z" fill="white" />
              </svg>
            </div>
          )}
          <span className="underline hover:cursor-pointer">
            <div className="relative inline-block cursor-pointer">
              <div className="relative rounded-md">
                <CopyToClipboard text={hashCode} onCopy={handleCopy}>
                  <button
                    className={` text-[#6E7A8A] flex items-center rounded-full  ${
                      copied ? "bg-blue-100" : ""
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="inline-block w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75"
                      />
                    </svg>

                    <span className="text-[16px]  mx-2 font-inter">
                      {truncatedAddress}
                    </span>

                    {copied ? "Copied!" : ""}
                  </button>
                </CopyToClipboard>
              </div>
            </div>
          </span>
        </div>
      ) : (
        "Processing.."
      )}
    </div>
  );
};

export default HashCodeCopy;
