import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDepositAll } from "hooks/useDepoistWithdrawAll";
import { SpinningCircles } from "react-loading-icons";
import ShowHistoryPanel from "components/common/ShowHistoryPanel";
import { ThemeContext } from "ThemeContext";

const AddBalanceBottom = () => {
  const { t } = useTranslation();
  const historyDataAll = useDepositAll();
  const { theme } = useContext(ThemeContext);

  return (
    <div
      className={`relative flex flex-col flex-1 gap-5 ${
        theme === "dark" ? "bg-[#181F23] text-white" : "bg-[#D6ECF7] text-black"
      } rounded-[20px]`}
    >
      <div className="text-[14px] font-normal pl-8 pt-4">
        {t("Deposit History")}
      </div>
      <div className="flex w-full justify-between items-center p-8 gap-10 pt-0">
        <div className="flex flex-wrap w-full justify-start items-center gap-7 pt-0">
          {historyDataAll?.isFetched ? (
            <ShowHistoryPanel historyData={historyDataAll?.data?.data?.data} />
          ) : (
            <div className="flex justify-center items-center w-full">
              <SpinningCircles stroke="#007bff" speed={1.5} fill="#007bff" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddBalanceBottom;
