import { useContext, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { ThemeContext } from "ThemeContext";

export default function SelectInput({
  label = "",
  options = [],
  onChange = (f) => f,
  placeholder = "please select",
  ...props
}) {
  const { theme } = useContext(ThemeContext);
  const [value, setValue] = useState(placeholder);
  const [showOptions, setShowOptions] = useState(false);
  const handleClickOption = (option) => {
    setValue(option.label);
    setShowOptions(false);
    onChange(option);
  };
  const handleComboClick = () => {
    setShowOptions(!showOptions);
  };

  return (
    <div {...props} className={`${props.className}`}>
      {label ? (
        <label
          className={`text-sm font-normal block mb-2 ${
            theme === "dark" ? "text-[#D9D9D9]" : "text-[#5F5F5F]"
          }`}
        >
          {label}
        </label>
      ) : null}
      <div
        className="flex justify-between p-2 border border-[#6e7a8a] text-[#6E7A8A] rounded-md"
        onClick={handleComboClick}
      >
        <span>{value}</span>
        <ChevronDownIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
      </div>
      <div className="relative">
        {showOptions ? (
          <div
            className={`border absolute top-0 w-full z-50 ${
              theme === "dark" ? "bg-[#181F23]" : "bg-[#d5ebf5]"
            }`}
          >
            <div className="p-2 text-[#6E7A8A]">{placeholder}</div>
            {options.map((option) => (
              <div
                className="p-2 text-[#6E7A8A] cursor-pointer"
                onClick={() => handleClickOption(option)}
              >
                {option.label}
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
}
