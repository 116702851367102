import { useState } from "react";

export default function PriceInput({
  onChange = (f) => f,
  label,
  placeholder = "",
  ...props
}) {
  const [value, setValue] = useState("");
  const removeCharacterFilter = (inputVal) => {
    return inputVal.replace(/[^\d\.\,]/g, "");
  };
  // const addCommaFilter = (inputVal) => {
  //   const pattern = /([-+]?\d{3})(?=\d)/g;
  //   const str = inputVal.replace(pattern, ($0, $1) => $1 + ",");
  //   return str;
  // };
  const justOnePointFilter = (inputVal) =>
    inputVal.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
  const filter = (inputVal) => {
    return justOnePointFilter(removeCharacterFilter(inputVal));
  };
  const strToNumber = (str) => {
    const number = Number(str.replace(/\,/g, "").trim());
    return number;
  };
  const handleChange = (e) => {
    const str = filter(e.target.value);
    setValue(str);
    onChange(strToNumber(str));
  };

  return (
    <div {...props}>
      <label className="block text-[14px] text-[#D9D9D9] mb-2">{label}</label>
      <input
        placeholder={placeholder}
        onChange={handleChange}
        value={value}
        className="block w-full bg-transparent border border-[#6e7a8a] rounded-lg p-2 text-[#00C880]"
      />
    </div>
  );
}
