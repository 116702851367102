import classNames from "classnames";
import { SpinningCircles } from "react-loading-icons";

const ButtonGreen = ({
  dark,
  size,
  label,
  color,
  onClickHandle,
  disabled = true,
  loading = false,
}) => {
  let isDisabled = loading ? true : disabled;
  return (
    <button
      type="button"
      disabled={isDisabled}
      // style={{
      //   boxShadow: '4px 3px 13px 0px rgba(18, 143, 200, 1)'
      // }}
      className={classNames(
        `inline-block dark w-[100%] sm:w-full font-bold rounded-lg px-3 py-3 text-md uppercase leading-normal transition duration-150 ease-in-out ${
          isDisabled ? "bg-[#00C880] opacity-55" : "bg-[#00C880]"
        } dark:text-gray  text-[#ffffff]`
      )}
      onClick={onClickHandle}
    >
      {loading ? (
        <div className="flex justify-center items-center w-full gap-4">
          <SpinningCircles
            stroke="white"
            speed={1.5}
            fill="white"
            width={20}
            height={20}
          />
          {label}
        </div>
      ) : (
        label
      )}
    </button>
  );
};

export default ButtonGreen;
