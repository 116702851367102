import { useEffect, useState, useRef, useContext } from "react";
import { ThemeContext } from "ThemeContext";
import axios from "axios";
import Chart from "chart.js/auto";
import { ThreeDots } from "react-loading-icons";
import Board from "components/Board";
import { useTranslation } from "react-i18next";
import BuyToken from "./BuyToken";
import SellToken from "./SellToken";
import { fixFloorFilter } from "utils/fixDigitFilter";
import { constants } from "../../constants";
import { useAppData } from "providers/app";

const DayCaption = ({ id, caption, timeframe, setTimeframe }) => {
  return (
    <div
      className={`text-sm font-semibold cursor-pointer hover:opacity-70 ${
        timeframe === id ? "opacity-60" : "opacity-100"
      }`}
      onClick={() => {
        setTimeframe(id);
      }}
    >
      {caption}
    </div>
  );
};

const Card = ({
  title,
  nexusTokenSymbol,
  to,
  imageOrigin,
  imageCompare,
  rate,
  symbol,
  compareRate,
}) => {
  const { infoEUUS } = useAppData();
  // Caption
  const dayIds = [
    { id: "1d", caption: "1D" },
    { id: "7d", caption: "7D" },
    { id: "30d", caption: "1M" },
    { id: "90d", caption: "3M" },
    { id: "365d", caption: "1Y" },
  ];

  //EU/US, USD, EUR
  const [timeframe, setTimeframe] = useState("1d");
  const [loading, setloading] = useState(true);
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const pre_url = constants.PRE_ALPHAVANTAGE_URL;
  const apiKey = `apikey=${process.env.REACT_APP_ALPHAVANTAGE_API_KRY}`;
  const symbolUrl = `symbol=${constants.FOR_URL_SYMBOL[symbol]}&market=${to}`;
  const canvasRef = useRef(null); // Ref to the canvas element
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e) => {
    const input = e.target.value;

    // Use a regular expression to check if the input is a valid float
    const isValidFloat = /^-?\d*(\.\d*)?$/.test(input);

    if (isValidFloat || input === "") {
      setInputValue(input);
    }
  };

  function formatChartDataMMDDHHSS(prices) {
    const formattedData = [];

    for (const entry of prices) {
      const { date, price } = entry;
      const match = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):\d{2}/.exec(date);

      if (match) {
        const month = match[2];
        const day = match[3];
        const hour = match[4];
        const minute = match[5];

        const formattedDate = `${month}-${day} ${hour}:${minute}`;
        formattedData.push({ date: formattedDate, price });
      }
    }

    return formattedData;
  }

  function formatChartDataMMDD(prices) {
    const formattedData = [];

    for (const entry of prices) {
      const { date, price } = entry;
      const match = /(\d{4})-(\d{2})-(\d{2})/.exec(date);

      if (match) {
        const month = match[2];
        const day = match[3];

        const formattedDate = `${month}-${day}`;
        formattedData.push({ date: formattedDate, price });
      }
    }

    return formattedData;
  }

  const fetchData = async () => {
    try {
      let url;
      const currentDate = new Date();
      let startDate, startDateString, response, prices;
      startDate = new Date(currentDate);
      const nDay = constants.FOR_FETCH_URL[timeframe][2];
      startDate.setDate(currentDate.getDate() - nDay);
      startDateString = startDate.toISOString().split("T")[0];
      url = `${pre_url}function=${constants.FOR_FETCH_URL[timeframe][1]}&${symbolUrl}&interval=15min&${apiKey}`;
      response = await axios.get(url);
      prices = response?.data[constants.FOR_FETCH_URL[timeframe][0]];

      let dataArray = [];
      const tempArr = [];
      if (prices !== undefined && prices !== null) {
        Object.keys(prices).map((date) => {
          if (new Date(date).getTime() > new Date(startDateString).getTime()) {
            dataArray.push({
              date,
              price:
                nexusTokenSymbol === constants.USEU_SYMBOL
                  ? parseFloat(prices[date]["4. close"]) *
                    parseFloat(infoEUUS.price)
                  : parseFloat(prices[date]["4. close"]),
            });
          }
          return null;
        });
      }

      if (timeframe === "1d") {
        dataArray = formatChartDataMMDDHHSS(dataArray);
      } else if (timeframe !== "365d")
        dataArray = formatChartDataMMDD(dataArray);

      // Destroy the existing Chart instance if it exists
      if (canvasRef?.current?.chart) {
        canvasRef.current.chart.destroy();
      }

      dataArray.reverse();

      let interval = Math.floor(dataArray.length / 5);
      if (timeframe === "1d") {
        let prices_interval = Math.floor(dataArray.length / 15);
        let temp_arr = [];
        dataArray?.forEach((p, i) => {
          if (i % prices_interval !== 0 && i !== dataArray.length - 1) {
          } else temp_arr.push(p);
        });
        dataArray = temp_arr;
      }
      dataArray.forEach((element, index) => {
        if (index % interval !== 0 && index !== dataArray.length - 1) {
          tempArr.push({
            date: " ",
            price: element.price,
          });
        } else {
          tempArr.push(element);
        }
      });

      const labels = tempArr.map((entry) => entry.date);
      const prices_array = tempArr.map((entry) => entry.price);

      // Create a new Chart instance
      const newChartInstance = new Chart(canvasRef?.current, {
        type: "line",
        data: {
          labels,
          datasets: [
            {
              label: "",
              data: prices_array,
              borderColor: "#467b9e",
              fill: true,
            },
          ],
        },
        // options: {
        //   // responsive: true,
        //   // maintainAspectRatio: false,
        //   plugins: {
        //     title: {
        //       display: true,
        //       text: title
        //     },
        //     legend: false,
        //     tooltip: true
        //   }
        // }
        options: {
          responsive: true,
          plugins: {
            title: {
              display: true,
              text: title,
            },
            legend: false,
            tooltip: true,
          },
          interaction: {
            intersect: true,
          },
        },
        scales: {
          x: {
            display: true,
            title: {
              display: true,
            },
          },
          y: {
            display: true,
            title: {
              display: true,
              text: "Value",
            },
            suggestedMin: -10,
            suggestedMax: 200,
          },
        },
      });

      // Store the new Chart instance in the canvasRef
      canvasRef.current.chart = newChartInstance;
      setloading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const renderChart = async () => {
      if (!loading) setloading(true);
      // Fetch and render new data
      await fetchData();
    };

    renderChart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeframe]);

  return (
    <div
      className={`lg:w-[49%] w-full pt-3 pb-2 ${
        theme === "dark" ? "bg-black" : "bg-white"
      }  rounded-[12px]`}
    >
      <div className="w-full">
        <div className="flex flex-col items-center w-full gap-5 pt-4">
          <span className="flex w-fit gap-6">
            {imageOrigin}
            {imageCompare}
          </span>
          <div
            className={`flex items-center justify-center gap-5 mb-4 ${
              theme === "dark" ? "text-white bg-black" : "text-blue-600 bg-white"
            } transition-all`}
          >
            {dayIds.map((item, index) => (
              <DayCaption
                key={index}
                id={item.id}
                caption={item.caption}
                timeframe={timeframe}
                setTimeframe={setTimeframe}
              ></DayCaption>
            ))}
          </div>
        </div>
        <div className="flex flex-col items-center w-full gap-5">
          <span className="text-gray-600 text-lg">
            {symbol} Price{" "}
            <span className="font-bold">{fixFloorFilter(rate)} USD</span> -
            {title} Price{" "}
            <span className="font-bold">{fixFloorFilter(compareRate)} USD</span>
          </span>
        </div>
        <div className="relative flex w-full pl-5 pr-5">
          <canvas
            ref={canvasRef}
            width={"100%"}
            height={"50%"}
            className={`${
              theme === "dark" ? "text-white bg-black" : "text-blue-600 bg-white"
            } rounded-xl`}
          ></canvas>
          <div className="absolute w-full h-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
            {loading ? (
              <ThreeDots
                width={"100%"}
                height={"100%"}
                stroke="#007bff"
                fill="#007bff"
                speed={0.75}
              />
            ) : null}
          </div>
        </div>
        <div className="flex flex-col items-center w-full gap-5">
          <span className="text-gray-600 text-xl">
            {symbol} Price in {title}{" "}
            <span className="font-bold">
              {fixFloorFilter(
                parseFloat(parseFloat(rate) / parseFloat(compareRate))
              )}
            </span>
          </span>
        </div>
        <Board>
          <div className="flex flex-col items-normal">
            <div className="relative flex flex-wrap items-center justify-center gap-3 text-lg font-semibold">
              <div className="flex border-[1px] border-[#6E7A8A] rounded-[7px] font-normal w-[90%] sm:w-full items-center md:ml-8 md:mr-8 ml-4 mr-4">
                <div
                  className={`pointer-events-none flex items-center pl-2 mr-0 border-none bg-none ${
                    theme === "dark" ? "bg-[none]" : "bg-none"
                  }`}
                >
                  <span
                    className={`${
                      theme === "dark" ? "text-[#D9D9D9]" : "text-[#263238]"
                    } md:text-base text-xs py-2 xl:text-[14px] lg:text-[14px]`}
                  >
                    {title}
                  </span>
                  {/* <span className="text-gray-500 ml-0 xl:text-[14px] lg:text-[14px] md:text-base text-xs">
                    ({rate !== null ? Number(rate).toFixed(4) : 0.0})
                  </span> */}
                </div>
                <input
                  type="text"
                  name="price"
                  id="price"
                  className={`grow rounded-md border pl-2 pr-2 border-none lg:w-[100px] text-gray-300 outline-none placeholder:text-[#6E7A8A] ${
                    theme === "dark"
                      ? "bg-[#000000] text-[#6E7A8A]"
                      : "bg-white text-gray-800"
                  } text-sm xl:text-[14px] lg:text-[14px] lg:leading-6`}
                  value={inputValue}
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex w-full items-center md:ml-8 md:mr-8 ml-4 mr-4 gap-3 pb-4">
                <SellToken
                  buttonTitle={`${t("sell")} ${title}`}
                  nexusTokenSymbol={nexusTokenSymbol}
                  symbol={symbol}
                  value={fixFloorFilter(
                    parseFloat(compareRate * Number(inputValue))
                  )}
                  originValue={fixFloorFilter(Number(inputValue))}
                />
                <BuyToken
                  buttonTitle={`${t("buy")} ${title}`}
                  nexusTokenSymbol={nexusTokenSymbol}
                  symbol={symbol}
                  value={fixFloorFilter(
                    parseFloat(compareRate * Number(inputValue))
                  )}
                  originValue={fixFloorFilter(Number(inputValue))}
                />
              </div>
            </div>
          </div>
        </Board>
      </div>
    </div>
  );
};

export default Card;
