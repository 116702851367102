const Wallet = ({ isDarkMode }) => {
  return (
    <>
      {isDarkMode ? (
        <svg
          width="68"
          height="68"
          viewBox="0 0 68 68"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M60.3996 11.7871H7.59989C6.57083 11.7883 5.58426 12.1977 4.85665 12.9254C4.12904 13.6531 3.71981 14.6397 3.71875 15.6688V52.3312C3.71981 53.3603 4.12904 54.3469 4.85665 55.0746C5.58426 55.8023 6.57083 56.2117 7.59989 56.2129H60.3996C61.4287 56.2118 62.4154 55.8025 63.1431 55.0748C63.8708 54.3471 64.2802 53.3604 64.2812 52.3312V15.6688C64.2802 14.6396 63.8708 13.6529 63.1431 12.9252C62.4154 12.1975 61.4287 11.7882 60.3996 11.7871ZM7.59989 13.9121H60.3996C60.8653 13.9126 61.3119 14.0978 61.6412 14.4271C61.9706 14.7565 62.1558 15.203 62.1562 15.6688V20.2871H5.84375V15.6688C5.84417 15.2031 6.02931 14.7566 6.35854 14.4273C6.68778 14.0979 7.13421 13.9127 7.59989 13.9121ZM62.1562 28.7871H5.84375V22.4121H62.1562V28.7871ZM60.3996 54.0879H7.59989C7.13421 54.0873 6.68778 53.9021 6.35854 53.5727C6.02931 53.2434 5.84417 52.7969 5.84375 52.3312V30.9121H62.1562V52.3312C62.1558 52.797 61.9706 53.2435 61.6412 53.5729C61.3119 53.9022 60.8653 54.0874 60.3996 54.0879Z"
            fill="#0084C9"
            stroke="#263238"
          />
          <path
            d="M15.5498 43.4629H11.6812C11.3994 43.4629 11.1291 43.5748 10.9299 43.7741C10.7306 43.9733 10.6187 44.2436 10.6187 44.5254C10.6187 44.8072 10.7306 45.0774 10.9299 45.2767C11.1291 45.4759 11.3994 45.5879 11.6812 45.5879H15.5498C15.8316 45.5879 16.1019 45.4759 16.3011 45.2767C16.5004 45.0774 16.6123 44.8072 16.6123 44.5254C16.6123 44.2436 16.5004 43.9733 16.3011 43.7741C16.1019 43.5748 15.8316 43.4629 15.5498 43.4629Z"
            fill="#0084C9"
            stroke="#263238"
          />
          <path
            d="M25.8346 43.4629H21.9658C21.684 43.4629 21.4138 43.5748 21.2145 43.7741C21.0153 43.9733 20.9033 44.2436 20.9033 44.5254C20.9033 44.8072 21.0153 45.0774 21.2145 45.2767C21.4138 45.4759 21.684 45.5879 21.9658 45.5879H25.8346C26.1164 45.5879 26.3866 45.4759 26.5859 45.2767C26.7851 45.0774 26.8971 44.8072 26.8971 44.5254C26.8971 44.2436 26.7851 43.9733 26.5859 43.7741C26.3866 43.5748 26.1164 43.4629 25.8346 43.4629Z"
            fill="#0084C9"
            stroke="#263238"
          />
          <path
            d="M36.1187 43.4629H32.25C31.9682 43.4629 31.698 43.5748 31.4987 43.7741C31.2994 43.9733 31.1875 44.2436 31.1875 44.5254C31.1875 44.8072 31.2994 45.0774 31.4987 45.2767C31.698 45.4759 31.9682 45.5879 32.25 45.5879H36.1187C36.4005 45.5879 36.6707 45.4759 36.87 45.2767C37.0692 45.0774 37.1812 44.8072 37.1812 44.5254C37.1812 44.2436 37.0692 43.9733 36.87 43.7741C36.6707 43.5748 36.4005 43.4629 36.1187 43.4629Z"
            fill="#0084C9"
            stroke="#263238"
          />
          <path
            d="M52.7173 39.2131C51.6234 39.2114 50.5562 39.5509 49.6644 40.1843C48.869 39.6232 47.9346 39.2914 46.9634 39.2252C45.9922 39.159 45.0215 39.3609 44.1573 39.8089C43.2931 40.2569 42.5685 40.9337 42.0628 41.7654C41.5571 42.5972 41.2896 43.5519 41.2896 44.5254C41.2895 45.4988 41.557 46.4536 42.0627 47.2853C42.5684 48.1171 43.2929 48.794 44.1571 49.242C45.0213 49.69 45.992 49.892 46.9632 49.8258C47.9344 49.7597 48.8687 49.4279 49.6642 48.8669C50.5561 49.5002 51.6234 49.8397 52.7173 49.8381C54.1262 49.8381 55.4775 49.2784 56.4738 48.2821C57.4701 47.2858 58.0298 45.9345 58.0298 44.5256C58.0298 43.1166 57.4701 41.7654 56.4738 40.7691C55.4775 39.7728 54.1262 39.2131 52.7173 39.2131ZM43.4329 44.5256C43.4329 43.8951 43.6198 43.2789 43.97 42.7547C44.3203 42.2305 44.8181 41.822 45.4005 41.5807C45.983 41.3394 46.6239 41.2763 47.2422 41.3993C47.8605 41.5223 48.4285 41.8259 48.8743 42.2717C49.32 42.7174 49.6236 43.2854 49.7466 43.9037C49.8696 44.522 49.8065 45.1629 49.5652 45.7454C49.324 46.3278 48.9154 46.8256 48.3912 47.1759C47.8671 47.5261 47.2508 47.7131 46.6204 47.7131C45.7753 47.7122 44.965 47.376 44.3674 46.7785C43.7699 46.1809 43.4338 45.3707 43.4329 44.5256ZM52.7173 47.7131C52.1627 47.7101 51.6187 47.5608 51.1402 47.2804C51.6581 46.4549 51.9328 45.5002 51.9329 44.5257C51.9329 43.5512 51.6582 42.5965 51.1403 41.771C51.6187 41.4905 52.1627 41.3411 52.7173 41.338C53.5627 41.338 54.3734 41.6739 54.9712 42.2716C55.569 42.8694 55.9048 43.6802 55.9048 44.5255C55.9048 45.3709 55.569 46.1817 54.9712 46.7794C54.3734 47.3772 53.5627 47.7131 52.7173 47.7131Z"
            fill="#0084C9"
            stroke="#263238"
          />
        </svg>
      ) : (
        <svg
          width="68"
          height="68"
          viewBox="0 0 68 68"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M60.3996 11.7871H7.59989C6.57083 11.7883 5.58426 12.1977 4.85665 12.9254C4.12904 13.6531 3.71981 14.6397 3.71875 15.6688V52.3312C3.71981 53.3603 4.12904 54.3469 4.85665 55.0746C5.58426 55.8023 6.57083 56.2117 7.59989 56.2129H60.3996C61.4287 56.2118 62.4154 55.8025 63.1431 55.0748C63.8708 54.3471 64.2802 53.3604 64.2812 52.3312V15.6688C64.2802 14.6396 63.8708 13.6529 63.1431 12.9252C62.4154 12.1975 61.4287 11.7882 60.3996 11.7871ZM7.59989 13.9121H60.3996C60.8653 13.9126 61.3119 14.0978 61.6412 14.4271C61.9706 14.7565 62.1558 15.203 62.1562 15.6688V20.2871H5.84375V15.6688C5.84417 15.2031 6.02931 14.7566 6.35854 14.4273C6.68778 14.0979 7.13421 13.9127 7.59989 13.9121ZM62.1562 28.7871H5.84375V22.4121H62.1562V28.7871ZM60.3996 54.0879H7.59989C7.13421 54.0873 6.68778 53.9021 6.35854 53.5727C6.02931 53.2434 5.84417 52.7969 5.84375 52.3312V30.9121H62.1562V52.3312C62.1558 52.797 61.9706 53.2435 61.6412 53.5729C61.3119 53.9022 60.8653 54.0874 60.3996 54.0879Z"
            fill="#0084C9"
            stroke="#263238"
          />
          <path
            d="M15.5498 43.4629H11.6812C11.3994 43.4629 11.1291 43.5748 10.9299 43.7741C10.7306 43.9733 10.6187 44.2436 10.6187 44.5254C10.6187 44.8072 10.7306 45.0774 10.9299 45.2767C11.1291 45.4759 11.3994 45.5879 11.6812 45.5879H15.5498C15.8316 45.5879 16.1019 45.4759 16.3011 45.2767C16.5004 45.0774 16.6123 44.8072 16.6123 44.5254C16.6123 44.2436 16.5004 43.9733 16.3011 43.7741C16.1019 43.5748 15.8316 43.4629 15.5498 43.4629Z"
            fill="#0084C9"
            stroke="#263238"
          />
          <path
            d="M25.8346 43.4629H21.9658C21.684 43.4629 21.4138 43.5748 21.2145 43.7741C21.0153 43.9733 20.9033 44.2436 20.9033 44.5254C20.9033 44.8072 21.0153 45.0774 21.2145 45.2767C21.4138 45.4759 21.684 45.5879 21.9658 45.5879H25.8346C26.1164 45.5879 26.3866 45.4759 26.5859 45.2767C26.7851 45.0774 26.8971 44.8072 26.8971 44.5254C26.8971 44.2436 26.7851 43.9733 26.5859 43.7741C26.3866 43.5748 26.1164 43.4629 25.8346 43.4629Z"
            fill="#0084C9"
            stroke="#263238"
          />
          <path
            d="M36.1187 43.4629H32.25C31.9682 43.4629 31.698 43.5748 31.4987 43.7741C31.2994 43.9733 31.1875 44.2436 31.1875 44.5254C31.1875 44.8072 31.2994 45.0774 31.4987 45.2767C31.698 45.4759 31.9682 45.5879 32.25 45.5879H36.1187C36.4005 45.5879 36.6707 45.4759 36.87 45.2767C37.0692 45.0774 37.1812 44.8072 37.1812 44.5254C37.1812 44.2436 37.0692 43.9733 36.87 43.7741C36.6707 43.5748 36.4005 43.4629 36.1187 43.4629Z"
            fill="#0084C9"
            stroke="#263238"
          />
          <path
            d="M52.7173 39.2131C51.6234 39.2114 50.5562 39.5509 49.6644 40.1843C48.869 39.6232 47.9346 39.2914 46.9634 39.2252C45.9922 39.159 45.0215 39.3609 44.1573 39.8089C43.2931 40.2569 42.5685 40.9337 42.0628 41.7654C41.5571 42.5972 41.2896 43.5519 41.2896 44.5254C41.2895 45.4988 41.557 46.4536 42.0627 47.2853C42.5684 48.1171 43.2929 48.794 44.1571 49.242C45.0213 49.69 45.992 49.892 46.9632 49.8258C47.9344 49.7597 48.8687 49.4279 49.6642 48.8669C50.5561 49.5002 51.6234 49.8397 52.7173 49.8381C54.1262 49.8381 55.4775 49.2784 56.4738 48.2821C57.4701 47.2858 58.0298 45.9345 58.0298 44.5256C58.0298 43.1166 57.4701 41.7654 56.4738 40.7691C55.4775 39.7728 54.1262 39.2131 52.7173 39.2131ZM43.4329 44.5256C43.4329 43.8951 43.6198 43.2789 43.97 42.7547C44.3203 42.2305 44.8181 41.822 45.4005 41.5807C45.983 41.3394 46.6239 41.2763 47.2422 41.3993C47.8605 41.5223 48.4285 41.8259 48.8743 42.2717C49.32 42.7174 49.6236 43.2854 49.7466 43.9037C49.8696 44.522 49.8065 45.1629 49.5652 45.7454C49.324 46.3278 48.9154 46.8256 48.3912 47.1759C47.8671 47.5261 47.2508 47.7131 46.6204 47.7131C45.7753 47.7122 44.965 47.376 44.3674 46.7785C43.7699 46.1809 43.4338 45.3707 43.4329 44.5256ZM52.7173 47.7131C52.1627 47.7101 51.6187 47.5608 51.1402 47.2804C51.6581 46.4549 51.9328 45.5002 51.9329 44.5257C51.9329 43.5512 51.6582 42.5965 51.1403 41.771C51.6187 41.4905 52.1627 41.3411 52.7173 41.338C53.5627 41.338 54.3734 41.6739 54.9712 42.2716C55.569 42.8694 55.9048 43.6802 55.9048 44.5255C55.9048 45.3709 55.569 46.1817 54.9712 46.7794C54.3734 47.3772 53.5627 47.7131 52.7173 47.7131Z"
            fill="#0084C9"
            stroke="#263238"
          />
        </svg>
      )}
    </>
  );
};

export default Wallet;
