import { useContext, useEffect, useState } from "react";
import OTCStatusTable from "./OTCStatusTable";
import * as OTCAPI from "api/otc";
import { useUserData } from "UserDataContext";
import useToast from "hooks/useToast";
import { ThemeContext } from "ThemeContext";

export default function OTCStatus() {
  const { showToast } = useToast();

  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const { theme } = useContext(ThemeContext);
  const { userData } = useUserData();

  const getOrders = () => {
    setLoading(true);
    OTCAPI.getOTCOrders({
      userId: userData.id,
    })
      .then((res) => {
        setOrders(res.data);
      })
      .catch((error) => {
        showToast(error.response.data.message, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getOrders();
  }, []);

  return (
    <div
      className={`w-full min-w-[300px] h-[85%] inline-block dark rounded-2xl leading-normal transition duration-150 ease-in-out ${
        theme === "dark" ? "bg-[#263238]" : "bg-[#EBF5FB]"
      } transition-all md:p-8 sm:p-2 overflow-y-auto`}
    >
      <div
        className={`flex flex-col w-full rounded-[20px] p-8 gap-y-4 overflow-x-scroll ${
          theme === "dark" ? "bg-[#000000]" : "bg-[#FFFFFF]"
        }`}
      >
        <OTCStatusTable
          data={orders}
          loading={loading}
          refreshCallback={getOrders}
        />
      </div>
    </div>
  );
}
