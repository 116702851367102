import useToast from 'hooks/useToast';
import { useState } from 'react';
import axios from 'axios';
import { useLogout } from 'hooks/useLogout';
import { useLocation, useNavigate } from 'react-router-dom';

function CheckingCode({ setSelectedPage, number, to }) {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL + '/api'
  });

  // Set the Authorization header with the token
  instance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token');
      if (token) {
        config.headers.Authorization = token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const { showToast } = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const [formData, setFormData] = useState({
    code: ''
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const urlParams = new URLSearchParams(location.search);
      const token = urlParams.get('token');
      await instance
        .post(`/auth/2fa/checking`, {
          phoneNumber: number,
          code: formData.code,
          token: token
        })
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            showToast(res.data?.message, 'success');
            localStorage.setItem('token', token);
            setSelectedPage(to);
            navigate(`/?token=${res.data?.token}`);
          } else {
            showToast(res?.response?.data?.message, 'error');
          }
        });
    } catch (error) {
      // Handle signup error
      console.error('Verification error:', error);
      showToast(error?.response?.data?.message, 'error');
    }
  };

  return (
    <div className="fade-in-load bg-[#ffffff] rounded-2xl transition-all shadow-[1px_1px_1px_1px_rgba(0,0,0,0.1)] flex flex-col py-10 px-10 w-[80%] 2xl:w-1/3 mx-auto justify-center items-center max-w-[600px] gap-7">
      <div className="w-full text-base text-start ">
        <span
          className="font-semibold text-[#128FC8] cursor-pointer hover:opacity-70"
          onClick={() => {
            setSelectedPage('signup');
          }}
        >
          ← Back to Sign Up
        </span>
      </div>
      <span className="text-xl font-bold text-black">
        Phone Number Verification Code
      </span>
      <span className="text-lg text-center text-black ">
        {`Please enter the 6-digit verification code that was sent to ${number}. The code is valid for 30 minutes.`}
      </span>

      <form onSubmit={handleSubmit} className="flex flex-col w-full gap-10">
        <input
          className="inline-block py-4 border border-1 border-[#D9D9D9] bg-[#FFFFFF] rounded-xl focus:outline-none text-black px-3 text-[15px] text-center"
          value={formData.code}
          onChange={(v) => {
            setFormData({ code: v?.target?.value });
          }}
        />
        <div className="flex flex-col items-center justify-center w-full gap-5">
          <button
            type="submit"
            className={`inline-block font-bold rounded-lg py-4 text-lg leading-normal transition duration-150 ease-in-out border-1 bg-[#0084C9] text-[#FFFFFF]  w-full`}
          >
            Verify your code
          </button>
        </div>
      </form>
    </div>
  );
}

export default CheckingCode;
