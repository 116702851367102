import SwapIcon from "components/Svg/Icons/SwapIcon";
import { useContext } from "react";
import { ThemeContext } from "ThemeContext";

export default function Seperator() {
  const { theme } = useContext(ThemeContext);
  return (
    <div className="flex items-center">
      <div
        className={`h-px grow ${
          theme === "dark" ? "bg-white" : "bg-[#6E7A8A]"
        }`}
      ></div>
      <span className="grow-0 p-2">
        <SwapIcon />
      </span>
      <div
        className={`h-px grow ${
          theme === "dark" ? "bg-white" : "bg-[#6E7A8A]"
        }`}
      ></div>
    </div>
  );
}
