import { useContext, useState } from "react";
import { ThemeContext } from "ThemeContext";

export default function TextInput({
  readOnly = false,
  valueIfReadOnly = "",
  onChange = (f) => f,
  label = "",
  placeholder = "",
  balanceStr = "",
  ...props
}) {
  const { theme } = useContext(ThemeContext);
  const [value, setValue] = useState("");
  const handleChange = (e) => {
    setValue(e.target.value);
    onChange(e.target.value);
  };
  const className =
    "block w-full bg-transparent border border-[#6e7a8a] rounded-lg p-2 text-[#00C880]";

  return (
    <div {...props}>
      {label !== "" ? (
        <div className="flex justify-between">
          <label
            className={`block text-[14px] mb-2 ${
              theme === "dark" ? "text-[#D9D9D9]" : ""
            }`}
          >
            {label}
          </label>
          <div className="flex justify-end">
            <span className="text-[#6E7A8A]">
              {"Available Balance: "}
              <span className={`${theme === "dark" ? "text-[#D9D9D9]" : ""}`}>
                {balanceStr}
              </span>
            </span>
          </div>
        </div>
      ) : null}
      {readOnly ? (
        <input
          placeholder={placeholder}
          value={valueIfReadOnly}
          onChange={() => {}}
          className={`${className} disable`}
        />
      ) : (
        <input
          placeholder={placeholder}
          onChange={handleChange}
          value={value}
          className={`${className}`}
        />
      )}
    </div>
  );
}
