import { usePageNum } from "PageNumContext";
import CryptoPayment from "./CryptoPayment";
import { useContext } from "react";
import { ThemeContext } from "ThemeContext";
import WalletIcon from "components/Svg/Icons/WalletIcon";

const AddBalanceTop = () => {
  const { theme } = useContext(ThemeContext);
  const { setPageNum } = usePageNum();
  return (
    <div
      className={`flex p-[10px] ${
        theme === "dark" ? "bg-[#181F23]" : "bg-[#D6ECF7]"
      } w-full rounded-[20px] mb-4 min-w-[300px]`}
    >
      <div
        className={`flex flex-col ${
          theme === "dark" ? "bg-black text-white" : "bg-[#FFFFFF] text-[#6E7A8B]"
        }  w-full rounded-[20px] p-6 gap-y-4`}
      >
        <div className="flex mt-4 w-full justify-between items-center">
          <div className="flex flex-row gap-x-2 items-center">
            <WalletIcon isDarkMode={theme === "dark"} />
            <h2 className="text-[14px] font-semibold">
              My Balance / Add $ / Add Balance
            </h2>
          </div>
          <div
            className="text-sm font-normal text-[#B0B5BC] cursor-pointer hover:opacity-70 items-center"
            onClick={() => {
              setPageNum(1);
            }}
          >
            ← Back
          </div>
        </div>
        <CryptoPayment />
      </div>
    </div>
  );
};
export default AddBalanceTop;
