// import { ConnectButton } from "@rainbow-me/rainbowkit";
import { usePageNum } from "PageNumContext";
import { useAppData } from "providers/app";
import LogoutIcon from "components/Svg/Icons/Logout";
import { useContext } from "react";
import { ThemeContext } from "ThemeContext";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import ButtonDepositMenu from "components/Input/Button_deposit_menu";

const ProfileButton = ({ userData, logout, setSelectedPage }) => {
  const { theme } = useContext(ThemeContext);
  const { setPageNum } = usePageNum();
  const { totalBalanceInUsd, totalBalanceWalletInUsd } = useAppData();

  const getRandomColor = (a) => {
    let hash = 0;
    a.split("").forEach((char) => {
      hash = char.charCodeAt(0) + ((hash << 5) - hash);
    });
    let colour = "#";
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      colour += value.toString(16).padStart(2, "0");
    }
    console.log(colour);
    return colour;
  };

  return (
    <div className="flex items-center justify-center align-middle h-full gap-3 flex-row ">
      <div className="flex flex-row items-center gap-2">
        <div className=" h-8 p-3 cursor-pointer rounded border border-sky-600 justify-center items-center gap-2 md:mr-4 mr-1 md:inline-flex  hidden">
          <div
            className={`${
              theme === "dark" ? "text-white" : "text-black"
            } text-base font-medium font-['Poppins']`}
          >
            $ {(totalBalanceInUsd + totalBalanceWalletInUsd)?.toFixed(2)}
          </div>
          <div className="text-sky-600 text-xs font-bold font-['Inter']">▽</div>
        </div>
        {/* <div className=" cursor-pointer rounded  justify-center items-center gap-2 md:mr-4 mr-1 md:hidden block">
          <InputSearch></InputSearch>
        </div> */}
        {userData?.photos.length > 0 ? (
          <img
            onClick={() => {
              setPageNum(6); // MY Acount
            }}
            className="w-8 h-8 border-2 border-white rounded-full shadow cursor-pointer"
            src={userData?.photos[0]?.value}
            alt="avatar"
          />
        ) : (
          <span
            className={`w-16 h-10 rounded-full flex items-center justify-center `}
            style={{
              backgroundColor: getRandomColor(userData?.displayName),
            }}
          >
            {userData?.displayName.trim().slice(0, 2).toUpperCase()}
          </span>
        )}
        <span>{userData?.displayName}</span>
      </div>

      <div className="flex flex-row gap-2 items-center align-middle md:pl-4 pl-1 justify-center">
        <ConnectButton />
        <span className="md:block hidden">
          <ButtonDepositMenu
            label="Deposit"
            dark
            onClickHandle={() => {
              setPageNum(2);
            }}
          />
        </span>
        {/* <span className="md:hidden block">
          <SettingIcon></SettingIcon>
        </span>
        <span className="md:hidden block md:pl-[13px] pl-[5px]">
          <DiscussIcon></DiscussIcon>
        </span> */}
        <div
          onClick={() => {
            logout();
            setSelectedPage("login");
          }}
          className={`flex transition-all text-[#128FC8] cursor-pointer md:pl-3 pl-1`}
        >
          <div className="flex items-center uppercase transition-all gap-x-2 text-[#D9D9D9] hover:text-gray-400">
            <LogoutIcon></LogoutIcon>
            <span className="text-[12px] md:block hidden">Logout</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileButton;
