import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "style/style.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { UserProvider } from "UserDataContext";
import { I18nextProvider } from "react-i18next";
import "@rainbow-me/rainbowkit/styles.css";
import i18next from "i18next";
import "./i18n";
import { LogoutProvider } from "hooks/useLogout";
import { WagmiProvider } from "wagmi";
import { config } from "./config";
import { RainbowKitProvider, lightTheme, darkTheme } from "@rainbow-me/rainbowkit";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
const queryClient = new QueryClient();

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
});
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <UserProvider>
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <RainbowKitProvider
            chains={config.chains}
            initialChain={config.chains[0]}
            theme={darkTheme({
              accentColor: "#0084C9",
            })}
          >
            <LogoutProvider>
              <I18nextProvider i18n={i18next}>
                <App />
              </I18nextProvider>
            </LogoutProvider>
          </RainbowKitProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </UserProvider>
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
