import { SpinningCircles } from "react-loading-icons";

export default function OrderButton({
  onClick = (f) => f,
  postingFlag = false,
  isDisabled = false,
  messageDisabled = "",
  ...props
}) {
  const disabled = postingFlag || isDisabled;
  return (
    <div>
      <button
        {...props}
        className={`w-full p-3 ${
          disabled ? "bg-[#263238]" : "bg-[#0088CD]"
        } text-white ${props.className}`}
        onClick={onClick}
        disabled={disabled}
      >
        <div className="flex items-center justify-center">
          {postingFlag ? (
            <SpinningCircles
              stroke="#ffffff"
              speed={1.5}
              fill="#ffffff"
              width={20}
              height={20}
            />
          ) : null}
          <span className={`ml-5 ${disabled ? "text-[#b0b5bc]" : ""}`}>
            Place Order
          </span>
        </div>
      </button>
      <p className="text-center text-red-400">{messageDisabled}</p>
    </div>
  );
}
