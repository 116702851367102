// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.primary-btn {
  background-color: #0084c9;
  border-radius: 0.25rem;
  color: #fff;
  padding: 0.65rem 1rem;
  width: 100%;
}

.disabled-btn {
  background-color: #6e7a8a;
  color: #b0b5bc;
}

.Toastify__toast-container--top-right {
  z-index: 1000000 !important;
}
`, "",{"version":3,"sources":["webpack://./src/style/style.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,sBAAsB;EACtB,WAAW;EACX,qBAAqB;EACrB,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":[".primary-btn {\n  background-color: #0084c9;\n  border-radius: 0.25rem;\n  color: #fff;\n  padding: 0.65rem 1rem;\n  width: 100%;\n}\n\n.disabled-btn {\n  background-color: #6e7a8a;\n  color: #b0b5bc;\n}\n\n.Toastify__toast-container--top-right {\n  z-index: 1000000 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
