import { useState } from "react";
import BuyTokenDialog from "components/Dialog/BuyToken";
import ButtonRed from "components/Input/Button_red";
import useToast from "hooks/useToast";

const SellToken = ({ buttonTitle, token, symbol, value, originValue }) => {
  const [openDialog, setopenDialog] = useState(false);
  const { showToast } = useToast();

  return (
    <div className="flex w-[50%] justify-center">
      <BuyTokenDialog
        onClose={() => {
          setopenDialog(false);
        }}
        isOpen={openDialog}
        token={token}
        symbol={symbol}
        value={value}
        orginValue={originValue}
      />
      <ButtonRed
        label={buttonTitle}
        onClickHandle={() => {
          if (value !== null && Number(value) > 0.0) {
            setopenDialog(true);
          } else showToast(`Money should be bigger than zero!`, "error");
        }}
      />
    </div>
  );
};

export default SellToken;
