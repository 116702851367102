const CheckedIcon = ({ isDarkMode }) => {
  return (
    <>
      {isDarkMode ? (
        <svg
          width="34"
          height="34"
          viewBox="0 0 34 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.5"
            y="0.5"
            width="33"
            height="33"
            rx="5.5"
            stroke="#6E7A8A"
          />
          <path
            d="M25 11L13.3636 22.6364L9 18.2727"
            stroke="#F7F7F7"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
        </svg>
      ) : (
        <svg
          width="34"
          height="34"
          viewBox="0 0 34 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.5"
            y="0.5"
            width="33"
            height="33"
            rx="5.5"
            stroke="#6E7A8A"
          />
          <path
            d="M25 11L13.3636 22.6364L9 18.2727"
            stroke="#181F23"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
        </svg>
      )}
    </>
  );
};

export default CheckedIcon;
