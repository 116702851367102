import classNames from "classnames";
import PrimarySpinner from "components/common/PrimarySpinner";

const ButtonDark = ({
  label,
  submit,
  onClickHandle,
  loading = false,
  disabled = false,
  className = "",
}) => {
  const _disabled = loading || disabled;
  return (
    <button
      type={submit ? "submit" : "button"}
      onClick={onClickHandle}
      className={classNames(className, "primary-btn h-12 text-[15px]", {
        "disabled-btn": _disabled,
      })}
      disabled={_disabled}
    >
      <div className="flex justify-center items-center cursor-poiter">
        {loading ? <PrimarySpinner className="mr-2" /> : null}
        {label}
      </div>
    </button>
  );
};

export default ButtonDark;
