import { dashboard_contents } from ".";
import SidebarItem from "components/DashboardSidebar/Item";
import { usePageNum } from "PageNumContext";
import { useTranslation } from "react-i18next";
import { useUserData } from "UserDataContext";
import { useContext } from "react";
import { ThemeContext } from "ThemeContext";

const SideBar = ({ show, setter, changeLanguage }) => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const { setPageNum, pageNum } = usePageNum();
  const appendClass = show ? " ml-0" : " ml-[-250px] md:ml-0";
  const { userData } = useUserData();
  return (
    <div
      className={`inline-block dark font-bold md:relative absolute text-xs uppercase leading-normal transition duration-150 ease-in-out  min-w-[180px] z-20 w-1/5 overflow-y-auto ${
        theme === "dark" ? "bg-[#202630] border-none" : "bg-[#ffffff] border-none"
      } dark:text-gray text-[#128FC8]   ${appendClass}
       transition-[margin-left] ease-in-out duration-500 left-0 z-40 rounded-lg
      `}
    >
      <div className="flex flex-col items-start justify-start bg-inherit">
        <div className="w-full h-full overflow-hidden shadow-lg bg-inherit">
          {dashboard_contents({
            changeLanguage: changeLanguage,
            membership: userData?.membership,
          })?.map((c, index) =>
            !c?.noSidebar ? (
              <SidebarItem
                label={t(c?.label)}
                icon={c?.icon}
                show={c?.show}
                key={index}
                onClickHandle={() => {
                  setPageNum(index);
                  setter(false);
                }}
                isSelected={pageNum === index}
                hasSeperatorLine={c?.hasSeperatorLine}
              />
            ) : null
          )}
        </div>
      </div>
    </div>
  );
};

export default SideBar;
