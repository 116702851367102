import { SpinningCircles } from "react-loading-icons";

const PrimarySpinner = ({ size = "sm", ...props }) => {
  const SIZE = {
    sm: 20,
    md: 50,
    lg: 80,
  };
  return (
    <div className={`${props.className || "m-auto"}`}>
      <SpinningCircles
        stroke="#ffffff"
        speed={1.5}
        fill="#ffffff"
        width={SIZE[size]}
        height={SIZE[size]}
      />
    </div>
  );
};

export default PrimarySpinner;
