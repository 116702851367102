import classNames from "classnames";

const ButtonRed = ({ dark, size, label, color, onClickHandle }) => {
  return (
    <button
      type="button"
      // style={{
      //   boxShadow: '4px 3px 13px 0px rgba(18, 143, 200, 1)'
      // }}
      className={classNames(
        `inline-block dark w-[100%] sm:w-full font-bold rounded-lg px-3 py-3 text-md uppercase leading-normal transition duration-150 ease-in-out bg-[#F5435B] dark:text-gray text-[#ffffff]`
      )}
    >
      {label}
    </button>
  );
};

export default ButtonRed;
