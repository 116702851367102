import ButtonGreen from "components/Input/Button_green";
import { useState } from "react";
import { useUserData } from "UserDataContext";
import * as AppStatesApi from "api/app";
import useToast from "hooks/useToast";
import { DialogCustom } from "components/Dialog";

const checkNum = (value) => {
  return /^-?\d*(\.\d*)?$/.test(value);
};

const Alert = ({ value }) => {
  const isNum = checkNum(value);
  const nLen = value.length;
  if (value === "" || isNum === false) {
    return nLen > 0 ? (
      <p className="text-center text-red-400">
        The value is not number. Pelase enter again.
      </p>
    ) : null;
  }
  return nLen === 6 ? null : (
    <p className="text-center text-red-400">
      The length must be a maximum of 6.
    </p>
  );
};

const WithdrawDialog = ({
  isOpen,
  onClose,
  symbol,
  feeValue,
  amount,
  address,
  reloadBalance = (e) => {},
}) => {
  const [inputValue, setInputValue] = useState("");
  const { userData } = useUserData();
  const { showToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const isDiabled = !(checkNum(inputValue) === true && inputValue.length === 6);
  const hideEmail = (value) => {
    const partialEmail = value.replace(
      /(\w{2})[\w.-](\w{1})+@([\w.]+\w)/,
      "$1***$2@$3"
    );
    return partialEmail;
  };
  const receiveAmount = amount - feeValue;

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const withdrawCreateHandle = (amount, symbol) => {
    setIsLoading(true);
    const payload = {
      userId: userData.id,
      amount: amount,
      symbol: symbol,
      address: address,
    };
    AppStatesApi.withdrawCreate(payload)
      .then((response) => {
        showToast(`${response?.data?.message}`);
        reloadBalance();
      })
      .catch((error) => {
        showToast(`${error?.response?.data?.message}`, "error");
      })
      .finally(() => {
        setIsLoading(false);
        setInputValue("");
        onClose();
      });
  };

  return (
    <DialogCustom
      header={
        <div className="text-center w-full mt-6">
          Withdrawals Details Confirmation
        </div>
      } // Translate the header
      isOpen={isOpen}
      onClose={onClose}
      size="xs"
      body={
        <div className="grid grid-cols-1 gap-6 p-6 pt-0">
          <div className="flex flex-col w-full gap-3 bg-gray-900 opacity-85 rounded-lg p-3">
            <div className="flex md:flex-row flex-col justify-between gap-1">
              <span>Received</span>
              <span>
                <span className="text-white">
                  {receiveAmount} {symbol}
                </span>
                (Fee: {feeValue} {symbol})
              </span>
            </div>
            <div className="flex md:flex-row flex-col justify-between gap-1">
              <span>Address</span>
              <span className="text-white">{address}</span>
            </div>
            <div className="flex md:flex-row flex-col justify-between gap-1">
              <span>Amount</span>
              <span className="text-white">{amount}</span>
            </div>
            <div className="flex justify-between gap-1 text-orange-600">
              *Please make sure to verify the accuracy of the wihtdrawal address
              and transfer network to prevent the loss of your assets
            </div>
          </div>
          <div className="flex flex-col w-full gap-3 bg-tranparent rounded-lg p-1">
            <div className="flex flex-col gap-1">
              <span className="text-white text-lg">Security Verification</span>
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-white">Email Verification Code</span>
              <div className="h-fit flex bg-inherit rounded border border-[#6E7A8A] justify-between px-4 py-1 items-center w-full cursor-pointer">
                <input
                  type="email"
                  className="text-[#B0B5BC] text-xs font-normal font-['Inter'] w-full h-full py-2.5 outline-none bg-inherit rounded"
                  value={inputValue}
                  onChange={handleChange}
                />
              </div>
              <Alert value={inputValue} />

              <span>
                Enter the 6-digit code sent to{" "}
                {hideEmail(userData?.emails[0]?.value)}
              </span>
              <span>Didn't receive email verification code?</span>
              <ButtonGreen
                label={"Submit"}
                disabled={isDiabled}
                onClickHandle={() => withdrawCreateHandle(amount, symbol)}
                loading={isLoading}
              />
            </div>
          </div>
        </div>
      }
    />
  );
};

export default WithdrawDialog;
