import { useUserData } from "UserDataContext";
import CalculatorIcon from "components/Svg/Icons/CalculatorIcon";
import ReceiptPanel from "./ReceiptPanel";
import InputPanel from "./InputPanel";
import { useContext, useEffect, useState } from "react";
import * as OTCAPI from "api/otc";
import useToast from "hooks/useToast";
import { useAppData } from "providers/app";
import { fixDigitFilter } from "utils/fixDigitFilter";
import { ThemeContext } from "ThemeContext";

export default function OTCCalculator() {
  const { userData } = useUserData();
  const { coins, defaultSelectedCoin, infoEUUS, infoUSEU } = useAppData();

  const { theme } = useContext(ThemeContext);
  const { showToast } = useToast();
  const [euusChecked, setEUUSChecked] = useState(false);
  const [useuChecked, setUSEUChecked] = useState(false);
  const [discountChecked, setDiscountChecked] = useState(false);
  // const [nexusWalletChecked, setNexusWalletChecked] = useState(false);
  // const [otherWalletChecked, setOtherWalletChecked] = useState(false);
  // const [address, setAddress] = useState("");
  const [euusAmount, setEUUSAmount] = useState(0);
  const [useuAmount, setUSEUAmount] = useState(0);
  const [memberships, setMemberships] = useState([]);
  const [currentMembership, setCurrentMembership] = useState(null);
  const [feeTradeOTC, setFeeTradeOTC] = useState(1);
  const [minOrderAmountOTC, setMinOrderAmountOTC] = useState(0);
  const [postingFlag, setPostingFlag] = useState(false);
  const [orderID, setOrderID] = useState("");
  const [selectedCoin, setSelectedCoin] = useState(defaultSelectedCoin);

  const euusCost = euusChecked ? infoEUUS.price * euusAmount : 0;
  const useuCost = useuChecked ? infoUSEU.price * useuAmount : 0;
  const totalDueAmount = fixDigitFilter(euusCost + useuCost);
  const totalAmount = selectedCoin ? totalDueAmount / selectedCoin.price : 0;
  const payMethodAndValue = selectedCoin
    ? `${selectedCoin.symbol} ($${fixDigitFilter(selectedCoin.price)})`
    : "";
  const dueBalance = selectedCoin
    ? `${fixDigitFilter(totalAmount)} ${
        selectedCoin.symbol
      } (Total $${totalDueAmount})`
    : "";
  const useuOrderStr = useuChecked
    ? `${useuAmount} US/EU ($${fixDigitFilter(infoUSEU.price)})`
    : "";
  const euusOrderStr = euusChecked
    ? `${euusAmount} EU/US ($${fixDigitFilter(infoEUUS.price)})`
    : "";
  const youOrderStr = `${
    useuOrderStr ? useuOrderStr + "<br/>" : ""
  } ${euusOrderStr}`;

  const discountedEUUS =
    discountChecked && euusChecked && currentMembership
      ? (euusAmount * currentMembership.rewardPercent) / 100
      : 0;
  const discountedUSEU =
    discountChecked && useuChecked && currentMembership
      ? (useuAmount * currentMembership.rewardPercent) / 100
      : 0;
  const discountedEUUSStr =
    discountChecked && euusChecked && currentMembership
      ? `+${fixDigitFilter(discountedEUUS, 2)} EU/US`
      : "";
  const discountedUSEUStr =
    discountChecked && useuChecked && currentMembership
      ? `+${fixDigitFilter(discountedUSEU, 2)} US/EU`
      : "";

  const discountStr =
    discountChecked && currentMembership
      ? `${currentMembership.label}<br/>${
          discountedUSEUStr ? discountedUSEUStr + "<br/>" : ""
        }${discountedEUUSStr}`
      : "";

  let totalTokensReceive = "";
  if (euusChecked && useuChecked) {
    totalTokensReceive = `${fixDigitFilter(
      useuAmount + discountedUSEU,
      2
    )} US/EU and ${fixDigitFilter(euusAmount + discountedEUUS, 2)} EU/US`;
  } else if (euusChecked) {
    totalTokensReceive = `${fixDigitFilter(
      euusAmount + discountedEUUS,
      2
    )} EU/US`;
  } else if (useuChecked) {
    totalTokensReceive = `${fixDigitFilter(
      useuAmount + discountedUSEU,
      2
    )} US/EU`;
  } else {
    totalTokensReceive = "";
  }

  const fee = (totalAmount * feeTradeOTC) / 100;
  const feeStr = selectedCoin
    ? `${fixDigitFilter(fee)} ${selectedCoin.symbol}`
    : "";

  const totalCostStr = selectedCoin
    ? `${fixDigitFilter(totalAmount + fee)} ${selectedCoin.symbol}`
    : "";

  const receipts = [
    { title: "You pay in:", value: payMethodAndValue },
    { title: "Due balance is:", value: dueBalance },
    { title: "You Order:", value: youOrderStr },
    { title: "Discount:", value: discountStr },
    { title: "TotalTokensReceive:", value: totalTokensReceive },
    { title: `Fees ${feeTradeOTC}%:`, value: feeStr },
    { title: "Total Cost:", value: totalCostStr },
  ];

  const handleClickOrder = () => {
    const payload = {
      userId: userData.id,
      depositToken: {
        symbol: selectedCoin.symbol,
        amount: totalAmount,
      },
      // deposit_amount_fiat: 100,
      credit: [
        {
          symbol: "USEU",
          amount: useuChecked ? useuAmount : 0,
        },
        {
          symbol: "EUUS",
          amount: euusChecked ? euusAmount : 0,
        },
      ],
      lockPeriod:
        currentMembership === null || currentMembership === undefined
          ? 0
          : currentMembership.period,
      rewardPercent:
        currentMembership === null || currentMembership === undefined
          ? 0
          : currentMembership.rewardPercent,
      paymentMethod: "Crypto", // enum type ["Fiat", "Crypto"],
      orderId: orderID,
    };
    setPostingFlag(true);
    OTCAPI.createOTCOrder(payload)
      .then((res) => {
        showToast(
          "Success to create an order, please check OTC status page",
          "success"
        );
      })
      .catch((error) => {
        showToast(error.response.data.message, "error");
      })
      .finally(() => {
        setPostingFlag(false);
      });
  };

  const onCoinSelectionChange = (coin) => {
    setSelectedCoin(coins.filter((c) => c.symbol === coin.symbol)[0]);
  };

  const isDisabledOrderButton =
    totalAmount + fee > selectedCoin.balance ||
    totalDueAmount < minOrderAmountOTC;
  const messageDisabled =
    totalAmount + fee > selectedCoin.balance
      ? "Your balance is insufficient."
      : totalDueAmount < minOrderAmountOTC
      ? "Your order should be more than minimum amount."
      : "";
  useEffect(() => {
    OTCAPI.getOTCMemberships().then((res) => {
      const memberships = res.data.map((item) => ({
        id: item._id,
        period: item.period,
        rewardPercent: item.rewardPercent,
        label: `${item.period}Months Lock + ${item.rewardPercent}% Tokens`,
      }));
      setMemberships(memberships);
    });

    OTCAPI.getOTCConstants().then((res) => {
      setMinOrderAmountOTC(res.data.min_order_amount_otc);
      setFeeTradeOTC(res.data.fee_trade_otc);
    });
    OTCAPI.getOTCOrderID(userData.id).then((res) => {
      setOrderID(res.data);
    });
  }, []);

  useEffect(() => {
    onCoinSelectionChange(selectedCoin);
  }, [coins]);
  return (
    <div
      className={`w-full min-w-[300px] h-[100%] inline-block dark rounded-2xl leading-normal transition duration-150 ease-in-out ${
        theme === "dark" ? "bg-[#263238]" : "bg-[#EBF5FB]"
      } transition-all md:p-8 sm:p-2 overflow-y-auto`}
    >
      <div
        className={`flex w-full h-fit p-[10px] rounded-[20px] ${
          theme === "dark" ? "bg-[#181F23]" : "bg-[#FFFFFF]"
        }`}
      >
        <div
          className={`flex flex-col w-full rounded-[20px] p-8 gap-y-4 ${
            theme === "dark" ? "bg-[#000000]" : "bg-[#FFFFFF]"
          }`}
        >
          <div className="flex items-center">
            <CalculatorIcon isDarkMode={theme === "dark"} />
            <p
              className={`text-[14px] font-semibold ml-3 ${
                theme === "dark" ? "text-white" : ""
              }`}
            >
              Nexus Pro OTC Calculator
            </p>
          </div>
          <div className="grid lg:grid-cols-2 sm:grid-cols-1 gap-4 md:divide-x md:divide-[#263238]">
            <InputPanel
              onEUUSChange={setEUUSChecked}
              onUSEUChange={setUSEUChecked}
              onEUUSPriceChange={setEUUSAmount}
              onUSEUPriceChange={setUSEUAmount}
              onDiscountChange={setDiscountChecked}
              // onNexusWalletChange={setNexusWalletChecked}
              // onOtherWalletChange={setOtherWalletChecked}
              // onAddressChange={setAddress}
              totalAmount={
                selectedCoin
                  ? `${fixDigitFilter(totalAmount)} ${selectedCoin.symbol}`
                  : ""
              }
              onCoinSelectionChange={onCoinSelectionChange}
              memberships={memberships}
              onMembershipChange={(option) => setCurrentMembership(option)}
              minOrderAmountOTC={minOrderAmountOTC}
              coins={coins}
              selectedCoin={selectedCoin}
              balanceStr={
                selectedCoin
                  ? `${fixDigitFilter(selectedCoin.balance)} ${
                      selectedCoin.symbol
                    }`
                  : ""
              }
            />
            <ReceiptPanel
              className="md:pl-4"
              receipts={receipts}
              onClickOrder={handleClickOrder}
              postingFlag={postingFlag}
              orderID={orderID}
              isDisabled={isDisabledOrderButton}
              messageDisabled={messageDisabled}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
