import { useState } from "react";
import BuyTokenDialog from "components/Dialog/BuyToken";
import ButtonGreen from "components/Input/Button_green";
import useToast from "hooks/useToast";

const BuyToken = ({
  buttonTitle,
  nexusTokenSymbol,
  symbol,
  value,
  originValue,
}) => {
  const [openDialog, setopenDialog] = useState(false);
  const { showToast } = useToast();

  return (
    <div className="flex w-[50%] justify-center">
      <BuyTokenDialog
        onClose={() => {
          setopenDialog(false);
        }}
        isOpen={openDialog}
        nexusTokenSymbol={nexusTokenSymbol}
        symbol={symbol}
        value={value}
        orginValue={originValue}
      />
      <ButtonGreen
        label={buttonTitle}
        disabled={false}
        onClickHandle={() => {
          if (value !== null && Number(value) > 0.0) {
            setopenDialog(true);
          } else showToast(`Money should be bigger than zero!`, "error");
        }}
      />
    </div>
  );
};

export default BuyToken;
