import { useContext, useEffect, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import TabPanel from "components/TabPanel";
import { useAppData } from "providers/app";
import { Box, Tab, Tabs } from "@mui/material";
import DepositWithdrawFilterPanel from "./DepositWithdrawFilterPanel";
import dayjs from "dayjs";
import * as TradeHistoryApi from "api/tradeHistory";
import useToast from "hooks/useToast";
import { useUserData } from "UserDataContext";
import DepositTable from "./DepositTable";
import WithdrawTable from "./WithdrawTab";
import TradeFilterPanel from "./TradeFilterPanel";
import TradeTab from "./TradeTab";
import { ThemeContext } from "ThemeContext";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const TradingHistory = () => {
  const { showToast } = useToast();
  const { userData } = useUserData();
  const { balances } = useAppData();
  const { theme } = useContext(ThemeContext);
  const [tabValue, setTabValue] = useState(0);
  const [loadingDepositData, setLoadingDepositData] = useState(false);
  const [loadingWithdrawData, setLoadingWithdrawData] = useState(false);
  const [loadingTradeData, setLoadingTradeData] = useState(false);
  const [depositData, setDepositData] = useState([]);
  const [withdrawData, setWithdrawData] = useState([]);
  const [tradeData, setTradeData] = useState([]);
  const coinAll = {
    balance: 0,
    balanceInUsd: 0,
    isNative: true,
    name: "All",
    price: "0",
    showOnBuy: true,
    symbol: "",
  };
  const coins = [coinAll, ...balances];
  const [selectedCoin, setSelectedCoin] = useState(coinAll);
  const [selectedSellCoin, setSelectedSellCoin] = useState(coinAll);
  const [selectedBuyCoin, setSelectedBuyCoin] = useState(coinAll);
  const currentDate = new Date();
  const day = currentDate.getDate().toString().padStart(2, "0");
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const year = currentDate.getFullYear();
  const [fromDate, setFromDate] = useState(
    dayjs(`${year}-${month - 3}-${day}`)
  );
  const [toDate, setToDate] = useState(dayjs(`${year}-${month}-${day}`));

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const onChangeFromDate = (value) => {
    setFromDate(value);
  };
  const onChangeToDate = (value) => {
    setToDate(value);
  };
  const onCoinSelectionChange = (coin) => {
    setSelectedCoin(coins.filter((c) => c.symbol === coin.symbol)[0]);
  };
  const onSellCoinSelectionChange = (coin) => {
    setSelectedSellCoin(coins.filter((c) => c.symbol === coin.symbol)[0]);
  };
  const onBuyCoinSelectionChange = (coin) => {
    setSelectedBuyCoin(coins.filter((c) => c.symbol === coin.symbol)[0]);
  };

  const getDepositHistoryData = () => {
    setLoadingDepositData(true);
    TradeHistoryApi.getDepositHistory({
      userId: userData.id,
      fromDate: fromDate.format("YYYY-MM-DD"),
      toDate: toDate.format("YYYY-MM-DD"),
      symbol: selectedCoin.symbol,
    })
      .then((res) => {
        setDepositData(
          res.data.data.map((e) => ({
            ...e,
            symbol: e.token.symbol,
            icon: e.token.icon_url,
          }))
        );
      })
      .catch((error) => {
        showToast(error.response.data.message, "error");
      })
      .finally(() => {
        setLoadingDepositData(false);
      });
  };

  const getWithdrawHistoryData = () => {
    setLoadingWithdrawData(true);
    TradeHistoryApi.getWithdrawHistory({
      userId: userData.id,
      fromDate: fromDate.format("YYYY-MM-DD"),
      toDate: toDate.format("YYYY-MM-DD"),
      symbol: selectedCoin.symbol,
    })
      .then((res) => {
        setWithdrawData(
          res.data.data.map((e) => ({
            ...e,
            symbol: e.token.symbol,
            icon: e.token.icon_url,
          }))
        );
      })
      .catch((error) => {
        showToast(error.response.data.message, "error");
      })
      .finally(() => {
        setLoadingWithdrawData(false);
      });
  };
  
  const getTradeHistoryData = () => {
    setLoadingTradeData(true);
    TradeHistoryApi.getTradeHistory({
      userId: userData.id,
      fromDate: fromDate.format("YYYY-MM-DD"),
      toDate: toDate.format("YYYY-MM-DD"),
      inSymbol: selectedSellCoin.symbol,
      outSymbol: selectedBuyCoin.symbol,
    })
      .then((res) => {
        setTradeData(
          res.data.data.map((e) => ({
            ...e,
            sellCoinSymbol: e.in_token.token.symbol,
            sellCoinIcon: e.in_token.token.icon_url,
            sellCoinPrice: e.in_token.price,
            sellAmount: e.in_token.amount,
            buyCoinSymbol: e.out_token.token.symbol,
            buyCoinIcon: e.out_token.token.icon_url,
            buyCoinPrice: e.out_token.price,
            buyAmount: e.out_token.amount,
          }))
        );
      })
      .catch((error) => {
        showToast(error.response.data.message, "error");
      })
      .finally(() => {
        setLoadingTradeData(false);
      });
  };
  useEffect(() => {
    if (tabValue === 0) {
      getDepositHistoryData();
    } else if (tabValue === 1) {
      getWithdrawHistoryData();
    } else if (tabValue === 2) {
      getTradeHistoryData();
    }
  }, [
    fromDate,
    toDate,
    selectedCoin,
    tabValue,
    selectedSellCoin,
    selectedBuyCoin,
  ]);

  return (
    <div
      className={`w-full h-[95%] inline-block dark font-bold rounded-2xl text-xs uppercase leading-normal transition duration-150 ease-in-out ${
        theme === "dark" ? "bg-[#263238]" : "bg-[#EBF5FB]"
      } dark:text-gray md:p-8 p-2 text-[#128FC8] transition-all`}
    >
      <div
        className={`flex flex-col w-full rounded-2xl h-[100%] overflow-y-auto ${
          theme === "dark" ? "bg-[#000000]" : "bg-[#FFFFFF]"
        }`}
      >
        <ThemeProvider theme={theme === "dark" ? darkTheme : ""}>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="basic tabs example"
              >
                <Tab label="Deposit" {...a11yProps(0)} />
                <Tab label="Withdrawal" {...a11yProps(1)} />
                <Tab label="Trade" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
              <DepositWithdrawFilterPanel
                coins={coins}
                fromDate={fromDate}
                toDate={toDate}
                selectedCoin={selectedCoin}
                onChangeFromDate={onChangeFromDate}
                onChangeToDate={onChangeToDate}
                onCoinSelectionChange={onCoinSelectionChange}
              />
              <DepositTable data={depositData} loading={loadingDepositData} />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <DepositWithdrawFilterPanel
                coins={coins}
                fromDate={fromDate}
                toDate={toDate}
                selectedCoin={selectedCoin}
                onChangeFromDate={onChangeFromDate}
                onChangeToDate={onChangeToDate}
                onCoinSelectionChange={onCoinSelectionChange}
              />
              <WithdrawTable
                data={withdrawData}
                loading={loadingWithdrawData}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <TradeFilterPanel
                coins={coins}
                fromDate={fromDate}
                toDate={toDate}
                selectedSellCoin={selectedSellCoin}
                selectedBuyCoin={selectedBuyCoin}
                onChangeFromDate={onChangeFromDate}
                onChangeToDate={onChangeToDate}
                onSellCoinSelectionChange={onSellCoinSelectionChange}
                onBuyCoinSelectionChange={onBuyCoinSelectionChange}
              />
              <TradeTab data={tradeData} loading={loadingTradeData} />
            </TabPanel>
          </Box>
        </ThemeProvider>
      </div>
    </div>
  );
};

export default TradingHistory;
