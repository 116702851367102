
import { useContext } from "react";
import { ThemeContext } from "ThemeContext";
import AddBalanceTop from "./AddBalanceTop";
import AddBalanceBottom from "./AddBalanceBottom";

const AddBalance = () => {
  const { theme } = useContext(ThemeContext);
  return (
    <div
      className={`w-full h-[95%] inline-block dark font-bold rounded-2xl text-xs leading-normal transition duration-150 ease-in-out ${
        theme === "dark" ? "bg-[#263238]" : "bg-[#EBF5FB]"
      } dark:text-gray p-8 text-[#128FC8] transition-all overflow-auto`}
    >
      <div
        className={`flex flex-col justify-between w-full ${
          theme === "dark" ? "bg-[#263238]" : "bg-[#EBF5FB]"
        }`}
      >
        <AddBalanceTop />
      </div>
      <div className="flex flex-col justify-between w-full">
        <AddBalanceBottom />
      </div>
    </div>
  );
};

export default AddBalance;
