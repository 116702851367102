const MetaMaskIcon = ({ isDarkMode }) => {
  return (
    <>
      {isDarkMode ? (
        <svg
          width="49"
          height="48"
          viewBox="0 0 49 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.8893 38.1375C23.8893 37.7822 24.1827 37.4941 24.5446 37.4941H27.509C27.8184 37.4941 28.0856 37.7066 28.15 38.0037L29.1014 42.3981C29.1425 42.588 29.0941 42.7859 28.9697 42.9368C28.8453 43.0877 28.6582 43.1753 28.4605 43.1753H24.0898C23.7279 43.1753 23.4346 42.8872 23.4346 42.5319C23.4346 42.1766 23.7279 41.8885 24.0898 41.8885H27.6512L26.9784 38.7809H24.5446C24.1827 38.7809 23.8893 38.4929 23.8893 38.1375Z"
            fill="#0084C9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M46.242 0.626909C46.401 0.701909 46.5221 0.839749 46.5769 1.00784L48.9683 8.34974C49.006 8.46539 49.0103 8.58945 48.9806 8.70746L46.8303 17.2714L47.0431 21.5577C47.05 21.6969 47.0118 21.8345 46.9344 21.9498L45.6887 23.8032L48.54 33.7965C48.5708 33.9043 48.573 34.0184 48.5464 34.1273L45.8838 45.0304C45.8419 45.2021 45.7316 45.3487 45.579 45.4356C45.4264 45.5226 45.2449 45.5422 45.0775 45.4899L34.5565 42.2014L29.0117 47.2697C28.8946 47.3767 28.7423 47.436 28.5843 47.436H24.7814C24.4299 47.436 24.145 47.1487 24.145 46.7943C24.145 46.4399 24.4299 46.1526 24.7814 46.1526H28.3388L33.9707 41.0047C34.1374 40.8523 34.3716 40.8 34.5865 40.8672L44.8094 44.0625L47.1127 34.6308L33.408 34.8852L28.0239 38.6649C27.8274 38.8028 27.5708 38.8179 27.3598 38.7039C27.1487 38.5899 27.019 38.3661 27.0241 38.1248L27.5128 14.7272C27.5144 14.6496 27.53 14.5729 27.5588 14.5009L30.6019 6.88567C30.6669 6.72278 30.7954 6.59391 30.9573 6.5291L45.7374 0.611697C45.9005 0.546404 46.0831 0.551909 46.242 0.626909ZM47.0876 33.3477L33.1913 33.6057C33.0653 33.608 32.9428 33.648 32.8394 33.7206L28.3228 36.8913L28.7828 14.8716L31.6804 7.62036L45.5787 2.05604L47.7015 8.57313L45.573 17.0498C45.5575 17.1117 45.5512 17.1756 45.5543 17.2394L45.7613 21.4075L44.468 23.3319C44.3621 23.4895 44.3311 23.6864 44.3833 23.8693L47.0876 33.3477Z"
            fill="#0084C9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M34.1124 20.2636C34.2094 19.8771 34.5545 19.6562 34.8832 19.7703L45.0041 23.2816C45.3328 23.3956 45.5207 23.8014 45.4237 24.1879C45.3267 24.5744 44.9816 24.7953 44.6529 24.6812L34.532 21.1699C34.2033 21.0558 34.0155 20.6501 34.1124 20.2636Z"
            fill="#0084C9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M48.2815 16.6593C48.3346 16.9671 48.1264 17.2593 47.8165 17.312L46.1149 17.6015C45.8049 17.6543 45.5106 17.4476 45.4575 17.1399C45.4044 16.8321 45.6126 16.5399 45.9225 16.4872L47.6241 16.1977C47.9341 16.1449 48.2284 16.3516 48.2815 16.6593Z"
            fill="#0084C9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M48.2854 19.1207C48.3247 19.6196 48.0941 20.0732 47.7703 20.1337L46.111 20.444C45.7872 20.5046 45.4929 20.1492 45.4536 19.6503C45.4143 19.1514 45.6449 18.6979 45.9687 18.6373L47.628 18.327C47.9518 18.2665 48.2461 18.6218 48.2854 19.1207Z"
            fill="#0084C9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M38.3475 25.3012C38.3553 25.6345 38.0774 25.9107 37.7269 25.9181L27.6343 26.1311C27.2838 26.1385 26.9933 25.8743 26.9855 25.541C26.9777 25.2077 27.2556 24.9315 27.6061 24.9241L37.6987 24.7111C38.0493 24.7037 38.3397 24.9679 38.3475 25.3012Z"
            fill="#0084C9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M46.0307 0.819866C46.243 1.10046 46.1865 1.49921 45.9046 1.7105L28.6285 14.6591L34.5447 19.9544C34.5782 19.9844 34.6084 20.0179 34.6349 20.0542L38.0725 24.7784C38.2174 24.9776 38.234 25.2421 38.115 25.4577L33.3656 34.0628L34.2768 39.5064L38.6452 33.4602C38.8512 33.1751 39.2505 33.1101 39.537 33.3152C39.8235 33.5203 39.8887 33.9177 39.6827 34.2029L34.4176 41.4901C34.267 41.6987 34.005 41.7965 33.7537 41.7382C33.5024 41.6798 33.311 41.4767 33.2686 41.2233L32.0687 34.0544C32.0451 33.9135 32.0698 33.7688 32.1389 33.6436L36.8001 25.1983L33.6397 20.8551L27.1971 15.0885C27.0551 14.9614 26.9777 14.7778 26.986 14.5879C26.9943 14.3981 27.0874 14.2218 27.24 14.1075L45.1359 0.694374C45.4178 0.483086 45.8184 0.53927 46.0307 0.819866Z"
            fill="#0084C9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.0492 37.8244C27.2005 37.5315 27.5724 37.4109 27.8799 37.555L34.4502 40.6335C34.7577 40.7776 34.8844 41.1318 34.7331 41.4247C34.5818 41.7176 34.2099 41.8383 33.9024 41.6942L27.3321 38.6156C27.0246 38.4716 26.8979 38.1173 27.0492 37.8244Z"
            fill="#0084C9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.8725 27.5936C30.0308 27.5299 30.207 27.5411 30.357 27.6243L33.0574 29.1225C33.2655 29.238 33.3902 29.4731 33.3756 29.7224C33.361 29.9718 33.2099 30.1886 32.99 30.2757L29.2065 31.7739C28.9877 31.8606 28.7415 31.8027 28.5773 31.6259C28.4132 31.4491 28.3615 31.1864 28.4454 30.9544L29.5284 27.9579C29.5892 27.7898 29.7143 27.6573 29.8725 27.5936ZM30.3979 29.0727L30.0265 30.1002L31.3239 29.5865L30.3979 29.0727Z"
            fill="#0084C9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.145 6.95819C24.145 6.56599 24.4347 6.24805 24.792 6.24805H31.3097C31.667 6.24805 31.9566 6.56599 31.9566 6.95819C31.9566 7.35039 31.667 7.66834 31.3097 7.66834H24.792C24.4347 7.66834 24.145 7.35039 24.145 6.95819Z"
            fill="#0084C9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.8029 38.0037C20.864 37.7066 21.1182 37.4941 21.4125 37.4941H24.232C24.5763 37.4941 24.8553 37.7822 24.8553 38.1375C24.8553 38.4929 24.5763 38.7809 24.232 38.7809H21.9172L21.2772 41.8885H23.7272C24.0714 41.8885 24.3505 42.1766 24.3505 42.5319C24.3505 42.8872 24.0714 43.1753 23.7272 43.1753H20.5075C20.3195 43.1753 20.1415 43.0877 20.0232 42.9368C19.9048 42.7859 19.8588 42.588 19.8979 42.3981L20.8029 38.0037Z"
            fill="#0084C9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.75806 0.626909C2.91699 0.551909 3.09957 0.546404 3.26265 0.611696L18.0428 6.5291C18.2047 6.59391 18.3331 6.72278 18.3982 6.88567L21.4413 14.5009C21.4701 14.5729 21.4857 14.6496 21.4873 14.7272L21.976 38.1248C21.9811 38.3661 21.8514 38.5899 21.6403 38.7039C21.4293 38.8179 21.1727 38.8028 20.9762 38.6649L15.5921 34.8852L1.88743 34.6308L4.19066 44.0625L14.4136 40.8672C14.6285 40.8 14.8627 40.8523 15.0294 41.0047L20.6613 46.1526H24.2187C24.5702 46.1526 24.8551 46.4399 24.8551 46.7943C24.8551 47.1487 24.5702 47.436 24.2187 47.436H20.4158C20.2578 47.436 20.1055 47.3767 19.9884 47.2697L14.4436 42.2014L3.9226 45.4899C3.75515 45.5422 3.57371 45.5226 3.42109 45.4356C3.26847 45.3487 3.15819 45.2021 3.11627 45.0304L0.453729 34.1273C0.427126 34.0184 0.429316 33.9043 0.460078 33.7965L3.31134 23.8032L2.06574 21.9498C1.98825 21.8345 1.95007 21.6969 1.95698 21.5577L2.16984 17.2714L0.0194656 8.70746C-0.0101642 8.58945 -0.00589521 8.46539 0.0317743 8.34974L2.42323 1.00784C2.47798 0.839749 2.59914 0.701909 2.75806 0.626909ZM1.91247 33.3477L15.8088 33.6057C15.9348 33.608 16.0573 33.648 16.1607 33.7206L20.6773 36.8913L20.2173 14.8716L17.3197 7.62036L3.42142 2.05604L1.29862 8.57313L3.42708 17.0498C3.44262 17.1117 3.44891 17.1756 3.44575 17.2394L3.23876 21.4075L4.53207 23.3319C4.63797 23.4895 4.66902 23.6864 4.61683 23.8693L1.91247 33.3477Z"
            fill="#0084C9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.8876 20.2636C14.9846 20.6501 14.7967 21.0558 14.468 21.1699L4.3471 24.6812C4.01839 24.7953 3.6733 24.5744 3.57631 24.1879C3.47932 23.8014 3.66717 23.3956 3.99587 23.2816L14.1168 19.7703C14.4455 19.6562 14.7906 19.8771 14.8876 20.2636Z"
            fill="#0084C9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.720304 16.6593C0.786702 16.3516 1.1546 16.1449 1.54202 16.1977L3.66907 16.4872C4.0565 16.5399 4.31674 16.8321 4.25034 17.1399C4.18394 17.4476 3.81605 17.6543 3.42862 17.6015L1.30157 17.312C0.914148 17.2593 0.653905 16.9671 0.720304 16.6593Z"
            fill="#0084C9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.714324 19.1207C0.753627 18.6218 1.04798 18.2665 1.37179 18.327L3.03104 18.6373C3.35485 18.6979 3.58548 19.1514 3.54618 19.6503C3.50688 20.1492 3.21252 20.5046 2.88871 20.444L1.22946 20.1337C0.905656 20.0732 0.675021 19.6196 0.714324 19.1207Z"
            fill="#0084C9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.6525 25.3012C10.6603 24.9679 10.9507 24.7037 11.3013 24.7111L21.3939 24.9241C21.7444 24.9315 22.0223 25.2077 22.0145 25.541C22.0067 25.8743 21.7163 26.1385 21.3657 26.1311L11.2731 25.9181C10.9226 25.9107 10.6447 25.6345 10.6525 25.3012Z"
            fill="#0084C9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.96938 0.819866C3.18166 0.53927 3.58227 0.483086 3.86417 0.694374L21.7601 14.1075C21.9127 14.2218 22.0058 14.3981 22.0141 14.5879C22.0224 14.7778 21.945 14.9614 21.803 15.0885L15.3604 20.8551L12.2 25.1983L16.8612 33.6436C16.9303 33.7688 16.955 33.9135 16.9314 34.0544L15.7315 41.2233C15.6891 41.4767 15.4977 41.6798 15.2464 41.7382C14.995 41.7965 14.7331 41.6987 14.5825 41.4901L9.31743 34.2029C9.1114 33.9177 9.17663 33.5203 9.46312 33.3152C9.74962 33.1101 10.1489 33.1751 10.3549 33.4602L14.7233 39.5064L15.6345 34.0628L10.8851 25.4577C10.7661 25.2421 10.7826 24.9776 10.9276 24.7784L14.3652 20.0542C14.3916 20.0179 14.4219 19.9844 14.4554 19.9544L20.3716 14.6591L3.09546 1.7105C2.81356 1.49921 2.75711 1.10046 2.96938 0.819866Z"
            fill="#0084C9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.9509 37.8244C22.1021 38.1173 21.9755 38.4716 21.668 38.6156L15.0977 41.6942C14.7902 41.8383 14.4183 41.7176 14.267 41.4247C14.1157 41.1318 14.2424 40.7776 14.5499 40.6335L21.1202 37.555C21.4277 37.4109 21.7996 37.5315 21.9509 37.8244Z"
            fill="#0084C9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.1273 27.5936C19.2855 27.6573 19.4107 27.7898 19.4714 27.9579L20.5544 30.9544C20.6383 31.1864 20.5867 31.4491 20.4225 31.6259C20.2583 31.8027 20.0121 31.8606 19.7933 31.7739L16.0098 30.2757C15.7899 30.1886 15.6388 29.9718 15.6242 29.7224C15.6096 29.4731 15.7343 29.238 15.9424 29.1225L18.6429 27.6243C18.7929 27.5411 18.9691 27.5299 19.1273 27.5936ZM17.6759 29.5865L18.9733 30.1002L18.6019 29.0727L17.6759 29.5865Z"
            fill="#0084C9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.0435 6.95819C17.0435 6.56599 17.3331 6.24805 17.6904 6.24805H24.2081C24.5654 6.24805 24.8551 6.56599 24.8551 6.95819C24.8551 7.35039 24.5654 7.66834 24.2081 7.66834H17.6904C17.3331 7.66834 17.0435 7.35039 17.0435 6.95819Z"
            fill="#0084C9"
          />
        </svg>
      ) : (
        <svg
          width="49"
          height="48"
          viewBox="0 0 49 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.8893 38.1375C23.8893 37.7822 24.1827 37.4941 24.5446 37.4941H27.509C27.8184 37.4941 28.0856 37.7066 28.15 38.0037L29.1014 42.3981C29.1425 42.588 29.0941 42.7859 28.9697 42.9368C28.8453 43.0877 28.6582 43.1753 28.4605 43.1753H24.0898C23.7279 43.1753 23.4346 42.8872 23.4346 42.5319C23.4346 42.1766 23.7279 41.8885 24.0898 41.8885H27.6512L26.9784 38.7809H24.5446C24.1827 38.7809 23.8893 38.4929 23.8893 38.1375Z"
            fill="#0084C9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M46.242 0.626909C46.401 0.701909 46.5221 0.839749 46.5769 1.00784L48.9683 8.34974C49.006 8.46539 49.0103 8.58945 48.9806 8.70746L46.8303 17.2714L47.0431 21.5577C47.05 21.6969 47.0118 21.8345 46.9344 21.9498L45.6887 23.8032L48.54 33.7965C48.5708 33.9043 48.573 34.0184 48.5464 34.1273L45.8838 45.0304C45.8419 45.2021 45.7316 45.3487 45.579 45.4356C45.4264 45.5226 45.2449 45.5422 45.0775 45.4899L34.5565 42.2014L29.0117 47.2697C28.8946 47.3767 28.7423 47.436 28.5843 47.436H24.7814C24.4299 47.436 24.145 47.1487 24.145 46.7943C24.145 46.4399 24.4299 46.1526 24.7814 46.1526H28.3388L33.9707 41.0047C34.1374 40.8523 34.3716 40.8 34.5865 40.8672L44.8094 44.0625L47.1127 34.6308L33.408 34.8852L28.0239 38.6649C27.8274 38.8028 27.5708 38.8179 27.3598 38.7039C27.1487 38.5899 27.019 38.3661 27.0241 38.1248L27.5128 14.7272C27.5144 14.6496 27.53 14.5729 27.5588 14.5009L30.6019 6.88567C30.6669 6.72278 30.7954 6.59391 30.9573 6.5291L45.7374 0.611697C45.9005 0.546404 46.0831 0.551909 46.242 0.626909ZM47.0876 33.3477L33.1913 33.6057C33.0653 33.608 32.9428 33.648 32.8394 33.7206L28.3228 36.8913L28.7828 14.8716L31.6804 7.62036L45.5787 2.05604L47.7015 8.57313L45.573 17.0498C45.5575 17.1117 45.5512 17.1756 45.5543 17.2394L45.7613 21.4075L44.468 23.3319C44.3621 23.4895 44.3311 23.6864 44.3833 23.8693L47.0876 33.3477Z"
            fill="#0084C9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M34.1124 20.2636C34.2094 19.8771 34.5545 19.6562 34.8832 19.7703L45.0041 23.2816C45.3328 23.3956 45.5207 23.8014 45.4237 24.1879C45.3267 24.5744 44.9816 24.7953 44.6529 24.6812L34.532 21.1699C34.2033 21.0558 34.0155 20.6501 34.1124 20.2636Z"
            fill="#0084C9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M48.2815 16.6593C48.3346 16.9671 48.1264 17.2593 47.8165 17.312L46.1149 17.6015C45.8049 17.6543 45.5106 17.4476 45.4575 17.1399C45.4044 16.8321 45.6126 16.5399 45.9225 16.4872L47.6241 16.1977C47.9341 16.1449 48.2284 16.3516 48.2815 16.6593Z"
            fill="#0084C9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M48.2854 19.1207C48.3247 19.6196 48.0941 20.0732 47.7703 20.1337L46.111 20.444C45.7872 20.5046 45.4929 20.1492 45.4536 19.6503C45.4143 19.1514 45.6449 18.6979 45.9687 18.6373L47.628 18.327C47.9518 18.2665 48.2461 18.6218 48.2854 19.1207Z"
            fill="#0084C9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M38.3475 25.3012C38.3553 25.6345 38.0774 25.9107 37.7269 25.9181L27.6343 26.1311C27.2838 26.1385 26.9933 25.8743 26.9855 25.541C26.9777 25.2077 27.2556 24.9315 27.6061 24.9241L37.6987 24.7111C38.0493 24.7037 38.3397 24.9679 38.3475 25.3012Z"
            fill="#0084C9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M46.0307 0.819866C46.243 1.10046 46.1865 1.49921 45.9046 1.7105L28.6285 14.6591L34.5447 19.9544C34.5782 19.9844 34.6084 20.0179 34.6349 20.0542L38.0725 24.7784C38.2174 24.9776 38.234 25.2421 38.115 25.4577L33.3656 34.0628L34.2768 39.5064L38.6452 33.4602C38.8512 33.1751 39.2505 33.1101 39.537 33.3152C39.8235 33.5203 39.8887 33.9177 39.6827 34.2029L34.4176 41.4901C34.267 41.6987 34.005 41.7965 33.7537 41.7382C33.5024 41.6798 33.311 41.4767 33.2686 41.2233L32.0687 34.0544C32.0451 33.9135 32.0698 33.7688 32.1389 33.6436L36.8001 25.1983L33.6397 20.8551L27.1971 15.0885C27.0551 14.9614 26.9777 14.7778 26.986 14.5879C26.9943 14.3981 27.0874 14.2218 27.24 14.1075L45.1359 0.694374C45.4178 0.483086 45.8184 0.53927 46.0307 0.819866Z"
            fill="#0084C9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.0492 37.8244C27.2005 37.5315 27.5724 37.4109 27.8799 37.555L34.4502 40.6335C34.7577 40.7776 34.8844 41.1318 34.7331 41.4247C34.5818 41.7176 34.2099 41.8383 33.9024 41.6942L27.3321 38.6156C27.0246 38.4716 26.8979 38.1173 27.0492 37.8244Z"
            fill="#0084C9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.8725 27.5936C30.0308 27.5299 30.207 27.5411 30.357 27.6243L33.0574 29.1225C33.2655 29.238 33.3902 29.4731 33.3756 29.7224C33.361 29.9718 33.2099 30.1886 32.99 30.2757L29.2065 31.7739C28.9877 31.8606 28.7415 31.8027 28.5773 31.6259C28.4132 31.4491 28.3615 31.1864 28.4454 30.9544L29.5284 27.9579C29.5892 27.7898 29.7143 27.6573 29.8725 27.5936ZM30.3979 29.0727L30.0265 30.1002L31.3239 29.5865L30.3979 29.0727Z"
            fill="#0084C9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.145 6.95819C24.145 6.56599 24.4347 6.24805 24.792 6.24805H31.3097C31.667 6.24805 31.9566 6.56599 31.9566 6.95819C31.9566 7.35039 31.667 7.66834 31.3097 7.66834H24.792C24.4347 7.66834 24.145 7.35039 24.145 6.95819Z"
            fill="#0084C9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.8029 38.0037C20.864 37.7066 21.1182 37.4941 21.4125 37.4941H24.232C24.5763 37.4941 24.8553 37.7822 24.8553 38.1375C24.8553 38.4929 24.5763 38.7809 24.232 38.7809H21.9172L21.2772 41.8885H23.7272C24.0714 41.8885 24.3505 42.1766 24.3505 42.5319C24.3505 42.8872 24.0714 43.1753 23.7272 43.1753H20.5075C20.3195 43.1753 20.1415 43.0877 20.0232 42.9368C19.9048 42.7859 19.8588 42.588 19.8979 42.3981L20.8029 38.0037Z"
            fill="#0084C9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.75806 0.626909C2.91699 0.551909 3.09957 0.546404 3.26265 0.611696L18.0428 6.5291C18.2047 6.59391 18.3331 6.72278 18.3982 6.88567L21.4413 14.5009C21.4701 14.5729 21.4857 14.6496 21.4873 14.7272L21.976 38.1248C21.9811 38.3661 21.8514 38.5899 21.6403 38.7039C21.4293 38.8179 21.1727 38.8028 20.9762 38.6649L15.5921 34.8852L1.88743 34.6308L4.19066 44.0625L14.4136 40.8672C14.6285 40.8 14.8627 40.8523 15.0294 41.0047L20.6613 46.1526H24.2187C24.5702 46.1526 24.8551 46.4399 24.8551 46.7943C24.8551 47.1487 24.5702 47.436 24.2187 47.436H20.4158C20.2578 47.436 20.1055 47.3767 19.9884 47.2697L14.4436 42.2014L3.9226 45.4899C3.75515 45.5422 3.57371 45.5226 3.42109 45.4356C3.26847 45.3487 3.15819 45.2021 3.11627 45.0304L0.453729 34.1273C0.427126 34.0184 0.429316 33.9043 0.460078 33.7965L3.31134 23.8032L2.06574 21.9498C1.98825 21.8345 1.95007 21.6969 1.95698 21.5577L2.16984 17.2714L0.0194656 8.70746C-0.0101642 8.58945 -0.00589521 8.46539 0.0317743 8.34974L2.42323 1.00784C2.47798 0.839749 2.59914 0.701909 2.75806 0.626909ZM1.91247 33.3477L15.8088 33.6057C15.9348 33.608 16.0573 33.648 16.1607 33.7206L20.6773 36.8913L20.2173 14.8716L17.3197 7.62036L3.42142 2.05604L1.29862 8.57313L3.42708 17.0498C3.44262 17.1117 3.44891 17.1756 3.44575 17.2394L3.23876 21.4075L4.53207 23.3319C4.63797 23.4895 4.66902 23.6864 4.61683 23.8693L1.91247 33.3477Z"
            fill="#0084C9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.8876 20.2636C14.9846 20.6501 14.7967 21.0558 14.468 21.1699L4.3471 24.6812C4.01839 24.7953 3.6733 24.5744 3.57631 24.1879C3.47932 23.8014 3.66717 23.3956 3.99587 23.2816L14.1168 19.7703C14.4455 19.6562 14.7906 19.8771 14.8876 20.2636Z"
            fill="#0084C9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.720304 16.6593C0.786702 16.3516 1.1546 16.1449 1.54202 16.1977L3.66907 16.4872C4.0565 16.5399 4.31674 16.8321 4.25034 17.1399C4.18394 17.4476 3.81605 17.6543 3.42862 17.6015L1.30157 17.312C0.914148 17.2593 0.653905 16.9671 0.720304 16.6593Z"
            fill="#0084C9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.714324 19.1207C0.753627 18.6218 1.04798 18.2665 1.37179 18.327L3.03104 18.6373C3.35485 18.6979 3.58548 19.1514 3.54618 19.6503C3.50688 20.1492 3.21252 20.5046 2.88871 20.444L1.22946 20.1337C0.905656 20.0732 0.675021 19.6196 0.714324 19.1207Z"
            fill="#0084C9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.6525 25.3012C10.6603 24.9679 10.9507 24.7037 11.3013 24.7111L21.3939 24.9241C21.7444 24.9315 22.0223 25.2077 22.0145 25.541C22.0067 25.8743 21.7163 26.1385 21.3657 26.1311L11.2731 25.9181C10.9226 25.9107 10.6447 25.6345 10.6525 25.3012Z"
            fill="#0084C9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.96938 0.819866C3.18166 0.53927 3.58227 0.483086 3.86417 0.694374L21.7601 14.1075C21.9127 14.2218 22.0058 14.3981 22.0141 14.5879C22.0224 14.7778 21.945 14.9614 21.803 15.0885L15.3604 20.8551L12.2 25.1983L16.8612 33.6436C16.9303 33.7688 16.955 33.9135 16.9314 34.0544L15.7315 41.2233C15.6891 41.4767 15.4977 41.6798 15.2464 41.7382C14.995 41.7965 14.7331 41.6987 14.5825 41.4901L9.31743 34.2029C9.1114 33.9177 9.17663 33.5203 9.46312 33.3152C9.74962 33.1101 10.1489 33.1751 10.3549 33.4602L14.7233 39.5064L15.6345 34.0628L10.8851 25.4577C10.7661 25.2421 10.7826 24.9776 10.9276 24.7784L14.3652 20.0542C14.3916 20.0179 14.4219 19.9844 14.4554 19.9544L20.3716 14.6591L3.09546 1.7105C2.81356 1.49921 2.75711 1.10046 2.96938 0.819866Z"
            fill="#0084C9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.9509 37.8244C22.1021 38.1173 21.9755 38.4716 21.668 38.6156L15.0977 41.6942C14.7902 41.8383 14.4183 41.7176 14.267 41.4247C14.1157 41.1318 14.2424 40.7776 14.5499 40.6335L21.1202 37.555C21.4277 37.4109 21.7996 37.5315 21.9509 37.8244Z"
            fill="#0084C9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.1273 27.5936C19.2855 27.6573 19.4107 27.7898 19.4714 27.9579L20.5544 30.9544C20.6383 31.1864 20.5867 31.4491 20.4225 31.6259C20.2583 31.8027 20.0121 31.8606 19.7933 31.7739L16.0098 30.2757C15.7899 30.1886 15.6388 29.9718 15.6242 29.7224C15.6096 29.4731 15.7343 29.238 15.9424 29.1225L18.6429 27.6243C18.7929 27.5411 18.9691 27.5299 19.1273 27.5936ZM17.6759 29.5865L18.9733 30.1002L18.6019 29.0727L17.6759 29.5865Z"
            fill="#0084C9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.0435 6.95819C17.0435 6.56599 17.3331 6.24805 17.6904 6.24805H24.2081C24.5654 6.24805 24.8551 6.56599 24.8551 6.95819C24.8551 7.35039 24.5654 7.66834 24.2081 7.66834H17.6904C17.3331 7.66834 17.0435 7.35039 17.0435 6.95819Z"
            fill="#0084C9"
          />
        </svg>
      )}
    </>
  );
};

export default MetaMaskIcon;
