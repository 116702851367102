import { useContext, useState } from "react";
import { SpinningCircles } from "react-loading-icons";
import { formattedSpecifiedDate } from "utils/dateFormat";
import * as OTCAPI from "api/otc";
import { useUserData } from "UserDataContext";
import ButtonGreen from "components/Input/Button_green";
import useToast from "hooks/useToast";
import { ThemeContext } from "ThemeContext";

const TileAndSearchBar = ({
  isDarkMode = false,
  titleIcon = "",
  title = "",
  total = 0,
  onChange = "",
  ...props
}) => {
  const [value, setValue] = useState("");
  return (
    <div
      {...props}
      className={`flex justify-between items-center ${props.className}`}
    >
      <span>
        <span>{titleIcon}</span>
        <span
          className={`font-semibold text-[14px] ${
            isDarkMode ? "text-white" : ""
          }`}
        >
          {title}
        </span>
      </span>
      <span>
        <span className="text-gray-700">Search: </span>
        <input
          type="text"
          className={`p-3 border border-[#6E7A8A] shadow-sm rounded-xl focus:border-indigo-300 ${
            isDarkMode ? "bg-black" : ""
          }`}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`${total} records...`}
        />
      </span>
    </div>
  );
};

const Deposit = ({
  data = { payMethod: "", amount: 0, price: 0, icon_url: "" },
}) => {
  return (
    <div>
      <div className="flex items-center">
        <span className="min-w-[30px]">
          <img
            src={data.icon_url}
            alt="icon"
            className="mr-2"
            width={30}
            height={30}
          />
        </span>
        <span className="ml-2">
          <div>{data.payMethod}</div>
          <div>{`${Number(data.amount).toFixed(4)} ($${Number(
            data.price * data.amount
          ).toFixed(2)})`}</div>
        </span>
      </div>
    </div>
  );
};
const CreditItem = ({
  type = "",
  price = 0,
  amount = 0,
  icon_url = "",
  ...props
}) => {
  return (
    <div {...props} className={`flex items-center ${props.className}`}>
      <span className="min-w-[30px]">
        <img width={30} height={30} src={icon_url} alt="icon" />
      </span>
      <span className="ml-2">
        <div>{type}</div>
        <span>{amount}</span>
        <span className="ml-2">(${price * amount})</span>
      </span>
    </div>
  );
};

const Credit = ({
  data = {
    useu: { amount: 0, price: 0, icon_url: "" },
    euus: { amount: 0, price: 0, icon_url: "" },
  },
}) => {
  return (
    <div>
      {data.euus.amount > 0 ? (
        <CreditItem
          type="EUUS"
          price={Number(data.euus.price).toFixed(2)}
          amount={Number(data.euus.amount).toFixed(4)}
          icon_url={data.euus.icon_url}
        />
      ) : null}
      {data.useu.amount > 0 ? (
        <CreditItem
          type="USEU"
          price={Number(data.useu.price).toFixed(2)}
          amount={Number(data.useu.amount).toFixed(4)}
          icon_url={data.useu.icon_url}
          className="mb-2"
        />
      ) : null}
    </div>
  );
};

const Status = ({ data = "" }) => {
  return <div>{data}</div>;
};

export default function OTCStatusTable({
  data = [],
  loading = true,
  refreshCallback = () => {},
  ...props
}) {
  const { theme } = useContext(ThemeContext);
  const { userData } = useUserData();
  const { showToast } = useToast();
  const [btnDisabled, setBtnDisabled] = useState(false);

  const handleClaimClick = (orderId) => {
    console.log(orderId);
    const payload = {
      userId: userData.id,
      orderId: orderId,
    };
    setBtnDisabled(true);
    OTCAPI.claimOrder(payload)
      .then((res) => {
        console.log(res);
        showToast(
          "Success to receive Nexus tokens, please check your balance on My balance page.",
          "success"
        );
        refreshCallback();
      })
      .catch((error) => {
        showToast(error.response.data.message, "error");
      })
      .finally(() => {
        setBtnDisabled(false);
      });
  };

  return (
    <div>
      <TileAndSearchBar
        isDarkMode={theme === "dark"}
        title="OTC status"
        className="mb-6"
        total={data.length}
      />
      <div className="overflow-y-hidden rounded-2xl">
        <table
          className={`min-w-full text-xs font-medium table-fixed ${
            theme === "dark" ? "text-white" : ""
          }`}
        >
          <thead
            className={`text-left ${
              theme === "dark" ? "text-white bg-[#181F23]" : ""
            }`}
          >
            <th className="p-6">CREATED DATE</th>
            <th>ORDER ID</th>
            <th>DEPOSIT</th>
            <th>CREDIT</th>
            <th>STATUS</th>
            <th>UNLOCK DATE</th>
            <th>UNLOCK</th>
          </thead>
          {loading ? null : (
            <tbody className="divide-[#263238] divide-y">
              {data.map((item, i) => {
                const canWithdraw = item.status === "UnLocked";
                return (
                  <tr className="text-white" key={i}>
                    <td className="px-6 py-10 whitespace-nowrap">
                      <span>{formattedSpecifiedDate(item.createdDate)}</span>
                    </td>
                    <td>{item.order_id}</td>
                    <td>
                      <Deposit data={item.deposit} />
                    </td>
                    <td>
                      <Credit data={item.credit} />
                    </td>
                    <td>
                      <Status data={item.status} />
                    </td>
                    <td>
                      {item.status !== "Closed"
                        ? formattedSpecifiedDate(item.unlockDate)
                        : ""}
                    </td>
                    <td className="pr-8 py-10">
                      {canWithdraw ? (
                        <ButtonGreen
                          label={"Unlock"}
                          disabled={btnDisabled}
                          onClickHandle={() => handleClaimClick(item.order_id)}
                        />
                      ) : null}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
        {loading ? (
          <div className="flex justify-center mt-3">
            <SpinningCircles width={30} height={30} />
          </div>
        ) : null}
      </div>
    </div>
  );
}
