import { getDefaultConfig } from "@rainbow-me/rainbowkit";
import { constants } from "constants";
import { mainnet } from "wagmi/chains";

// const projectId = "4f540cadece68427ad9a3aadd2158678";

export const config = getDefaultConfig({
  appName: "Nexus",
  projectId: constants.PROJECT_ID,
  chains: [mainnet],
  ssr: true, // If your dApp uses server side rendering (SSR)
});
