import ReceiptItem from "./ReceiptItem";

export default function Receipt({ receipts = [], ...props }) {
  return (
    <div className={`${props.className}`}>
      {receipts.map((receipt) => (
        <ReceiptItem
          title={receipt.title}
          value={receipt.value}
          key={receipt.title}
        />
      ))}
    </div>
  );
}
