const EUUSIcon = ({ isDarkMode }) => {
  return (
    <>
      {isDarkMode ? (
        <svg
          width="70"
          height="69"
          viewBox="0 0 70 69"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_735_10794)">
            <path
              d="M35 69C54.33 69 70 53.5538 70 34.5C70 15.4462 54.33 0 35 0C15.67 0 0 15.4462 0 34.5C0 53.5538 15.67 69 35 69Z"
              fill="url(#paint0_linear_735_10794)"
            />
            <path
              d="M35.0002 67.9279C53.7297 67.9279 68.913 52.9615 68.913 34.4996C68.913 16.0377 53.7297 1.07129 35.0002 1.07129C16.2707 1.07129 1.0874 16.0377 1.0874 34.4996C1.0874 52.9615 16.2707 67.9279 35.0002 67.9279Z"
              fill="url(#paint1_linear_735_10794)"
            />
            <path
              d="M34.9998 64.5486C51.8355 64.5486 65.4835 51.0956 65.4835 34.5004C65.4835 17.9052 51.8355 4.45215 34.9998 4.45215C18.1641 4.45215 4.51611 17.9052 4.51611 34.5004C4.51611 51.0956 18.1641 64.5486 34.9998 64.5486Z"
              fill="url(#paint2_radial_735_10794)"
            />
            <g filter="url(#filter0_d_735_10794)">
              <path
                d="M30.1222 9.95418L30.2067 8.00684C28.2461 9.5817 24.2983 12.7543 22.3376 14.3308C24.8634 14.1919 29.9482 13.9093 32.474 13.7688L31.0603 12.4259C34.5938 11.7708 38.2996 11.9522 41.7602 12.9765C54.1074 16.6212 61.0717 29.6057 57.1586 41.7308C52.518 56.1889 35.018 62.3789 22.0393 54.2464C19.5284 52.6945 17.3308 50.6605 15.5524 48.3195C15.5276 48.2868 15.4795 48.2786 15.4447 48.3031C15.4099 48.3276 15.4016 48.375 15.4265 48.4093C16.9976 50.5935 18.9517 52.5327 21.1626 54.0978C29.8322 60.2764 42.0121 59.9888 50.4016 53.41C55.6654 49.3716 59.1657 43.1064 59.7474 36.5308C61.3451 20.2071 46.2599 6.98252 30.1255 9.95581L30.1222 9.95418Z"
                fill="url(#paint3_linear_735_10794)"
              />
            </g>
            <g filter="url(#filter1_d_735_10794)">
              <path
                d="M36.0011 44.9032C34.2857 46.2625 31.75 47.3292 28.7667 47.3292C23.5096 47.3292 18.8475 44.4981 17.0575 39.5366H13.5903V36.0814H16.3117C16.2752 35.4197 16.2752 35.2351 16.2752 34.9051C16.2752 34.6111 16.2752 34.317 16.3117 33.7289H13.5903V30.2737H17.0575C18.8475 25.2746 23.6206 22.334 28.7667 22.334C31.4881 22.334 33.5034 22.958 35.1807 24.0248L33.2797 27.8852C32.1229 27.1125 30.5583 26.7449 28.7667 26.7449C26.231 26.7449 23.8079 27.8476 22.4655 30.2737H32.161L30.5202 33.7289H21.4959C21.4213 34.2435 21.4213 34.5376 21.4213 34.9427C21.4213 35.1633 21.4213 35.4197 21.4959 36.0814H29.5507L27.948 39.5366H22.5782C23.8842 41.7796 26.1962 42.8448 28.7684 42.8448C30.7821 42.8448 32.4974 42.2567 33.6907 41.265L36.0027 44.9049L36.0011 44.9032Z"
                fill="#141413"
              />
              <path
                d="M42.942 45.7235V43.0851C39.4152 42.5182 37.8142 39.9534 37.6401 37.9325L41.0924 37.1679C41.2167 38.5974 42.2177 39.8798 44.369 39.8798C45.6203 39.8798 46.5202 39.2885 46.5202 38.3262C46.5202 37.6107 45.97 37.1434 44.969 36.9213L42.7431 36.4034C39.9422 35.7368 38.2169 34.0607 38.2169 31.7196C38.2169 28.9587 40.3184 27.0604 42.9437 26.616V23.9531H45.8192V26.6895C48.5704 27.2809 49.8217 29.2283 50.1714 30.9061L46.6943 31.8176C46.5434 31.0531 46.1192 29.7723 44.1436 29.7723C42.7431 29.7723 42.0421 30.5859 42.0421 31.3749C42.0421 32.0398 42.5426 32.5577 43.5171 32.7799L45.6932 33.2488C49.0444 33.9888 50.3703 35.9116 50.3703 38.1302C50.3703 40.3487 48.7196 42.5182 45.8175 43.0361V45.7235H42.942Z"
                fill="#141413"
              />
            </g>
            <path
              d="M43.2853 45.3856V42.7472C39.7584 42.1803 38.1574 39.6155 37.9834 37.5946L41.4357 36.8301C41.56 38.2595 42.561 39.542 44.7123 39.542C45.9636 39.542 46.8635 38.9506 46.8635 37.9883C46.8635 37.2728 46.3133 36.8055 45.3122 36.5834L43.0864 36.0655C40.2855 35.3989 38.5602 33.7228 38.5602 31.3817C38.5602 28.6208 40.6617 26.7225 43.2869 26.2781V23.6152H46.1624V26.3516C48.9136 26.943 50.165 28.8904 50.5147 30.5682L47.0375 31.4798C46.8867 30.7152 46.4624 29.4344 44.4869 29.4344C43.0864 29.4344 42.3853 30.248 42.3853 31.037C42.3853 31.7019 42.8859 32.2198 43.8604 32.442L46.0365 32.9109C49.3877 33.6509 50.7135 35.5738 50.7135 37.7923C50.7135 40.0108 49.0628 42.1803 46.1608 42.6982V45.3856H43.2853Z"
              fill="url(#paint4_linear_735_10794)"
            />
            <path
              d="M36.3443 44.5653C34.629 45.9246 32.0932 46.9914 29.11 46.9914C23.8529 46.9914 19.1907 44.1602 17.4008 39.1987H13.9336V35.7435H16.655C16.6185 35.0818 16.6185 34.8972 16.6185 34.5672C16.6185 34.2732 16.6185 33.9791 16.655 33.391H13.9336V29.9358H17.4008C19.1907 24.9367 23.9639 21.9961 29.11 21.9961C31.8314 21.9961 33.8467 22.6202 35.5239 23.687L33.623 27.5473C32.4661 26.7746 30.9016 26.407 29.11 26.407C26.5742 26.407 24.1512 27.5098 22.8087 29.9358H32.5042L30.8635 33.391H21.8392C21.7646 33.9056 21.7646 34.1997 21.7646 34.6048C21.7646 34.8254 21.7646 35.0818 21.8392 35.7435H29.8939L28.2913 39.1987H22.9214C24.2274 41.4418 26.5394 42.5069 29.1116 42.5069C31.1253 42.5069 32.8407 41.9188 34.034 40.9271L36.346 44.567L36.3443 44.5653Z"
              fill="url(#paint5_linear_735_10794)"
            />
            <path
              d="M34.032 40.9252C32.8387 41.9185 31.1234 42.505 29.1097 42.505C26.5375 42.505 24.2255 41.4398 22.9195 39.1968H28.2893L29.892 35.7415H21.8372C21.7626 35.0799 21.7626 34.8218 21.7626 34.6029C21.7626 34.1977 21.7626 33.9036 21.8372 33.389H30.8615L32.5023 29.9338H22.8068C24.1492 27.5078 26.5723 26.4051 29.108 26.4051C30.898 26.4051 32.4642 26.7726 33.621 27.5454L35.522 23.685C33.8447 22.6198 31.8294 21.9941 29.108 21.9941C23.9619 21.9941 19.1888 24.9348 17.3988 29.9338H13.9316V33.389H16.653C16.6166 33.9772 16.6166 34.2712 16.6166 34.5653C16.6166 34.8953 16.6166 35.0799 16.653 35.7415H13.9316V39.1968H17.3988C19.1888 44.1582 23.8492 46.9894 29.108 46.9894C32.0913 46.9894 34.627 45.9226 36.3424 44.5634L34.0304 40.9236L34.032 40.9252ZM17.7137 39.0889L17.6358 38.8717H14.2648V36.0699H17.006L16.9861 35.7252C16.9497 35.0717 16.9497 34.8937 16.9497 34.5669C16.9497 34.2696 16.9497 33.9886 16.9861 33.4103L17.0077 33.0639H14.2648V30.2622H17.6358L17.7137 30.0449C19.3926 25.3546 23.8675 22.3225 29.1097 22.3225C31.4863 22.3225 33.3973 22.7963 35.0961 23.8075L33.4851 27.0749C32.3448 26.4214 30.8433 26.0783 29.1097 26.0783C26.2209 26.0783 23.8178 27.4261 22.5167 29.777L22.2499 30.2605H31.9819L30.651 33.0623H21.5505L21.5091 33.3433C21.4312 33.8808 21.4312 34.1993 21.4312 34.6029C21.4312 34.8234 21.4312 35.0995 21.5074 35.7791L21.5406 36.0699H29.3749L28.0738 38.8717H22.3444L22.6294 39.3618C23.9139 41.5689 26.2756 42.8333 29.1064 42.8333C30.9808 42.8333 32.6829 42.3302 33.9442 41.4088L35.9015 44.4899C34.0403 45.8752 31.5808 46.6659 29.1064 46.6659C23.7614 46.6659 19.3959 43.7629 17.7104 39.0906L17.7137 39.0889Z"
              fill="url(#paint6_linear_735_10794)"
            />
            <path
              d="M50.7135 37.7923C50.7135 35.5738 49.3877 33.6509 46.0365 32.9109L43.8604 32.442C42.8842 32.2198 42.3853 31.7019 42.3853 31.037C42.3853 30.248 43.0864 29.4344 44.4869 29.4344C46.4624 29.4344 46.8884 30.7168 47.0375 31.4798L50.5147 30.5682C50.165 28.892 48.9136 26.9447 46.1624 26.3516V23.6152H43.2869V26.2781C40.66 26.7225 38.5602 28.6208 38.5602 31.3817C38.5602 33.7244 40.2855 35.4006 43.0864 36.0655L45.3122 36.5834C46.3133 36.8055 46.8635 37.2728 46.8635 37.9883C46.8635 38.9506 45.9636 39.542 44.7123 39.542C42.561 39.542 41.56 38.2595 41.4357 36.8301L37.9834 37.5946C38.1591 39.6155 39.7584 42.1803 43.2853 42.7472V45.3856H46.1608V42.6982C49.0628 42.1803 50.7135 40.0598 50.7135 37.7923ZM46.1028 42.3764L45.831 42.4254V45.0589H43.6184V42.4695L43.34 42.4254C40.2623 41.9304 38.643 39.818 38.348 37.8495L41.1572 37.227C41.4141 38.565 42.4549 39.8687 44.7123 39.8687C46.1972 39.8687 47.195 39.1139 47.195 37.9883C47.195 37.3855 46.8817 36.5964 45.3885 36.2648L43.1643 35.7469C40.4479 35.1016 38.8916 33.5104 38.8916 31.3801C38.8916 28.9704 40.6799 27.0492 43.3433 26.5983L43.6184 26.551V23.9387H45.831V26.6114L46.0912 26.6669C48.8971 27.2698 49.8186 29.2449 50.1169 30.3296L47.2812 31.073C46.9083 29.7644 45.9702 29.1028 44.4852 29.1028C42.8875 29.1028 42.0522 30.0732 42.0522 31.0321C42.0522 31.8767 42.6837 32.5057 43.7891 32.7573L45.9636 33.2262C49.6147 34.0316 50.3821 36.1521 50.3821 37.789C50.3821 39.6906 49.0595 41.8454 46.1028 42.3731V42.3764Z"
              fill="url(#paint7_linear_735_10794)"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_735_10794"
              x="8.41211"
              y="3.00684"
              width="56.4517"
              height="62.5381"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="-1" dy="1" />
              <feGaussianBlur stdDeviation="3" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.9 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_735_10794"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_735_10794"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_d_735_10794"
              x="5.59033"
              y="14.334"
              width="52.7798"
              height="40.9951"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="4" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.9 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_735_10794"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_735_10794"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_735_10794"
              x1="35"
              y1="0"
              x2="35"
              y2="69"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#333333" />
              <stop offset="0.25" stopColor="#B8B8B8" />
              <stop offset="0.5" stopColor="#BBBBBA" />
              <stop offset="0.75" stopColor="#D4D4D4" />
              <stop offset="1" stopColor="#2B2B2B" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_735_10794"
              x1="68.913"
              y1="34.4996"
              x2="1.0874"
              y2="34.4996"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#D4D4D4" />
              <stop offset="0.5" stopColor="#4D4D4D" />
              <stop offset="1" stopColor="#7D7D7D" />
            </linearGradient>
            <radialGradient
              id="paint2_radial_735_10794"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(34.9998 34.5004) scale(30.4837 30.0482)"
            >
              <stop offset="0.3" stopColor="#343433" />
              <stop offset="0.66" stopColor="#323231" />
              <stop offset="0.79" stopColor="#2B2B2A" />
              <stop offset="0.88" stopColor="#1F1F1E" />
              <stop offset="0.95" stopColor="#0E0E0E" />
              <stop offset="1" />
            </radialGradient>
            <linearGradient
              id="paint3_linear_735_10794"
              x1="18.5158"
              y1="30.9894"
              x2="59.5125"
              y2="38.3222"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#D4D4D4" />
              <stop offset="0.5" stopColor="#4D4D4D" />
              <stop offset="1" stopColor="#7D7D7D" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_735_10794"
              x1="37.9834"
              y1="34.5004"
              x2="50.7135"
              y2="34.5004"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#4A4A4A" />
              <stop offset="0.25" stopColor="#B8B8B8" />
              <stop offset="0.5" stopColor="#A8A8A7" />
              <stop offset="0.75" stopColor="#CCCCCC" />
              <stop offset="1" stopColor="#404040" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_735_10794"
              x1="13.9336"
              y1="34.4937"
              x2="36.3443"
              y2="34.4937"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#BA9555" />
              <stop offset="0.12" stopColor="#A67124" />
              <stop offset="0.4" stopColor="#FCF6AC" />
              <stop offset="0.67" stopColor="#D3AC53" />
              <stop offset="0.85" stopColor="#BD882C" />
              <stop offset="1" stopColor="#AB6A30" />
            </linearGradient>
            <linearGradient
              id="paint6_linear_735_10794"
              x1="13.9333"
              y1="34.4934"
              x2="36.344"
              y2="34.4934"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#DBA73E" />
              <stop offset="0.16" stopColor="#D89D3A" />
              <stop offset="0.3" stopColor="#A46B28" />
              <stop offset="0.46" stopColor="#8E5723" />
              <stop offset="0.49" stopColor="#F4F3B8" />
              <stop offset="0.56" stopColor="#F7ED9A" />
              <stop offset="0.69" stopColor="#D5B767" />
              <stop offset="0.75" stopColor="#A76F29" />
              <stop offset="0.85" stopColor="#D0AB5B" />
              <stop offset="1" stopColor="#E0C77D" />
            </linearGradient>
            <linearGradient
              id="paint7_linear_735_10794"
              x1="37.9834"
              y1="34.5004"
              x2="50.7135"
              y2="34.5004"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#ABABAB" />
              <stop offset="0.16" stopColor="#A4A4A4" />
              <stop offset="0.3" stopColor="#757575" />
              <stop offset="0.46" stopColor="#525252" />
              <stop offset="0.49" stopColor="#EDEDED" />
              <stop offset="0.56" stopColor="#E7E7E7" />
              <stop offset="0.69" stopColor="#B7B7B7" />
              <stop offset="0.75" stopColor="#666666" />
              <stop offset="0.85" stopColor="#ADADAD" />
              <stop offset="1" stopColor="#C6C6C6" />
            </linearGradient>
            <clipPath id="clip0_735_10794">
              <rect width="70" height="69" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <svg
          width="70"
          height="69"
          viewBox="0 0 70 69"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_735_10794)">
            <path
              d="M35 69C54.33 69 70 53.5538 70 34.5C70 15.4462 54.33 0 35 0C15.67 0 0 15.4462 0 34.5C0 53.5538 15.67 69 35 69Z"
              fill="url(#paint0_linear_735_10794)"
            />
            <path
              d="M35.0002 67.9279C53.7297 67.9279 68.913 52.9615 68.913 34.4996C68.913 16.0377 53.7297 1.07129 35.0002 1.07129C16.2707 1.07129 1.0874 16.0377 1.0874 34.4996C1.0874 52.9615 16.2707 67.9279 35.0002 67.9279Z"
              fill="url(#paint1_linear_735_10794)"
            />
            <path
              d="M34.9998 64.5486C51.8355 64.5486 65.4835 51.0956 65.4835 34.5004C65.4835 17.9052 51.8355 4.45215 34.9998 4.45215C18.1641 4.45215 4.51611 17.9052 4.51611 34.5004C4.51611 51.0956 18.1641 64.5486 34.9998 64.5486Z"
              fill="url(#paint2_radial_735_10794)"
            />
            <g filter="url(#filter0_d_735_10794)">
              <path
                d="M30.1222 9.95418L30.2067 8.00684C28.2461 9.5817 24.2983 12.7543 22.3376 14.3308C24.8634 14.1919 29.9482 13.9093 32.474 13.7688L31.0603 12.4259C34.5938 11.7708 38.2996 11.9522 41.7602 12.9765C54.1074 16.6212 61.0717 29.6057 57.1586 41.7308C52.518 56.1889 35.018 62.3789 22.0393 54.2464C19.5284 52.6945 17.3308 50.6605 15.5524 48.3195C15.5276 48.2868 15.4795 48.2786 15.4447 48.3031C15.4099 48.3276 15.4016 48.375 15.4265 48.4093C16.9976 50.5935 18.9517 52.5327 21.1626 54.0978C29.8322 60.2764 42.0121 59.9888 50.4016 53.41C55.6654 49.3716 59.1657 43.1064 59.7474 36.5308C61.3451 20.2071 46.2599 6.98252 30.1255 9.95581L30.1222 9.95418Z"
                fill="url(#paint3_linear_735_10794)"
              />
            </g>
            <g filter="url(#filter1_d_735_10794)">
              <path
                d="M36.0011 44.9032C34.2857 46.2625 31.75 47.3292 28.7667 47.3292C23.5096 47.3292 18.8475 44.4981 17.0575 39.5366H13.5903V36.0814H16.3117C16.2752 35.4197 16.2752 35.2351 16.2752 34.9051C16.2752 34.6111 16.2752 34.317 16.3117 33.7289H13.5903V30.2737H17.0575C18.8475 25.2746 23.6206 22.334 28.7667 22.334C31.4881 22.334 33.5034 22.958 35.1807 24.0248L33.2797 27.8852C32.1229 27.1125 30.5583 26.7449 28.7667 26.7449C26.231 26.7449 23.8079 27.8476 22.4655 30.2737H32.161L30.5202 33.7289H21.4959C21.4213 34.2435 21.4213 34.5376 21.4213 34.9427C21.4213 35.1633 21.4213 35.4197 21.4959 36.0814H29.5507L27.948 39.5366H22.5782C23.8842 41.7796 26.1962 42.8448 28.7684 42.8448C30.7821 42.8448 32.4974 42.2567 33.6907 41.265L36.0027 44.9049L36.0011 44.9032Z"
                fill="#141413"
              />
              <path
                d="M42.942 45.7235V43.0851C39.4152 42.5182 37.8142 39.9534 37.6401 37.9325L41.0924 37.1679C41.2167 38.5974 42.2177 39.8798 44.369 39.8798C45.6203 39.8798 46.5202 39.2885 46.5202 38.3262C46.5202 37.6107 45.97 37.1434 44.969 36.9213L42.7431 36.4034C39.9422 35.7368 38.2169 34.0607 38.2169 31.7196C38.2169 28.9587 40.3184 27.0604 42.9437 26.616V23.9531H45.8192V26.6895C48.5704 27.2809 49.8217 29.2283 50.1714 30.9061L46.6943 31.8176C46.5434 31.0531 46.1192 29.7723 44.1436 29.7723C42.7431 29.7723 42.0421 30.5859 42.0421 31.3749C42.0421 32.0398 42.5426 32.5577 43.5171 32.7799L45.6932 33.2488C49.0444 33.9888 50.3703 35.9116 50.3703 38.1302C50.3703 40.3487 48.7196 42.5182 45.8175 43.0361V45.7235H42.942Z"
                fill="#141413"
              />
            </g>
            <path
              d="M43.2853 45.3856V42.7472C39.7584 42.1803 38.1574 39.6155 37.9834 37.5946L41.4357 36.8301C41.56 38.2595 42.561 39.542 44.7123 39.542C45.9636 39.542 46.8635 38.9506 46.8635 37.9883C46.8635 37.2728 46.3133 36.8055 45.3122 36.5834L43.0864 36.0655C40.2855 35.3989 38.5602 33.7228 38.5602 31.3817C38.5602 28.6208 40.6617 26.7225 43.2869 26.2781V23.6152H46.1624V26.3516C48.9136 26.943 50.165 28.8904 50.5147 30.5682L47.0375 31.4798C46.8867 30.7152 46.4624 29.4344 44.4869 29.4344C43.0864 29.4344 42.3853 30.248 42.3853 31.037C42.3853 31.7019 42.8859 32.2198 43.8604 32.442L46.0365 32.9109C49.3877 33.6509 50.7135 35.5738 50.7135 37.7923C50.7135 40.0108 49.0628 42.1803 46.1608 42.6982V45.3856H43.2853Z"
              fill="url(#paint4_linear_735_10794)"
            />
            <path
              d="M36.3443 44.5653C34.629 45.9246 32.0932 46.9914 29.11 46.9914C23.8529 46.9914 19.1907 44.1602 17.4008 39.1987H13.9336V35.7435H16.655C16.6185 35.0818 16.6185 34.8972 16.6185 34.5672C16.6185 34.2732 16.6185 33.9791 16.655 33.391H13.9336V29.9358H17.4008C19.1907 24.9367 23.9639 21.9961 29.11 21.9961C31.8314 21.9961 33.8467 22.6202 35.5239 23.687L33.623 27.5473C32.4661 26.7746 30.9016 26.407 29.11 26.407C26.5742 26.407 24.1512 27.5098 22.8087 29.9358H32.5042L30.8635 33.391H21.8392C21.7646 33.9056 21.7646 34.1997 21.7646 34.6048C21.7646 34.8254 21.7646 35.0818 21.8392 35.7435H29.8939L28.2913 39.1987H22.9214C24.2274 41.4418 26.5394 42.5069 29.1116 42.5069C31.1253 42.5069 32.8407 41.9188 34.034 40.9271L36.346 44.567L36.3443 44.5653Z"
              fill="url(#paint5_linear_735_10794)"
            />
            <path
              d="M34.032 40.9252C32.8387 41.9185 31.1234 42.505 29.1097 42.505C26.5375 42.505 24.2255 41.4398 22.9195 39.1968H28.2893L29.892 35.7415H21.8372C21.7626 35.0799 21.7626 34.8218 21.7626 34.6029C21.7626 34.1977 21.7626 33.9036 21.8372 33.389H30.8615L32.5023 29.9338H22.8068C24.1492 27.5078 26.5723 26.4051 29.108 26.4051C30.898 26.4051 32.4642 26.7726 33.621 27.5454L35.522 23.685C33.8447 22.6198 31.8294 21.9941 29.108 21.9941C23.9619 21.9941 19.1888 24.9348 17.3988 29.9338H13.9316V33.389H16.653C16.6166 33.9772 16.6166 34.2712 16.6166 34.5653C16.6166 34.8953 16.6166 35.0799 16.653 35.7415H13.9316V39.1968H17.3988C19.1888 44.1582 23.8492 46.9894 29.108 46.9894C32.0913 46.9894 34.627 45.9226 36.3424 44.5634L34.0304 40.9236L34.032 40.9252ZM17.7137 39.0889L17.6358 38.8717H14.2648V36.0699H17.006L16.9861 35.7252C16.9497 35.0717 16.9497 34.8937 16.9497 34.5669C16.9497 34.2696 16.9497 33.9886 16.9861 33.4103L17.0077 33.0639H14.2648V30.2622H17.6358L17.7137 30.0449C19.3926 25.3546 23.8675 22.3225 29.1097 22.3225C31.4863 22.3225 33.3973 22.7963 35.0961 23.8075L33.4851 27.0749C32.3448 26.4214 30.8433 26.0783 29.1097 26.0783C26.2209 26.0783 23.8178 27.4261 22.5167 29.777L22.2499 30.2605H31.9819L30.651 33.0623H21.5505L21.5091 33.3433C21.4312 33.8808 21.4312 34.1993 21.4312 34.6029C21.4312 34.8234 21.4312 35.0995 21.5074 35.7791L21.5406 36.0699H29.3749L28.0738 38.8717H22.3444L22.6294 39.3618C23.9139 41.5689 26.2756 42.8333 29.1064 42.8333C30.9808 42.8333 32.6829 42.3302 33.9442 41.4088L35.9015 44.4899C34.0403 45.8752 31.5808 46.6659 29.1064 46.6659C23.7614 46.6659 19.3959 43.7629 17.7104 39.0906L17.7137 39.0889Z"
              fill="url(#paint6_linear_735_10794)"
            />
            <path
              d="M50.7135 37.7923C50.7135 35.5738 49.3877 33.6509 46.0365 32.9109L43.8604 32.442C42.8842 32.2198 42.3853 31.7019 42.3853 31.037C42.3853 30.248 43.0864 29.4344 44.4869 29.4344C46.4624 29.4344 46.8884 30.7168 47.0375 31.4798L50.5147 30.5682C50.165 28.892 48.9136 26.9447 46.1624 26.3516V23.6152H43.2869V26.2781C40.66 26.7225 38.5602 28.6208 38.5602 31.3817C38.5602 33.7244 40.2855 35.4006 43.0864 36.0655L45.3122 36.5834C46.3133 36.8055 46.8635 37.2728 46.8635 37.9883C46.8635 38.9506 45.9636 39.542 44.7123 39.542C42.561 39.542 41.56 38.2595 41.4357 36.8301L37.9834 37.5946C38.1591 39.6155 39.7584 42.1803 43.2853 42.7472V45.3856H46.1608V42.6982C49.0628 42.1803 50.7135 40.0598 50.7135 37.7923ZM46.1028 42.3764L45.831 42.4254V45.0589H43.6184V42.4695L43.34 42.4254C40.2623 41.9304 38.643 39.818 38.348 37.8495L41.1572 37.227C41.4141 38.565 42.4549 39.8687 44.7123 39.8687C46.1972 39.8687 47.195 39.1139 47.195 37.9883C47.195 37.3855 46.8817 36.5964 45.3885 36.2648L43.1643 35.7469C40.4479 35.1016 38.8916 33.5104 38.8916 31.3801C38.8916 28.9704 40.6799 27.0492 43.3433 26.5983L43.6184 26.551V23.9387H45.831V26.6114L46.0912 26.6669C48.8971 27.2698 49.8186 29.2449 50.1169 30.3296L47.2812 31.073C46.9083 29.7644 45.9702 29.1028 44.4852 29.1028C42.8875 29.1028 42.0522 30.0732 42.0522 31.0321C42.0522 31.8767 42.6837 32.5057 43.7891 32.7573L45.9636 33.2262C49.6147 34.0316 50.3821 36.1521 50.3821 37.789C50.3821 39.6906 49.0595 41.8454 46.1028 42.3731V42.3764Z"
              fill="url(#paint7_linear_735_10794)"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_735_10794"
              x="8.41211"
              y="3.00684"
              width="56.4517"
              height="62.5381"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="-1" dy="1" />
              <feGaussianBlur stdDeviation="3" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.9 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_735_10794"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_735_10794"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_d_735_10794"
              x="5.59033"
              y="14.334"
              width="52.7798"
              height="40.9951"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="4" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.9 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_735_10794"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_735_10794"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_735_10794"
              x1="35"
              y1="0"
              x2="35"
              y2="69"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#333333" />
              <stop offset="0.25" stopColor="#B8B8B8" />
              <stop offset="0.5" stopColor="#BBBBBA" />
              <stop offset="0.75" stopColor="#D4D4D4" />
              <stop offset="1" stopColor="#2B2B2B" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_735_10794"
              x1="68.913"
              y1="34.4996"
              x2="1.0874"
              y2="34.4996"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#D4D4D4" />
              <stop offset="0.5" stopColor="#4D4D4D" />
              <stop offset="1" stopColor="#7D7D7D" />
            </linearGradient>
            <radialGradient
              id="paint2_radial_735_10794"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(34.9998 34.5004) scale(30.4837 30.0482)"
            >
              <stop offset="0.3" stopColor="#343433" />
              <stop offset="0.66" stopColor="#323231" />
              <stop offset="0.79" stopColor="#2B2B2A" />
              <stop offset="0.88" stopColor="#1F1F1E" />
              <stop offset="0.95" stopColor="#0E0E0E" />
              <stop offset="1" />
            </radialGradient>
            <linearGradient
              id="paint3_linear_735_10794"
              x1="18.5158"
              y1="30.9894"
              x2="59.5125"
              y2="38.3222"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#D4D4D4" />
              <stop offset="0.5" stopColor="#4D4D4D" />
              <stop offset="1" stopColor="#7D7D7D" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_735_10794"
              x1="37.9834"
              y1="34.5004"
              x2="50.7135"
              y2="34.5004"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#4A4A4A" />
              <stop offset="0.25" stopColor="#B8B8B8" />
              <stop offset="0.5" stopColor="#A8A8A7" />
              <stop offset="0.75" stopColor="#CCCCCC" />
              <stop offset="1" stopColor="#404040" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_735_10794"
              x1="13.9336"
              y1="34.4937"
              x2="36.3443"
              y2="34.4937"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#BA9555" />
              <stop offset="0.12" stopColor="#A67124" />
              <stop offset="0.4" stopColor="#FCF6AC" />
              <stop offset="0.67" stopColor="#D3AC53" />
              <stop offset="0.85" stopColor="#BD882C" />
              <stop offset="1" stopColor="#AB6A30" />
            </linearGradient>
            <linearGradient
              id="paint6_linear_735_10794"
              x1="13.9333"
              y1="34.4934"
              x2="36.344"
              y2="34.4934"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#DBA73E" />
              <stop offset="0.16" stopColor="#D89D3A" />
              <stop offset="0.3" stopColor="#A46B28" />
              <stop offset="0.46" stopColor="#8E5723" />
              <stop offset="0.49" stopColor="#F4F3B8" />
              <stop offset="0.56" stopColor="#F7ED9A" />
              <stop offset="0.69" stopColor="#D5B767" />
              <stop offset="0.75" stopColor="#A76F29" />
              <stop offset="0.85" stopColor="#D0AB5B" />
              <stop offset="1" stopColor="#E0C77D" />
            </linearGradient>
            <linearGradient
              id="paint7_linear_735_10794"
              x1="37.9834"
              y1="34.5004"
              x2="50.7135"
              y2="34.5004"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#ABABAB" />
              <stop offset="0.16" stopColor="#A4A4A4" />
              <stop offset="0.3" stopColor="#757575" />
              <stop offset="0.46" stopColor="#525252" />
              <stop offset="0.49" stopColor="#EDEDED" />
              <stop offset="0.56" stopColor="#E7E7E7" />
              <stop offset="0.69" stopColor="#B7B7B7" />
              <stop offset="0.75" stopColor="#666666" />
              <stop offset="0.85" stopColor="#ADADAD" />
              <stop offset="1" stopColor="#C6C6C6" />
            </linearGradient>
            <clipPath id="clip0_735_10794">
              <rect width="70" height="69" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )}
    </>
  );
};

export default EUUSIcon;
