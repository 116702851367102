import { useContext, useState } from "react";
import CheckedIcon from "components/Svg/Icons/CheckedIcon";
import UnCheckedIcon from "components/Svg/Icons/UnCheckedIcon";
import { ThemeContext } from "ThemeContext";

export default function CheckComponent({
  label = "",
  onChange = (f) => f,
  ...props
}) {
  const { theme } = useContext(ThemeContext);

  const [checked, setChecked] = useState(false);
  const CheckIcon = checked ? <CheckedIcon /> : <UnCheckedIcon />;
  const handleChange = () => {
    const newChecked = !checked;
    setChecked(newChecked);
    onChange(newChecked);
  };

  return (
    <div {...props} className={`flex items-center ${props.className}`}>
      <span onClick={handleChange}>{CheckIcon}</span>
      <span
        className={`text-[14px] ml-2 ${theme === "dark" ? "text-white" : ""}`}
      >
        {label}
      </span>
    </div>
  );
}
