const defaultDigitAmount = 4;
/**
 *
 * @param {*} input
 * @param {Number} digitAmount
 * @returns {string}
 */
export const fixDigitFilter = (input, digitAmount = defaultDigitAmount) => {
  return Number(input).toFixed(digitAmount);
};

/**
 *
 * @param {*} input
 * @param {Number} digitAmount
 * @returns {Number}
 */
export const fixFloorFilter = (input, digitAmount = defaultDigitAmount) => {
  return (
    Math.floor(Number(input) * Math.pow(10, digitAmount)) /
    Math.pow(10, digitAmount)
  );
};
