import { useContext } from "react";
import { ThemeContext } from "ThemeContext";

const InputNumber = ({ value, setValue, label, prefix, ...props }) => {
  const { theme } = useContext(ThemeContext);
  const handleInputChange = (e) => {
    const input = e.target.value;

    // Use a regular expression to check if the input is a valid float
    const isValidFloat = /^-?\d*(\.\d*)?$/.test(input);

    if (isValidFloat || input === "") {
      setValue(input);
    }
  };
  return (
    <div
      {...props}
      className={`text-center w-full ${props.className ? props.className : ""}`}
    >
      <span
        className={`text-sm font-normal ${
          theme === "dark" ? "text-[#ffffff]" : "text-[#5F5F5F]"
        }`}
      >
        {label ? label : null}
      </span>
      <div className=" flex mt-2 w-full font-normal text-md justify-between relative  rounded py-1 px-3 border border-[#6E7A8A] gap-2">
        <span>{prefix ? prefix : null}</span>
        <input
          className="w-full outline-none"
          style={{
            background: "inherit",
          }}
          value={value === null || value === undefined ? 0 : value}
          onChange={handleInputChange}
        />
      </div>
    </div>
  );
};

export default InputNumber;
