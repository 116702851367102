import React, { useContext } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { ThemeContext } from "ThemeContext";

export function DialogCustom({
  isOpen,
  header,
  body,
  footer,
  onClose,
  size,
  isFooter,
}) {
  const handleOpen = () => onClose(!isOpen);
  const { theme } = useContext(ThemeContext);

  return (
    <>
      <Dialog
        open={isOpen}
        handler={handleOpen}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        size={size ? size : "sm"}
        className={`shadow-none ${
          theme === "dark" ? " bg-[#263238] text-white " : "bg-[#ffffff]"
        }`}
      >
        <DialogHeader className={`${theme === "dark" ? "text-white" : ""}`}>
          {header}
        </DialogHeader>
        <DialogBody className="overflow-y-auto xl:max-h-[500px] max-h-[500px]">
          {body}
        </DialogBody>
        {isFooter ? (
          <DialogFooter>
            <Button variant="gradient" color="green" onClick={handleOpen}>
              <span>Confirm</span>
            </Button>
          </DialogFooter>
        ) : null}
      </Dialog>
    </>
  );
}
