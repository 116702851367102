import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Table from "components/Table";

const BalanceTable = ({ data=[], title=""}) => {
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        Header: `${t("Coin")}`,
        accessor: "coin",
        Cell: ({ row }) => {
          return (
            <div className="flex flex-row items-center text-center align-middle ml-2 py-2">
              {row.original.icon && (
                <div>
                  <img
                    src={row.original.icon}
                    alt="icon"
                    className="w-8 h-8"
                  ></img>
                </div>
              )}
              <div className="flex md:flex-row flex-col items-start justify-between gap-1 ml-1">
                <span className="ml-1">{row.original.symbol}</span>
                <span className="ml-1">
                  (${Number(row.original.price).toFixed(2)})
                </span>
              </div>
            </div>
          );
        },
      },
      {
        Header: `${t("Balance")}`,
        accessor: "price",
        Cell: ({ row }) => {
          return (
            <div className="flex flex-row items-start justify-between py-2">
              <div className="text-sm font-bold ml-2">
                {row?.original?.balance !== undefined &&
                row?.original?.balance !== null
                  ? Number(row?.original?.balance).toFixed(4)
                  : "Loading"}
              </div>
            </div>
          );
        },
      },
      {
        Header: `${t("Amount")}`,
        accessor: "balance",
        Cell: ({ row }) => {
          return (
            <div className="flex flex-row items-start justify-between py-2">
              <div className="text-sm  ml-2">
                {row?.original?.balanceInUsd !== undefined &&
                row?.original?.balanceInUsd !== null
                  ? isNaN(row?.original?.balanceInUsd) !== true
                    ? `≈ $${Number(row?.original?.balanceInUsd).toFixed(2)}`
                    : `≈ $0.00`
                  : "Loading"}
              </div>
            </div>
          );
        },
      },
    ],
    [t]
  );

  return <Table columns={columns} data={data} title={title} />;
};

export default BalanceTable;
