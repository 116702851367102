function isEmpty(value) {
  if (value === null || value === undefined) {
    return true;
  }

  if (typeof value === "object") {
    if (!value) {
      return true;
    }

    return Object.keys(value).length === 0;
  }

  if (typeof value === "string") {
    return value.trim() === "";
  }

  return false;
}

export default isEmpty;
