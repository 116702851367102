import Receipt from "./Receipt";
import OrderButton from "./OrderButton";
import { useContext } from "react";
import { ThemeContext } from "ThemeContext";

export default function ReceiptPanel({
  onClickOrder = (f) => f,
  receipts = [],
  postingFlag = false,
  orderID = "",
  isDisabled = false,
  messageDisabled = "",
  ...props
}) {
  const { theme } = useContext(ThemeContext);

  return (
    <div {...props} className={`${props.className}`}>
      <div className="text-center">
        <p className={`text-[20px] ${theme === "dark" ? "text-white" : ""}`}>
          Order Summary
        </p>
        <p
          className={`text-[12px] mb-10 ${
            theme === "dark" ? "text-[#B0B5BC]" : ""
          }`}
        >
          Order ID #: {orderID}
        </p>
      </div>
      <Receipt receipts={receipts} className="mb-12" />
      <OrderButton
        onClick={onClickOrder}
        postingFlag={postingFlag}
        isDisabled={isDisabled}
        messageDisabled={messageDisabled}
      />
    </div>
  );
}
