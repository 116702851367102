import Table from "components/Table";
import { useContext, useMemo } from "react";
import { usePositionData } from "hooks/usePositionData";
import { SpinningCircles } from "react-loading-icons";
import { ThemeContext } from "ThemeContext";

const MyPosition = () => {
  const { theme } = useContext(ThemeContext);
  const data_ = usePositionData();
  const columns = useMemo(
    () => [
      {
        Header: "Assets",
        accessor: "symbol",
        Cell: ({ row }) => {
          return (
            <div className="flex flex-row items-center text-center align-middle ml-2 py-2">
              {row.original.icon && (
                <div>
                  <img
                    src={row.original.icon}
                    alt="icon"
                    className="w-7 h-7"
                  ></img>
                </div>
              )}
              <div className="flex md:flex-row flex-col items-start justify-between gap-1 pl-1">
                <span className="ml-1">{row.original.symbol}</span>
                <span className="ml-1">
                  (${Number(row.original.price).toFixed(2)})
                </span>
              </div>
            </div>
          );
        },
      },
      {
        Header: "Amount",
        accessor: "balance",
        Cell: ({ row }) => {
          return (
            <div className="text-sm font-normal ml-2 py-2">
              {row?.original?.balance !== undefined &&
              row?.original?.balance !== null
                ? Number(row?.original?.balance).toFixed(4)
                : "Loading"}
            </div>
          );
        },
      },
      {
        Header: "Price By USD",
        accessor: "balanceInUsd",
        Cell: ({ row }) => {
          return (
            <div className="text-sm font-normal ml-2 py-2">
              {row?.original?.balanceInUsd !== undefined &&
              row?.original?.balanceInUsd !== null
                ? isNaN(row?.original?.balanceInUsd) !== true
                  ? row?.original?.balanceInUsd === 0
                    ? `$0.00`
                    : `≈ $${Number(row?.original?.balanceInUsd).toFixed(2)}`
                  : `$0.00`
                : "Loading"}
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <div
      className={`w-full h-[95%] inline-block dark font-normal rounded-2xl text-xs uppercase leading-normal transition duration-150 ease-in-out ${
        theme === "dark" ? "bg-[#263238]" : "bg-[#EBF5FB]"
      } dark:text-gray md:p-8 p-2 text-[#128FC8] transition-all`}
    >
      <div
        className={`flex flex-col w-full rounded-2xl h-[100%] overflow-y-auto ${
          theme === "dark" ? "bg-[#000000]" : "bg-[#FFFFFF]"
        }`}
      >
        {data_?.isFetched ? (
          <Table
            columns={columns}
            data={data_?.data.data.data}
            title={`position`}
          />
        ) : (
          <div className="flex items-center justify-center w-full h-screen -translate-y-1/4">
            <SpinningCircles stroke="#007bff" speed={1.5} fill="#007bff" />
          </div>
        )}
      </div>
    </div>
  );
};

export default MyPosition;
