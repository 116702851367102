import { useContext } from "react";
import { ThemeContext } from "ThemeContext";

const InputNormal = ({
  value,
  setValue,
  label,
  prefix,
  btcDepositAmount,
  ...props
}) => {
  const { theme } = useContext(ThemeContext);
  const handleInputChange = (e) => {
    const input = e.target.value;
    setValue(input);
  };
  return (
    <div
      {...props}
      className={`text-center w-full ${props.className ? props.className : ""}`}
    >
      <span
        className={`  text-sm font-normal ${
          theme === "dark" ? "text-[#D9D9D9]" : "text-[#5F5F5F]"
        }`}
      >
        {label ? label : null}
      </span>
      <div className=" flex text-[#B0B5BC] mt-2 w-full font-normal text-md justify-between relative  rounded">
        <span>Minimum deposit amount: </span>{" "}
        <span>{btcDepositAmount} BTC</span>
      </div>
      <div className=" flex text-[#B0B5BC] mt-2 w-full font-normal text-md justify-between relative  rounded py-2 px-3 border border-[#6E7A8A] gap-1">
        <input
          className="w-full outline-none"
          style={{
            background: "inherit",
          }}
          value={value === null || value === undefined ? 0 : value}
          onChange={handleInputChange}
          placeholder="Please input transaction id on BTC network"
        />
        <span>{prefix ? prefix : null}</span>
      </div>
    </div>
  );
};

export default InputNormal;
