import { format } from "date-fns";
/**
 *
 * @param {string} isoDateStr
 * @returns
 */
export const formattedCurrentDate = () => {
  const date = new Date();
  return format(date, "yyyy-MM-dd hh:mm");
};

export const formattedSpecifiedDate = (isoDate) => {
  const date = new Date(isoDate);
  return format(date, "yyyy-MM-dd hh:mm");
};

export const convertFormatDate = (isoDate, formatter) => {
  const date = new Date(isoDate);
  return format(date, formatter);
};
