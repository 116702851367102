export const langList = [
  { text: "English", value: "en" },
  { text: "Spanish", value: "sp" },
  { text: "Russian", value: "ru" },
  { text: "Mandarin", value: "zh" },
  { text: "Arabic", value: "ar" },
  { text: "French", value: "fr" },
];
export const allTimezone = [
  { text: "UTC+00:00", value: "UTC+00:00" },
  { text: "UTC+01:00", value: "UTC+01:00" },
  { text: "UTC+02:00", value: "UTC+02:00" },
  { text: "UTC+03:00", value: "UTC+03:00" },
  { text: "UTC+04:00", value: "UTC+04:00" },
  { text: "UTC+05:00", value: "UTC+05:00" },
  { text: "UTC+06:00", value: "UTC+06:00" },
  { text: "UTC+07:00", value: "UTC+07:00" },
  { text: "UTC+08:00", value: "UTC+08:00" },
  { text: "UTC+09:00", value: "UTC+09:00" },
  { text: "UTC+10:00", value: "UTC+10:00" },
  { text: "UTC+11:00", value: "UTC+11:00" },
  { text: "UTC+12:00", value: "UTC+12:00" },
  { text: "UTC-01:00", value: "UTC-01:00" },
  { text: "UTC-02:00", value: "UTC-02:00" },
  { text: "UTC-03:00", value: "UTC-03:00" },
  { text: "UTC-04:00", value: "UTC-04:00" },
  { text: "UTC-05:00", value: "UTC-05:00" },
  { text: "UTC-06:00", value: "UTC-06:00" },
  { text: "UTC-07:00", value: "UTC-07:00" },
  { text: "UTC-08:00", value: "UTC-08:00" },
  { text: "UTC-09:00", value: "UTC-09:00" },
  { text: "UTC-10:00", value: "UTC-10:00" },
  { text: "UTC-11:00", value: "UTC-11:00" },
  { text: "UTC-12:00", value: "UTC-12:00" },
];
