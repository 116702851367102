import EUUSIcon from "components/Svg/Icons/EUUSIcon";
import USEUIcon from "components/Svg/Icons/USEUIcon";
import CheckedIcon from "components/Svg/Icons/CheckedIcon";
import UnCheckedIcon from "components/Svg/Icons/UnCheckedIcon";
import { useContext, useState } from "react";
import PriceInput from "./PriceInput";
import { ThemeContext } from "ThemeContext";

export default function AmountInput({
  type = "EUUS",
  onPriceChange = (f) => f,
  onCheckChange = (f) => f,
  ...props
}) {
  const { theme } = useContext(ThemeContext);

  const Icon = type === "EUUS" ? <EUUSIcon /> : <USEUIcon />;
  const Label = type === "EUUS" ? "EU / US" : "US / EU";
  const [checked, setChecked] = useState(false);
  const CheckIcon = checked ? (
    <CheckedIcon isDarkMode={theme === "dark"} />
  ) : (
    <UnCheckedIcon isDarkMode={theme === "dark"} />
  );
  const handleCheckChange = () => {
    const newChecked = !checked;
    setChecked(newChecked);
    onCheckChange(newChecked);
  };

  return (
    <div {...props}>
      <div className="grid lg:grid-cols-2 md:grid-cols-1">
        <div className="m-auto">{Icon}</div>
        <div className="flex items-center">
          <span onClick={handleCheckChange}>{CheckIcon}</span>
          <span
            className={`text-[14px] ml-2 pl-2 ${
              theme === "dark" ? "text-white" : ""
            }`}
          >
            {Label}
          </span>
        </div>
      </div>
      <div className="mt-3">
        <PriceInput onChange={onPriceChange} />
      </div>
      <p
        className={`pl-4 text-[10px] ${
          theme === "dark" ? "text-[#B0B5BC]" : ""
        }`}
      >
        Amounts Token
      </p>
    </div>
  );
}
